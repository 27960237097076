import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import Helpers from '../../../utils/Helpers';
import { PRIMARY_COLOR } from '../../../utils/CssConstants';


const styles = {
    campaignListTDataRow: {
        display: 'flex',
        background: '#EDEEF7',
        border: '1px solid #000000',
        boxSizing: 'border-box',
        borderRadius: 10,
        padding: '15px 30px',
        width: '100%',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 10    
    },
    campaingListData: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 20,
        padding: '5px 10px',
        textAlign: 'center',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        wordBreak: 'break-word'
    },
    smallSpace: {
        flex: 1
    },
    mediumSpace: {
        flex: 3
    },
    mediumSpaceTwo: {
        flex: 4
    },
    largeSpace: {
        flex: 7
    },
    campaignPhoto: {
        borderRadius: 10,
        width: 180,
        height: 180,
        objectFit: 'cover'
    }
}

const TableData = ({ classes, postsData, seriesIndex }) => {

    return (
        <>
            {(postsData.length > 0) && postsData.map((post, index) => 
                <tr className={classes.campaignListTDataRow} key={index}>
                    <td className={`${classes.campaingListData} ${classes.smallSpace}`} id="table-index">{seriesIndex + index}</td>
                    <td className={`${classes.campaingListData} ${classes.mediumSpaceTwo}`} id="table-campaign-name" style={{ flexDirection: 'column' }}><span style={{ fontWeight: 'bolder' }}>{post.brand_name !== null ? post.brand_name : ''}</span> <span>{post.brand_campaign !== null ? post.brand_campaign : ''}</span></td>
                    <td className={`${classes.campaingListData} ${classes.mediumSpaceTwo}`} id="table-campaign-name"><img className={classes.campaignPhoto} src={post.thumzup_image} alt="campaign" /></td>
                    <td className={`${classes.campaingListData} ${classes.mediumSpaceTwo}`} id="table-campaign-image"><a href={'https://www.instagram.com/' + post.instagram_name} rel='noreferrer' target="_blank" style={{color: PRIMARY_COLOR }}>{post.instagram_name !== null ? '@' + post.instagram_name : ''}</a></td>
                    <td className={`${classes.campaingListData} ${classes.mediumSpace}`} id="table-budget" style={{ wordBreak: 'break-word' }}>{post.phone_number}</td>
                    <td className={`${classes.campaingListData} ${classes.mediumSpace}`} id="table-budget-remaining">{post.amount_paid !== null ? '$' + post.amount_paid : "$0" }</td>
                    <td className={`${classes.campaingListData} ${classes.mediumSpace}`} id="table-budget-remaining">{post.date_posted !== null ? Helpers.formatDate(post.date_posted + "Z") : ""}</td>
                    <td className={`${classes.campaingListData} ${classes.mediumSpace}`} id="table-budget" style={{ wordBreak: 'break-word' }}>{post.status}</td>
                    {/* <td className={`${classes.campaingListData} ${classes.mediumSpace}`} id="table-budget-remaining">{post.date_paid !== null ? moment(post.date_paid).format('YYYY-DD-MM hh:mm A'): ""}</td> */}
                </tr>
            )}
        </>
    )
}

export default withStyles(styles)(TableData);