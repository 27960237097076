import React, { StrictMode } from 'react';
import ReactDOM from 'react-dom/client';

import { Provider } from 'react-redux';
import { store, persistor } from './store/index.js';
import { BrowserRouter } from 'react-router-dom';
import { PersistGate } from 'redux-persist/integration/react';
import { ThemeProvider, createTheme } from '@material-ui/core';
import { ToastContainer } from "react-toastify"


import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AppProvider } from './utils/AppContext.js';

const rootElement = ReactDOM.createRoot(document.getElementById('root'))
const theme = createTheme()

rootElement.render(
    <BrowserRouter>
        <Provider store={store}>
            <PersistGate loading={null} persistor={persistor}>
                <ThemeProvider theme={theme}>
                    <AppProvider>
                        <App />
                        <ToastContainer theme='colored' />
                    </AppProvider>
                </ThemeProvider>
            </PersistGate>
        </Provider>
    </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
