import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import { WHITE_BG, BTN_COLOR_THEME } from '../../../utils/CssConstants';


const styles = theme => ({
    campaignTabs: {
        display: 'flex',
        marginTop: 60,
        margin: '0px 10px',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            margin: 0,
            padding: 20,
        }
    },
    campaignTabsBtns: {
        background: WHITE_BG,
        borderRadius: 20,
        border: '1px solid #1549d6',
        boxSizing: 'border-box',
        width: '50%',
        marginRight: 20,
        height: '90px',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 20,
        color: "#504F4F",
        outline: 'none',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginBottom: 20,
            marginRight: 0,
            '&:last-child': {
                marginBottom: 0
            }
        }
    },
    campaignTabsActive: {
        color: WHITE_BG,
        background: BTN_COLOR_THEME,
    },
    
})

const CampaignTabs = ({ classes, activeTab, handleActiveTab }) => {
    return (
        <div className={classes.campaignTabs}>
            <button id="create-campaign-tab" className={`${classes.campaignTabsBtns} ${activeTab === 1 ? classes.campaignTabsActive : ""}`} onClick={() => handleActiveTab(1)}>Create Campaign</button>
            {/* <button className={`${classes.campaignTabsBtns} ${activeTab === 2 ? classes.campaignTabsActive : ""}`} onClick={() => handleActiveTab(2)}>Payment Methods</button> */}
            <button id="campaign-list-tab" className={`${classes.campaignTabsBtns} ${activeTab === 3 ? classes.campaignTabsActive : ""}`} style={{marginRight: 0}} onClick={() => handleActiveTab(3)}>Campaign List</button>
        </div>
    )
}

export default withStyles(styles)(CampaignTabs);