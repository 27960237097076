import React, { useState, useRef } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { WHITE_BG, INVALID_FORM_VALIDATION, PRIMARY_COLOR } from '../../../../utils/CssConstants';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import Cancel from '@mui/icons-material/Cancel';
import ImageCropModal from './ImageCrop/ImageCropModal';
import Helpers from '../../../../utils/Helpers';


const styles = theme => ({
    imageUploaderContainer: {
        background: WHITE_BG,
        border: '1px solid #000000',
        boxSizing: 'border-box',
        width: '100%',
        padding: '25px 20px 25px 20px',
    },
    imageUploader: {
        background: WHITE_BG,
        border: '1px solid #B7B7B7',
        boxSizing: 'border-box',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: 10,
        maxHeight: 480,
        height: 480,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 10,
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'center center',
    },
    imagePreview: {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
        maxHeight: 'inherit',
        maxWidth: 'inherit',
        verticalAlign: 'middle',
        borderRadius: 10,
        imageOrientation: 'from-image'

    },
    imageTooltip: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 15,
        color: '#B7B7B7',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center'
    },
    imageUpload: {
        opacity: 0,
        zIndex: 999,
        position: 'absolute',
        width: 'auto',
        height: 60,
        cursor: 'pointer',
        "&::-webkit-file-upload-button": {
            visibility: 'hidden',
        },
        "&::before": {
            border: 'none',
        },
        "& > input": {
            display: 'none',
        },
        "&:hover::before": {
            border: '1px solid black'
        }
    },
    imageUploadBtn: {
        background: PRIMARY_COLOR,
        borderRadius: 10,
        border: '1px solid #1549d6',
        boxSizing: 'border-box',
        width: '50%',
        marginRight: 20,
        height: 60,
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 20,
        color: WHITE_BG,
        outline: 'none',
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            width: '75%'
        }
    },
    imageInputCont: {
        display: 'flex',
        justifyContent: 'space-between'
    },
    invalidFormInputs: INVALID_FORM_VALIDATION,
    campaignNamePlaceholder: {
        fontFamily: 'sans-serif',
        fontStyle: 'normal',
    },
    campaignNameText: {
        wordBreak: 'break-word',
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 36,
    }
})

const ImageUploader = ({ classes, image, imageName, imageError, setImageError, handleImage, displayOnly, campaignName, campaignType }) => {

    const [cropModal, setCropModal] = useState(false)
    const [editMode, setEditMode] = useState(false)
    const fileForm = useRef();
    let [imagesizeErr, setImagesizeErr] = useState(false)
    let [fileType, setfileType] = useState("")

    const handleValidateImage = async (e) => {
        setImageError(false);
        setImagesizeErr(false);
        const file = e.target.files[0];

        // Check if a file is selected
        if (!file) {
            setImageError(true);
            return;
        }


        // Check file type (only allow jpg, jpeg, and png)
        if (!file.name.match(/\.(jpg|jpeg|png)$/)) {
            setImageError(true);
            return;
        }
        const fileSizeInMB = file.size / (1024 * 1024);
        if (fileSizeInMB > 2) {
            setImagesizeErr(true);
            return;
        }

        // Reset image error state
        setImageError(false);
        setImagesizeErr(false);
        // Continue with image handling
        setfileType(e.target.files[0].type)
        handleImage([file]);
        setCropModal(true);
    };


    return (
        <>
            <div className={classes.imageUploaderContainer}>
                <div className={`${classes.imageUploader} ${imageError && classes.invalidFormInputs}`} id='image-preview' style={{ height: image ? 'auto' : 480 }}>
                    {!displayOnly &&
                        <form ref={fileForm} style={{ display: image ? 'none' : 'block' }}>
                            <input id="image-uploader" className={classes.imageUpload} type="file" onClick={() => { setImageError(false); setImagesizeErr(false) }} onChange={handleValidateImage} accept="image/png, image/jpeg, image/jpg" />
                        </form>
                    }
                    {(!image && !imageError && !imagesizeErr) &&
                        <span className={classes.imageTooltip} id="image-tooltip" style={{ fontSize: 25 }}>
                            IMAGE PREVIEW
                        </span>
                    }
                    {imageError &&
                        <span className={classes.imageTooltip} style={{ fontSize: 25, color: '#FF0000', fontWeight: 'bold' }} id="image-error-message">
                            Please Upload A Valid Image
                        </span>

                    }
                    {imagesizeErr && !imageError &&
                        <span className={classes.imageTooltip} style={{ fontSize: 25, color: '#FF0000', fontWeight: 'bold' }} id="image-error-message">
                            Required image less then 2MB
                        </span>
                    }
                    {(image && !imageError) && <img className={classes.imagePreview} src={image} alt='campaign' />}
                </div>
                <span className={classes.imageTooltip} id="image-size-tip" >
                    Suggested image size is 500x500 in JPEG or PNG format.
                </span>
                <span className={classes.imageTooltip} id="image-size-tip" style={{ marginBottom: 30 }}>
                    Maximum file size allowed: 2MB.
                </span>

                <div className={classes.campaignNamePlaceholder}>
                    <p className={classes.campaignNameText} id="campaign-image-name">{campaignName}</p>
                </div>

                <div className={classes.imageInputCont} style={{ justifyContent: !image && 'center' }}>
                    {(!displayOnly && image) &&
                        <>
                            <div>
                                <ModeEditIcon fontSize='large' style={{ color: PRIMARY_COLOR, cursor: 'pointer' }} titleAccess='Edit Image' onClick={() => { setCropModal(true); setEditMode(true); }} />
                            </div>
                            <Cancel fontSize='large' style={{ color: PRIMARY_COLOR, cursor: 'pointer' }} titleAccess='Reupload Image' onClick={() => { handleImage(null); fileForm.current.reset(); setfileType("");}} />
                        </>
                    }

                </div>
            </div>
            {cropModal &&
                <ImageCropModal
                    open={true}
                    closeCropModal={() => setCropModal(false)}
                    image={image}
                    handleImage={handleImage}
                    fileForm={fileForm}
                    campaignType={campaignType}
                    editMode={editMode}
                    setEditMode={setEditMode}
                    fileType={fileType}
                />
            }
        </>

    );
};

export default withStyles(styles)(ImageUploader);