import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { WHITE_BG, GRAY_BG_F7, CAMPAIGNLIST_FILTER_LI_STYLES, PRIMARY_COLOR } from '../../utils/CssConstants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import CircularProgress from '@material-ui/core/CircularProgress';
import GetUsersHistoryAPI from '../../services/Users/GetUsersHistoryAPI';
import Pagination from '@mui/material/Pagination';
import { getUsers } from '../../store/actions/Users/usersActions';
import TableData from './TableData/TableData';
import { AppContext } from '../../utils/AppContext';


const styles = theme => ({
    rootUsers: {
        display: 'flex',
        background: WHITE_BG,
        margin: '30px 10px',
        padding: 15,
        borderRadius: 20
    },
    componentCont: {
        background: GRAY_BG_F7,
        width: '100%',
        borderRadius: 30,
        minHeight: '100vh',
        height: 'auto',
    },
    loaderContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh'
    },
    campaignListTop: {
        margin: 30,
        marginLeft: 50,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'baseline',
        [theme.breakpoints.down('sm')]: {
            margin: '20px 20px'
        }
    },
    campaignListHeading: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 24,
        marginBottom: 20,
        width: '15%',
        [theme.breakpoints.down('sm')]: {
            width: '20%'
        }
    },
    campaignListFilters: {
        display: 'flex',
        listStyle: 'none',
        padding: 0,
        margin: 0,
    },
    filterStyles: CAMPAIGNLIST_FILTER_LI_STYLES,
    filterActive: {
        color: PRIMARY_COLOR,
        fontWeight: 600
    },
    campaignListContent: {
        width: '100%',
        marginBottom: 20,
        [theme.breakpoints.down('sm')]: {
            overflowX: 'auto'
        }
    },
    campaignListTable: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            width: 1400
        }
    },
    campaignListTheadRow: {
        display: 'flex',
        background: WHITE_BG,
        border: '1px solid #000000',
        boxSizing: 'border-box',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: 10,
        padding: '15px 30px',
        width: '100%',
        minHeight: '12%',
        flexWrap: 'wrap',
        marginBottom: 10,
        justifyContent: 'center',
        alignItems: 'center',
    },
    campaingListHeadings: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 20,
        padding: '5px 10px',
        borderRight: '1px solid #CECECE',
        textAlign: 'center',
        height: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    smallSpace: {
        flex: 1
    },
    mediumSpace: {
        flex: 3
    },
    mediumSpaceTwo: {
        flex: 4
    },
    largeSpace: {
        flex: 7
    },
    campaignListBottom: {
        padding: '20px 0',
        display: 'flex',
        justifyContent: 'center',
        '&:before': {
            background: 'black'
        }
    },
    campaignPhoto: {
        borderRadius: 10,
        width: 180,
        height: 180,
        objectFit: 'cover'
    },
    campaignName: {
        color: '#000',
        textDecoration: 'underline',
        cursor: 'pointer',
        "&:hover": {
            color: '#37B1C8',
            textDecoration: 'underline',
            cursor: 'pointer'
        }
    },
    actionIcons: {
        "& > *": {
            marginRight: 15,
            cursor: 'pointer',
        }
    },

    filterBtn: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 20,
        border: '1px solid #FFF',
        background: WHITE_BG,
        borderRadius: 10,
        marginLeft: 15,
        width: '100%',
        display: 'flex',
        alignItems: 'center',
        outline: 'none',
        padding: '0px 15px'
    },
    filterList: {
        position: 'absolute',
        top: 45,
        left: 15,
        zIndex: 1000,
        display: 'block',
        listStyle: 'none',
        width: 125,
        margin: 0,
        padding: 0,
    },
    filterOption: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 20,
        letterSpacing: '0.05em',
        background: WHITE_BG,
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        width: 118,
        height: 35,
        textAlign: 'center',
        cursor: 'pointer',
        '&:hover': {
            color: '#262626',
            backgroundColor: '#f5f5f5',
            borderLeft: '3px solid #1549d6'
        }
    },
    statusList: {
        listStyle: 'none',
        width: 108,
        margin: 0,
        padding: 0,
    },
    statusDropdown: {
        position: 'absolute',
        top: 35,
        zIndex: 1000,
        display: 'block',
    },
    searchContainer: {
        width: '25%',
        display: 'flex',
        position: 'relative',
        marginLeft: 15,
        [theme.breakpoints.down('sm')]: {
            width: '80%'
        }
    }
})


const Users = ({ classes }) => {

    const { loader, setLoader,
        totalUsers, setTotalUsers,
        searchValue, setSearchValue,
        errorData, setErrorData } = useContext(AppContext);

  

    const [currentPage, setCurrentPage] = useState(1);
    const usersPerPage = 10

    const userDetails = useSelector((state) => state.authReducer.userDetails);
    const brandView = useSelector((state) => state.authReducer.brandView);
    const users = useSelector((state) => state.usersReducer.users);

    const navigate = useNavigate();
    let dispatch = useDispatch();


  
    const handlePagination = async (event, value) => {
        setCurrentPage(value);
        setLoader(true)
        await GetUsersHistoryAPI(value, 10, searchValue, (brandView ? userDetails.brand_id : null)).then(data => {
            if (data !== undefined) {
                dispatch(getUsers(data.data.accounts));
                setTotalUsers(data.data.total)
                if (data.data.total <= 0) setErrorData('No records');
            }
            setLoader(false);
        }).catch(err => console.log(err));
    }

    useEffect(() => {
        document.title = "Users - Thumzup";

        if (userDetails.is_verified) {
            if (userDetails.status_id === 1901) {
                navigate('/get-started')
            }
        } else {
            navigate('/signup-verification')
        }
        if (!brandView && userDetails.type_id !== 1801) {
            setLoader(true)
            setErrorData('Please select a brand');
            setLoader(false);
            dispatch(getUsers([]));
            setTotalUsers([])
            setCurrentPage(1)
        }
        else {
            setLoader(true)
            GetUsersHistoryAPI(currentPage, 10, searchValue, userDetails.brand_id).then(data => {
                if (data !== undefined) {
                    dispatch(getUsers(data.data.accounts));
                    setTotalUsers(data.data.total)
                    if (data.data.total <= 0) setErrorData('No records');
                }
                setLoader(false);

            }).catch(err => console.log(err));
        }
    }, [userDetails, dispatch, navigate, brandView]);

    // Get Current users
    const indexOfLastUser = currentPage * usersPerPage;
    const indexOfFirstUser = indexOfLastUser - usersPerPage;
    const currentUsers = users;

    return (
        <div className={classes.rootUsers}>
            <div className={classes.componentCont}>
                <div className={classes.campaignListTop}>
                    <h3 className={classes.campaignListHeading} id="page-heading">Users</h3>
                </div>

                {loader === true ?
                    <div className={classes.loaderContainer}>
                        <CircularProgress size={80} />
                    </div>
                    :
                    (totalUsers > 0) ?
                        <>
                            <div className={classes.campaignListContent}>
                                <table className={classes.campaignListTable}>
                                    <thead>
                                        <tr className={classes.campaignListTheadRow}>
                                            <th className={`${classes.campaingListHeadings} ${classes.smallSpace}`} id="index">ID</th>
                                            <th className={`${classes.campaingListHeadings} ${classes.mediumSpaceTwo}`} id="campaign-name">Instagram Name</th>
                                            <th className={`${classes.campaingListHeadings} ${classes.mediumSpaceTwo}`} id="campaign-photo">Phone Number</th>
                                            <th className={`${classes.campaingListHeadings} ${classes.mediumSpace}`} id="posts-paid">Posts Paid</th>
                                            <th className={`${classes.campaingListHeadings} ${classes.mediumSpace}`} id="total-paid">Total Paid</th>
                                            <th className={`${classes.campaingListHeadings} ${classes.mediumSpace}`} id="posts-pending">Posts Pending</th>
                                            <th className={`${classes.campaingListHeadings} ${classes.mediumSpace}`} id="amount-pending">Amount Pending</th>
                                            <th className={`${classes.campaingListHeadings} ${classes.mediumSpace}`} id="approved-amount">Approved Amount</th>
                                            <th className={`${classes.campaingListHeadings} ${classes.mediumSpace}`} id="status">Date Joined</th>
                                            <th className={`${classes.campaingListHeadings} ${classes.mediumSpace}`} id="Users-status" style={{ borderRight: 'none' }}>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <TableData usersData={currentUsers} seriesIndex={currentPage < 2 ? currentPage : indexOfFirstUser + 1} userDetails={userDetails} />
                                    </tbody>
                                </table>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                <Pagination color='primary' onChange={handlePagination} count={Math.ceil(totalUsers / 10)} page={currentPage} showFirstButton showLastButton />
                            </div>
                        </>
                        :
                        <div className={classes.loaderContainer}>
                            <h1 id="no-records">{errorData}</h1>
                        </div>
                }
            </div>
        </div>
    )
}

export default withStyles(styles)(Users);