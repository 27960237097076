import * as actionTypes from '../../types/dashboard';

const initialState = {
    posts: [],
};

const dashboardReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.GET_POSTS:
            return { ...state, posts: action.payload};
        case actionTypes.APPROVE_POST:
            return {
                ...state,
                posts: state.posts.map((post, i) => i === action.payload ? {...post, status: "Approved"} : post)
            }
        case actionTypes.FLAG_POST:
            return {
                ...state,
                posts: state.posts.map((post, i) => post.id === action.payload ? {...post, status: "Flagged"} : post)
            }
        case actionTypes.REJECT_POST:
            return {
                ...state,
                posts: state.posts.map((post, i) => i === action.payload ? {...post, status: "Rejected"} : post)
            }
        default:
            return state;
    }
}

export default dashboardReducer;