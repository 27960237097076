// import axios from 'axios';

/**
 * @description POST API to login into app
 * @async
 * @function PostResendEmailAPI
 * @returns Promise
**/


const PostResendEmailAPI = async (id) => {
    // let apiUrl = process.env.REACT_APP_API_URL + "/auth/re-send-email";
    // const body = {
    //     "admin_id": id,
    // };

    // const response = await axios.post(apiUrl, body);
    // return response;

    const url = process.env.REACT_APP_API_URL + "/auth/re-send-email";
    const body = {
        "admin_id": id,
    };
    const params = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(body),
    };
    try {
      const response = await fetch(url, params);
      const responseJson = await response.json();
      return responseJson;
    } catch (error) {
      return error;
    }
}

export default PostResendEmailAPI;
