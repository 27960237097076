import React, { useEffect, useState } from "react"
import { useFormik } from 'formik'
import { withStyles } from "@material-ui/core"
import { WHITE_BG, GRAY_BG_F7, PRIMARY_COLOR } from "../../utils/CssConstants"
import * as yup from 'yup'
import CircularProgress from '@material-ui/core/CircularProgress'
import PostCreateCouponAPI from '../../services/Coupon/PostCreateCouponAPI'
import { toast, ToastContainer } from "react-toastify"


const styles = theme => ({
    rootNewBusiness: {
        display: 'flex',
        background: WHITE_BG,
        margin: '30px 10px',
        padding: 15,
        borderRadius: 20,
        fontFamily: 'Poppins',
        fontStyle: 'normal',
    },
    componentCont: {
        background: GRAY_BG_F7,
        width: '100%',
        borderRadius: 30,
        minHeight: '100vh',
        height: 'auto'
    },
    campaignListTop: {
        margin: '30px 30px 30px 50px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'baseline'
    },
    campaignListHeading: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 24,
        marginBottom: 20,
        width: '100%'
    },
    campaignListContent: {
        width: '50%',
        margin: '0 30px 30px 50px',
        [theme.breakpoints.down('sm')]: {
            width: '75%'
        }
    },
    formGroup: {
        display: 'flex',
        flexDirection: 'column',
        margin: '20px 0',
    },
    formLabels: {
        fontWeight: 'bold',
        fontSize: 18,
    },
    formInputs: {
        width: '100%',
        padding: 10,
        fontWeight: 'normal',
        fontSize: 16,
        borderRadius: 5,
        border: '0.5px solid #CECECE'
    },
    labelError: {
        color: 'rgba(255, 0, 0, 0.9)',
        fontSize: 18,
    },
    submitBtn: {
        background: PRIMARY_COLOR,
        color: WHITE_BG,
        border: 'none',
        borderRadius: 5,
        height: 60,
        width: '25%',
        marginBottom: 63,
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 18,
        [theme.breakpoints.down('sm')]: {
            width: '50%'
        }
    },
    btnLoading: {
        color: '#FFF !important'
    },
    couponSuccess: {
        background: WHITE_BG,
        padding: 20,
        borderRadius: 5,
        border: '1px solid #1549d6'
    }
})

const Coupon = ({ classes }) => {

    const [loading, setLoading] = useState(false)
    const [apiSuccess, setApiSuccess] = useState({})

    const formik = useFormik({
        initialValues: {
            bemail: '',
            amount: ''
        },
        validationSchema: yup.object({
            bemail: yup.string().email('Invalid email address').required('Business email is required'),
            amount: yup.number().required('Coupon amount is required')
        }),
        onSubmit: values => handleSubmit(values)
    })

    const handleSubmit = async inputs => {
        setApiSuccess({})
        setLoading(true)
        await PostCreateCouponAPI(inputs.bemail, inputs.amount).then(res => {
            setLoading(false)
            if(res.status === 'SUCCESS') {
                toast.success(res.message)
                setApiSuccess(res) 
            } else {
                toast.error(res.response.data.error)
            }
        }).catch(err => console.log(err))
    }

    useEffect(() => {
        document.title = "Create Coupon - Thumzup";
    }, [])

    return (
        <>
            <div className={classes.rootNewBusiness}>
                <div className={classes.componentCont}> 
                    <div className={classes.campaignListTop}>
                        <h3 className={classes.campaignListHeading} id="page-heading">Create a Coupon</h3>
                    </div>

                    <div className={classes.campaignListContent}>
                        <form onSubmit={formik.handleSubmit}>
                            <div className={classes.formGroup}>
                                <label className={`${classes.formLabels} ${formik.touched.bemail && formik.errors.bemail && classes.labelError}`} htmlFor="bemail" >{formik.touched.bemail && formik.errors.bemail ? formik.errors.bemail : 'Business Email'}</label>
                                <input className={classes.formInputs} type='text' name="bemail" placeholder="Enter business email" value={formik.values.bemail} onChange={formik.handleChange} /> 
                            </div>
                            <div className={classes.formGroup}>
                                <label className={`${classes.formLabels} ${formik.touched.amount && formik.errors.amount && classes.labelError}`} htmlFor="amount">{formik.touched.amount && formik.errors.amount ? formik.errors.amount : 'Coupon Amount'}</label>
                                <input className={classes.formInputs} type='number' name="amount" placeholder="Enter coupon amount" value={formik.values.amount} onChange={formik.handleChange} /> 
                            </div>
                            <button className={classes.submitBtn} disabled={loading} type="submit">{loading ? <CircularProgress size={30} className={classes.btnLoading}  /> : "Create"}</button>
                        </form>

                        {apiSuccess.hasOwnProperty('data') && 
                            <div className={classes.couponSuccess}>
                                <h6>Coupon Code: {apiSuccess.data.code}</h6>
                                <h6>Coupon Amount: {apiSuccess.data.coupon_amount}</h6>
                                <h6>Coupon Total Amount: {apiSuccess.data.coupon_total_amount}</h6>
                            </div>
                        }
                    </div>
                </div>
            </div>
            <ToastContainer theme='colored' />
        </>
    )

}

export default withStyles(styles)(Coupon)