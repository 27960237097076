import React, { useState } from 'react';
import { withStyles } from "@material-ui/core/styles";
import { PRIMARY_COLOR } from '../../../utils/CssConstants';
import Helpers from '../../../utils/Helpers';
import SwitchButton from '../../Custom/SwitchButton';
import BlockUserAPI from '../../../services/Users/BlockUserAPI';
import { useDispatch, useSelector } from 'react-redux';
import { getUsers } from '../../../store/actions/Users/usersActions';
import { toast } from 'react-toastify';
import { CircularProgress } from '@material-ui/core';


const styles = {
    campaignListTDataRow: {
        display: 'flex',
        background: '#EDEEF7',
        border: '1px solid #000000',
        boxSizing: 'border-box',
        borderRadius: 10,
        padding: '15px 30px',
        width: '100%',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 10
    },
    campaingListData: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 18,
        padding: '5px 10px',
        textAlign: 'center',
        height: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        wordBreak: 'break-word'
    },
    smallSpace: {
        flex: 1
    },
    mediumSpace: {
        flex: 3
    },
    mediumSpaceTwo: {
        flex: 4
    },
    largeSpace: {
        flex: 7
    },
    red: {
        color: 'rgba(200, 55, 104, 1)',
    },
}

const TableData = ({ classes, brandData, seriesIndex }) => {


console.log("brandData",brandData)

    return (
        <>
            {(brandData.length > 0) && brandData.map((brand, index) =>
                <tr className={classes.campaignListTDataRow} key={index}>
                    <td className={`${classes.campaingListData} ${classes.mediumSpaceTwo}`} >{brand.brand_name}</td>
                    <td className={`${classes.campaingListData} ${classes.mediumSpace}`} >{brand.first_name}</td>
                    <td className={`${classes.campaingListData} ${classes.mediumSpace}`} >{brand.last_name}</td>
                    <td className={`${classes.campaingListData} ${classes.mediumSpaceTwo}`} >{brand.phone_number}</td>
                    <td className={`${classes.campaingListData} ${classes.mediumSpaceTwo}`} >{brand.email}</td>
                    <td className={`${classes.campaingListData} ${classes.mediumSpaceTwo}`} >{brand.sign_up_date}</td>
                </tr>
            )}
        </>
    )
}

export default withStyles(styles)(TableData);