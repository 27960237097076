import axios from 'axios'

/**
 * @description Get API to fetch brand's post's summary
 * @async
 * @function GetLikesFollowers
 * @returns Promise
**/


const GetLikesFollowers = async (brandId = null) => {

    try {

        let apiUrl = `${process.env.REACT_APP_API_URL}/posts/post-likes-followers-count?`
        if (brandId) apiUrl = apiUrl + 'brandId=' + brandId
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access-token')}`
            }
        }

        const response = await axios.get(apiUrl, config)
        return response.data

    } catch (e) {
        return e.response.data
    }
}

export default GetLikesFollowers