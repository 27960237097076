import * as actionTypes from '../../types/users';

const initialState = {
    users: [],
    allUsers: []
};

const usersReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.GET_USER_HISTORY:
            return { ...state, users: action.payload};
        case actionTypes.GET_USER_ALL_HISTORY:
            return { ...state, allUsers: action.payload};
        default:
            return state;
    }
}

export default usersReducer;