import React, { useEffect } from 'react';
import { withStyles } from "@material-ui/core/styles";
import { Container, Grid } from '@material-ui/core';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router';
import wavyBg from '../../assets/images/wavyBackgroundBlue.png';
import logo from '../../assets/images/thumbLogoBlue.png';
import map from '../../assets/images/map.png';
import topImg from '../../assets/images/topcutout.png';
import { BTN_COLOR_THEME } from '../../utils/CssConstants';


const styles = theme => ({
    root: {
        width: '100%',
        minHeight: '100vh',
        backgroundImage: `url(${wavyBg}), url(${map})`,
        backgroundRepeat: 'no-repeat, no-repeat',
        backgroundPosition: '100% 0%, 0px 170px',
        position:'relative', top:0, left:0,
        [theme.breakpoints.down('sm')]: {
            backgroundPosition: '100% 0%, -5% 80%',
        },
    },
    height_100: {
        height: '100%'
    },
    main: {
        paddingTop: '7%',
    },
    pageHeadingContainer:{
        display:'flex',
        alignItems:'center'
    },
    bullet:{
        width:30,
        height:30,
        backgroundColor:BTN_COLOR_THEME,
        display:'inline-block',
        borderRadius:'50%',
    },
    welcomeText:{
        marginLeft:'4px',
        textAlign:'center'
    },
    logoName:{
        fontWeight: 600,
        fontSize: '2.5em',
        color:'#212025'
    },
    logoSubText:{
        fontWeight: 600,
        fontSize: '1.0625em',
        color:'#454447',
        [theme.breakpoints.down('sm')]: {
            color: '#000'
        }
    },
    
    logoSection: {
        flex: 1,
        marginTop: '5%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center'

    },
    rightSection: {
        marginBottom:'40px'
    },
    chatBtn:{
        position:'absolute', bottom:10, left:20,
        width: '203px',
        height: '37px',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'white',
        border: 'none',
        padding: '10px 15px',
        fontSize: '11px',
        fontWeight: '500',
        boxShadow:'2px 2px 4px 1px rgba(0, 0, 0, 0.1)'
    }
});

const Layout = ({ classes, children, pageHeadline, pageSubHeading }) => {

    const navigate = useNavigate();
    const isAuth = useSelector((state) => state.authReducer.isAuthenticated);


    useEffect(() => {
        document.title = "Brand Login"
        if (isAuth) {
            navigate('/dashboard')
        }
    }, [isAuth, navigate]);

    return (
        <div className={classes.root} id="root-wrap">
            {/* <button className={classes.chatBtn}>
                <img src={chat} style={{marginRight: '6px'}} alt="chat" />
                Need Help? Let’s Chat!
            </button> */}                      
            <Container maxWidth='xl' style={{position:'relative', top:0, left:0,}} >
                {/* <img src={topImg} style={{position:'absolute', top:40, right:20}} alt="top" id="top-logo" /> */}
                {/* <button className={classes.chatBtn}>
                    <img src={chat} style={{marginRight: '6px'}} />
                    Need Help? Let’s Chat!
                </button> */}
                <Grid container spacing={5} className={`${classes.main} ${classes.height_100}`}  >
                    <Grid item md={6} xs={12} className={classes.leftSection} >
                        <div className={classes.height_100} style={{ display: 'flex', flexDirection: 'column', alignItems:'center'}}>
                            <div className={classes.pageHeadingContainer} >
                                <div className={classes.bullet} id="bullet-icon"></div>
                                <h2 className={classes.welcomeText} id="welcome-txt">{pageHeadline}</h2>
                            </div>
                            <div className={classes.logoSection} >
                                <img src={logo} width={118} height={118} alt="logo" id="logo" style={{ marginTop: '25%' }} />
                                <h1 className={classes.logoName} id="logo-txt">Thumzup</h1>
                                <p className={classes.logoSubText} id="sub-text">{pageSubHeading}</p>
                            </div>
                        </div>
                    </Grid>
                    <Grid item md={6} xs={12} className={classes.rightSection} >
                        <div>
                            {children}
                        </div>
                    </Grid>
                </Grid>
            </Container>
        </div>
    )
};

export default withStyles(styles)(Layout);