import axios from 'axios';

/**
 * @description Get API to fetch charges for campaign
 * @async
 * @function GetCampaignCharges
 * @returns Promise
**/


const GetCampaignCharges = async () => {

    try {

    let apiUrl = `${process.env.REACT_APP_API_URL}/campaign/charges`
    const config = {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('access-token')
        } 
    }

    const response = await axios.get(apiUrl, config)
    return response.data

    } catch (e) {
        return e
    }

}

export default GetCampaignCharges

    
  