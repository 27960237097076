import React from 'react';
import AppRoutes from './routes/AppRoutes'
import { initializeDatadog } from './utils/dataDogConfig'
import { useSelector } from 'react-redux';
import Axios from './utils/axios';


const App = () => {
  const userDetails = useSelector((state) => state.authReducer.userDetails);
  initializeDatadog(userDetails);

  return (
    <>
      <Axios />
      <AppRoutes />
    </>

  )
}
export default App;
