import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { withStyles } from "@material-ui/core/styles";
import { WHITE_BG, BTN_COLOR_THEME, LOGIN_FONT_COLOR, FIELD_INCORRECT_DATA_VALIDATION, INVALID_FORM_VALIDATION } from '../../utils/CssConstants';
import Layout from './Layout';
import Validate from '../../utils/Validation';
import CircularProgress from '@material-ui/core/CircularProgress';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { login } from '../../store/actions/Auth/authActions';
import PostPasswordResetAPI from '../../services/Auth/PostPasswordResetAPI';


const styles = theme => ({
    loginFormLabels: {
        fontWeight: 600,
        fontSize: '1em',
        fontFamily: 'Poppins',
        color: LOGIN_FONT_COLOR,
        display: 'block'
    },
    invalidFormInputs: INVALID_FORM_VALIDATION,
    fieldError: FIELD_INCORRECT_DATA_VALIDATION,
    loginFormInput: {
        height: 40,
        padding: 10,
        marginBottom: 10,
        width: '80%',
        border: '1px solid #A7A5A5',
        outline: 'none',
        boxSizing: 'border-box',
        borderRadius: 5,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    loginSubmitBtn: {
        background: BTN_COLOR_THEME,
        border: 'none',
        borderRadius: 4,
        height: 40,
        fontFamily: 'Inter, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: 12,
        letterSpacing: '0.05em',
        color: WHITE_BG,
        width: '237px',
        marginBottom: '5%',
        marginTop: '2%',
        display: 'block',
        [theme.breakpoints.down('sm')]: {
            height: 40,
        },
    },
    smallHeading: {
        fontWeight: 600,
        fontSize: '1.375em',
        fontFamily: 'Poppins',
        color: 'black',
        textAlign: 'center',
        marginBottom: '20px',
        marginRight: '25%'
    },
    loginPasswordCont: {
        position: 'relative',
        top: 0,
        left: 0,
        width: '80%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    EyeIcon: {
        position: 'absolute',
        right: 10,
        top: 7,
        cursor: 'pointer'
    },
    btnLoading: {
        marginRight: 10,
        color: "#FFF !important"
    },
    NavigationBtns: {
        display: 'flex'
    },
    inputToolTip: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 300,
        fontSize: 9,
        letterSpacing: '0.05em',
        paddingLeft: 5
    },
});

const PasswordReset = ({ classes }) => {

    const [passVisible, setPassVisible] = useState(false);
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState(false);
    const [confirmPassVisible, setConfirmPassVisible] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const [apiResponse, setApiResponse] = useState("");
    const [errorResponse, setErrorResponse] = useState("");


    const handleVisiblity = (fieldName) => {
        fieldName === 'confirm' ? setConfirmPassVisible(!confirmPassVisible) : setPassVisible(!passVisible);
    }

    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    }

    let query = useQuery();
    const navigate = useNavigate();
    let dispatch = useDispatch();

    const validateForm = () => {
        setPasswordError(false);
        setConfirmPasswordError(false);

        let passwordError = false, confirmPasswordError = false;

        if (Validate.notNullValidation(password) === false || Validate.passwordValidation(password) === false) {
            passwordError = true;
        } else {
            passwordError = false;
        }

        if (Validate.notNullValidation(confirmPassword) === false || password !== confirmPassword) {
            confirmPasswordError = true;
        } else {
            confirmPasswordError = false;
        }

        setPasswordError(passwordError);
        setConfirmPasswordError(confirmPasswordError);

        if (passwordError || confirmPasswordError) {
            return false;
        } else {
            return true;
        }
    }

    const handleSubmit = async () => {
        let isValid = validateForm();
        let resetToken = query.get("resetToken");

        if (isValid) {
            setBtnLoading(true);
            PostPasswordResetAPI(password, resetToken).then(res => {
                setBtnLoading(false);
                if(res.status === "SUCCESS") {
                    setApiResponse(res.message);
                    localStorage.setItem('access-token', res.token)
                    dispatch(login({...res.data, token: res.token}, true));
                    navigate('/dashboard')
                } else {
                    setErrorResponse(res.response.data.error)
                }
            });
        }
    }

    useEffect(() => {
        document.title = "Password Reset - Thumzup"
        let resetToken = query.get("resetToken");
        if(!resetToken) navigate('/');

    }, [navigate, query]);

    return (
        <Layout pageHeadline="Welcome to The Thumzup Admin Area" pageSubHeading="Get paid to post about things you love">
            <h3 className={classes.smallHeading} id="sign-in-head">Password Reset</h3>

            <label className={classes.loginFormLabels} htmlFor="pass" id="pass-label">Password</label>
            <div className={classes.loginPasswordCont}>
                <input className={`${classes.loginFormInput} ${passwordError && classes.invalidFormInputs}`} type={passVisible ? "text" : "password"} id="pass" style={{ width: '100%' }} value={password} onChange={(e) => setPassword(e.target.value)} />
                {passVisible ? <Visibility className={classes.EyeIcon} onClick={() => handleVisiblity('pass')} color="disabled" id="open-eye" /> : <VisibilityOff className={classes.EyeIcon} onClick={() => handleVisiblity('pass')} color="disabled" id="closed-eye" />}
            </div>
            {passwordError && <span className={classes.fieldError} style={{ paddingLeft: 0 }}>Invalid password</span>}
            <p className={classes.inputToolTip} id="password-tooltip">Your password must be 6 characters or more and cannot contain space</p>


            <label className={classes.loginFormLabels} htmlFor="pass" id="pass-label">Confirm Password</label>
            <div className={classes.loginPasswordCont}>
                <input className={`${classes.loginFormInput} ${confirmPasswordError && classes.invalidFormInputs}`} type={confirmPassVisible ? "text" : "password"} id="pass" style={{ width: '100%' }} value={confirmPassword} onChange={(e) => setConfirmPassword(e.target.value)} />
                {confirmPassVisible ? <Visibility className={classes.EyeIcon} onClick={() => handleVisiblity('confirm')} color="disabled" id="open-eye" /> : <VisibilityOff className={classes.EyeIcon} onClick={() => handleVisiblity('confirm')} color="disabled" id="closed-eye" />}
            </div>
            {confirmPasswordError && <span className={classes.fieldError} style={{ paddingLeft: 0 }}>Both passwords must match</span>}

            {apiResponse !== "" && <span className={classes.fieldError} style={{ paddingLeft: 0, color: "green" }}>{apiResponse}</span>}
            {errorResponse !== "" && <span className={classes.fieldError} style={{ paddingLeft: 0 }}>{errorResponse}</span>}

            <button className={classes.loginSubmitBtn} id="reset-btn" onClick={() => handleSubmit()} disabled={btnLoading}>{btnLoading ? <CircularProgress size={20} className={classes.btnLoading} /> : "Reset"}</button>   
        </Layout>
    )
};

export default withStyles(styles)(PasswordReset);