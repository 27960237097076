import React, { useState, useEffect } from 'react'
import { withStyles } from '@material-ui/core'
import GetAddressSuggestionsAPI from '../../services/Map/GetAddressSuggestionsAPI'


const styles = {
    dropdown: {
        position: 'relative', 
        display: 'inline-block',
        width: '86%',
    },
    dropdownContent: {
        backgroundColor: '#f9f9f9',
        minWidth: 655,
        boxShadow: '0px 8px 16px 0px rgba(0,0,0,0.2)',
        padding: '10px 5px',
        zIndex: 1,
        position: 'absolute',
        display: 'none'
    },
    dropdownOption: {
        cursor: 'pointer',
        listStyle: 'none',
        borderRadius: 5,
        '&:hover': {
            backgroundColor: '#DEEBFF'
        },
        
    },
    dropdownInput: {
        width: '100%',
        border: 'none',
        outline: 'none',
        height: '100%',
        padding: '0 5px'
    }
}

const MapDropdown = ({ classes, onChange, inputValue }) => {

    const [search, setSearch] = useState(inputValue)
    const [toggleDropdown, setToggleDropdown] = useState(false)
    const [options, setOptions] = useState([])
    const [loading, setLoading] = useState(false)

    // const fetchSuggestions = (query) => {
    //     let suggestions = GetAddressSuggestionsAPI(query).then(resp => {
    //         setOptions(getAddress(resp.data.predictions))
    //     });
    //     return new Promise(resolve => resolve(suggestions))
    // };

    const loadSuggestions = async (data) => {
        await onChange({ data })
        setToggleDropdown(false)
        setLoading(false)
        setSearch(data.label)
    }

    const getAddress = (googleData) => {
        let addresses = googleData.map(num => {
            return {
                label: num.description,
                value: num.place_id
            }
        })
        return addresses
    }

    useEffect(() => {
        if(!toggleDropdown || search === '') {
            return
        }
        setLoading(true)
        setOptions([])
        const getData = setTimeout(() => {
            GetAddressSuggestionsAPI(search).then(res => {
                setLoading(false)
                if(res.data.predictions) {
                    setOptions(getAddress(res.data.predictions))
                }
            }).catch(e => e.response && e.response.data.error)
        }, 1000)
        return () => clearTimeout(getData)
    }, [search])

    return (
        <div className={classes.dropdown}>
            <input className={classes.dropdownInput} onChange={e => {setSearch(e.target.value); setToggleDropdown(true)}} value={search} />
            <div className={classes.dropdownContent} style={{ display: toggleDropdown && 'block' }}>
                {loading && <li className={classes.dropdownOption} style={{ textAlign: 'center' }}>Loading...</li>}
                {(options.length === 0 && !loading) && <li className={classes.dropdownOption} style={{ textAlign: 'center' }}>No records found</li>}
                {options.map((option, i) => (
                    <li className={classes.dropdownOption} onClick={() => loadSuggestions(option)} key={i} value={option.value}>{option.label}</li>
                ))}
            </div>  
        </div>
    )

}


export default withStyles(styles)(MapDropdown)