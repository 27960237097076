import React, { useRef, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { rejectPost, getPosts } from '../../../store/actions/Dashboard/dashboardActions';
import UpdatePostStatusAPI from '../../../services/Dashboard/UpdatePostStatusAPI';
import GetPostsAPI from '../../../services/Dashboard/GetPostsAPI';
import CancelIcon from '@material-ui/icons/Cancel';
import { withStyles } from "@material-ui/core/styles";
import { WHITE_BG, BTN_COLOR_THEME, PRIMARY_COLOR } from '../../../utils/CssConstants';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const styles = {
    modal: {
        position: 'absolute',
        zIndex: 999,
        marginTop: 15,  
        width: '21%' 
    },
    Rejectmodal: {
        background: WHITE_BG,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 6,
        boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
        width: '100%',
    },
    RejectmodalHeader: {
        padding: 5,
        display: 'flex',
        justifyContent: 'flex-end'
    },
    crossIcon: {
        cursor: 'pointer',
        color: PRIMARY_COLOR,
    },
    RejectmodalContent: {
        display: 'flex',
        flexDirection: 'column',
        padding: '10px 20px',
    },
    RejectmodalText: {
        fontStyle: 'normal',
        fontFamily: 'Poppins',
        fontSize: 14,
        fontWeight: 600,
        marginBottom: 5,
        color: '#504F4F',
    },
    RejectmodalDropdown: {
        borderRadius: 6,
        boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.05)',
        border: 'none',
        padding: 5,
        outline: 'none',
        "&:child": {
            fontFamily: 'Poppins',
        }
    },
    RejectmodalFooter: {
        display: 'flex',
        justifyContent: 'center',
        padding: 5,
        marginBottom: 10,
    },
    RejectmodalSubmit: {
        background: BTN_COLOR_THEME,
        border: 'none',    
        borderRadius: 8,
        width: '40%',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontSize: 14,
        letterSpacing: '0.05em',
        color: WHITE_BG,
        fontWeight: 'normal',
        padding: 6,
    },
    open: {
        display: 'flex',
        right: 10,
    },
    close: {
        display: 'none',
    }
};

const RejectModal = ({ classes, showModal, closeModal, setApproveReject, index, currentPage, paginate, postIndexinArray, totalPosts, selectedFilter, userDetails, brandView }) => {

    const [rejectionReason, setRejectionReason] = useState(1701);

    const node = useRef();
    const dispatch = useDispatch();

    const handleReject = () => {
        UpdatePostStatusAPI(index, "705", null, rejectionReason).then(data => {
            if(data.status === "SUCCESS") {
                dispatch(rejectPost(index));
                setTimeout(function() {  
                        GetPostsAPI(currentPage, 10, selectedFilter, (brandView ? userDetails.brand_id: null)).then(data => {
                            if(postIndexinArray === 0 && totalPosts === 1) {
                                paginate(index, currentPage - 1)
                            }
                            dispatch(getPosts(data));
                        }).catch(err => console.log(err))
                }, 500);
                toast.success(data.message)
            } else {
                toast.error(data.error);
            }
        }).catch(err => console.log(err))

        closeModal()
        setApproveReject()
    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (node.current && !node.current.contains(event.target)) {
                closeModal()
            }
        }
        
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };

        // eslint-disable-next-line
    }, [node]);

    return (
        <div ref={node} className={`${classes.modal} ${'col-auto'} ${showModal ? classes.open : classes.close}`}>
            <div className={classes.Rejectmodal}>
                <div className={classes.RejectmodalHeader}>
                    <CancelIcon color="primary" className={classes.crossIcon} onClick={closeModal} />
                </div>
                <div className={classes.RejectmodalContent}>
                    <span className={classes.RejectmodalText}>Reason for rejection</span>
                    <select className={classes.RejectmodalDropdown} value={rejectionReason} onChange={e => setRejectionReason(e.target.value)}>
                        <option value="1701">Post image quality is unclear</option>
                        <option value="1702">Post image is not related to the brand</option>
                        <option value="1703">Post content is not safe for viewing</option>
                        <option value="1704">Post content is not complimentary to the brand</option>
                        <option value="1705">Thumzup Test Post</option>
                        <option value="1706">Post was copied from the internet</option>
                        <option value="1707">Post deleted</option>
                        <option value="1708">Post is a duplicate</option>
                    </select>
                </div>
                <div className={classes.RejectmodalFooter}>
                    <button className={classes.RejectmodalSubmit} onClick={handleReject}>Submit</button>
                </div>
            </div>
        </div>
    );
};

export default withStyles(styles)(RejectModal);