import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { WHITE_BG, PRIMARY_COLOR } from '../../utils/CssConstants';


const styles = {
    switch: {
        position: 'relative',
        display: 'inline-block',
        width: 32,
        height: 20,
        '& input': {
            opacity: 0,
            width: 0,
            height: 0
        }
    },      
    slider: {
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: '#CCC',
        '-webkit-transition': '.4s',
        transition: '.4s',
        borderRadius: 34,
        "&::before": {
            position: 'absolute',
            content: "''",
            height: 16,
            width: 16,
            left: 1,
            bottom: 2,
            backgroundColor: WHITE_BG,
            '-webkit-transition': '.4s',
            transition: '.4s',
            borderRadius: '50%'
        }
    },
    checkbox: {
        '&:checked + $slider': {
            backgroundColor: PRIMARY_COLOR
        },
        '&:focus + $slider': {
            boxShadow: '0 0 1px ' + PRIMARY_COLOR
        },
        '&:checked + $slider::before': {
            '-webkit-transform': 'translateX(13px)',
            '-ms-transform': 'translateX(13px)',
            transform: 'translateX(13px)'
        }
    },
    round: {
        borderRadius: 34,
    },
    '$slide::before $round::before': {
        borderRadius: '50%',
    }
};

const SwitchButton = ({ classes, checked, handleClick, defaultChecked, handleChange, disabled }) => {
    return (
        <>
            <label className={classes.switch}>
                <input className={classes.checkbox} type="checkbox" checked={checked} defaultChecked={defaultChecked} onChange={handleChange} disabled={disabled} />
                <span className={`${classes.slider} ${classes.round}`} style={{ cursor: disabled ? 'default' : 'pointer' }}></span>
            </label>  
        </>
    )
}

export default withStyles(styles)(SwitchButton);