import axios from 'axios';

/**
 * @description POST API for resetting password
 * @async
 * @function PostPasswordResetAPI
 * @returns Promise
**/


const PostPasswordResetAPI = async (newPass, resetToken) => {
  
    try {

        let apiUrl = process.env.REACT_APP_API_URL + "/reset-password";
        const formData = {
            password: newPass,
            reset_password_token: resetToken
        };

        const response = await axios.post(apiUrl, formData);
        return response.data;
        
    } catch (e) {
      return e;
    }
}

export default PostPasswordResetAPI;
  