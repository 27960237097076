import React, { useState, useEffect } from 'react';
import { withStyles } from "@material-ui/core/styles";
import { Visibility, VisibilityOff } from '@material-ui/icons';
import Layout from './Layout';
import { useNavigate, useLocation, createSearchParams } from "react-router-dom";
import { WHITE_BG, BTN_COLOR_THEME, LOGIN_FONT_COLOR, FIELD_INCORRECT_DATA_VALIDATION, INVALID_FORM_VALIDATION, PRIMARY_COLOR } from '../../utils/CssConstants';
import Validate from '../../utils/Validation';
import PostRegisterBrandAPI from '../../services/Auth/PostRegisterBrandAPI';
import { login } from '../../store/actions/Auth/authActions';
import { useDispatch } from 'react-redux';
import AmericanFlag from '../../assets/images/american-flag.png';
import CircularProgress from '@material-ui/core/CircularProgress';



const styles = theme => ({
    loginFormLabels: {
        fontWeight: 600,
        fontSize: 14,
        fontStyle: 'normal',
        fontFamily: 'Poppins',
        color: LOGIN_FONT_COLOR,
        display: 'block'
    },
    invalidFormInputs: INVALID_FORM_VALIDATION,
    fieldError: FIELD_INCORRECT_DATA_VALIDATION,
    loginFormInput: {
        height: 40,
        padding: 10,
        marginBottom: 10,
        width: '80%',
        border: '1px solid #A7A5A5',
        outline: 'none',
        boxSizing: 'border-box',
        borderRadius: 5,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    phoneContainer: {
        marginBottom: 10,
        width: '80%',
        border: '1px solid #A7A5A5',
        outline: 'none',
        boxSizing: 'border-box',
        borderRadius: 5,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        display: 'flex',
        background: WHITE_BG,
        alignItems: 'center'
    },
    phoneSelect: {
        border: 'none',
        width: '15%',
        margin: 0,
        height: 50,
        outline: 'none',
        padding: 10,
        boxSizing: 'border-box',
        borderRadius: 5,
        marginRight: 5,
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 14,
        color: "#A7A5A5"
    },
    phoneInput: {
        border: 'none', 
        borderLeft: '1px solid #A7A5A5', 
        borderRadius: 0, 
        width: '85%',
        height: 50,
        outline: 'none',
        padding: 10,
        boxSizing: 'border-box'
    },
    loginPasswordCont: {
        position: 'relative',
        top: 0,
        left: 0,
        width: '80%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    EyeIcon: {
        position: 'absolute',
        right: 10,
        top: 7,
        cursor: 'pointer'
    },
    loginSubmitBtn: {
        background: BTN_COLOR_THEME,
        border: 'none',
        borderRadius: 4,
        height: 40,
        fontFamily: 'Inter, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: 12,
        letterSpacing: '0.05em',
        color: WHITE_BG,
        width: '237px',
        marginBottom: '5%',
        marginTop: '4%',
        [theme.breakpoints.down('sm')]: {
            height: 40,
        },
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    loginRemeberMe: {
        marginRight: 10,
        height: 20,
        width: 20,
    },
    loginRemeberMeText: {
        fontFamily: 'Mulish',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 14,
        letterSpacing: '0.05em',
        color: PRIMARY_COLOR,
    },
    smallHeading: {
        fontWeight: 600,
        fontSize: 22,
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        letterSpacing: '0.05em',
        color: 'black',
        textAlign: 'center',
        marginBottom: '20px',
        marginRight: '25%'
    },
    infoText: {
        fontWeight: 600,
        fontSize: '0.875em',
        fontFamily: 'Poppins',
        color: 'black',
    },
    link: {
        color: PRIMARY_COLOR,
        cursor: 'pointer'
    },
    bottomLinks: {
        fontWeight: 500,
        fontSize: '11px',
        fontFamily: 'Poppins',
        color: PRIMARY_COLOR,
        textDecoration: 'none',
        "&:hover": {
            textDecoration: 'none',
            color: PRIMARY_COLOR,
        },
    },
    registerNameFieldsCont: {
        display: 'flex',
        justifyContent: 'space-between',
        width: '80%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    registerNameFields: {
        display: 'flex',
        flexDirection: 'column',
        width: '49%'

    },
    loginRemeberMeSection: {
        width: '70%'
    },
    termsText : {
        fontFamily: 'Mulish',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 11,
        letterSpacing: '0.05em'
    },
    anchorLinks: {
        textDecoration: 'none',
        color: PRIMARY_COLOR,
        '&:hover': {
            color: PRIMARY_COLOR
        }
    },
    inputToolTip: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 300,
        fontSize: 9,
        letterSpacing: '0.05em',
        paddingLeft: 5
    },
    btnLoading: {
        marginRight: 10,
        color: "#FFF !important"
    }
});

const BrandRegister = ({ classes }) => {

    const navigate = useNavigate();
    let dispatch = useDispatch()
    const phoneCode = "+1";

    const [passVisible, setPassVisible] = useState(false);
    const [passVisibleRetype, setPassVisibleRetype] = useState(false);
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState(false);
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState(false);
    const [passwordRetype, setPasswordRetype] = useState("");
    const [passwordRetypeError, setPasswordRetypeError] = useState(false);
    const [firstName, setFirstName] = useState("");
    const [firstNameError, setFirstNameError] = useState(false);
    const [lastName, setLastName] = useState("");
    const [lastNameError, setLastNameError] = useState(false);
    const [organization, setOrganization] = useState("");
    const [organizationError, setOrganizationError] = useState(false);
    const [phoneError, setPhoneError] = useState(false);
    const [phone, setPhone] = useState("");
    const [fullPhone, setFullPhone] = useState(phoneCode + phone);
    const [apiErrorMsg, setApiErrorMsg] = useState();
    const [btnLoading, setBtnLoading] = useState(false);

    document.title = "Brand Register - Thumzup"
    let queryParams = new URLSearchParams(useLocation().search);

    const handleVisiblity = () => {
        setPassVisible(!passVisible)
    }

    const handleVisiblityRetype = () => {
        setPassVisibleRetype(!passVisibleRetype)
    }

    const validateForm = () => {
        setEmailError(false);
        setPasswordError(false);
        setPasswordRetypeError(false);
        setFirstNameError(false);
        setLastNameError(false);
        setOrganizationError(false);
        setPhoneError(false);
        setApiErrorMsg(false);

        let emailError, passwordError, passwordRetypeError, firstNameError, lastNameError, organizationError, phoneError = false;


        if (Validate.emailValidation(email) === false) {
            emailError = true;
        } else {
            emailError = false;
        }

        if (Validate.notNullValidation(password) === false || Validate.passwordValidation(password) === false) {
            passwordError = true;
        } else {
            passwordError = false;
        }

        if (Validate.notNullValidation(passwordRetype) === false || password !== passwordRetype) {
            passwordRetypeError = true;
        } else {
            passwordRetypeError = false;
        }

        if (Validate.notNullValidation(firstName) === false) {
            firstNameError = true;
        } else {
            firstNameError = false;
        }

        if (Validate.notNullValidation(lastName) === false) {
            lastNameError = true;
        } else {
            lastNameError = false;
        }

        if (Validate.notNullValidation(organization) === false) {
            organizationError = true;
        } else {
            organizationError = false;
        }
        
        if (Validate.phoneNumberValidation(fullPhone) === false || Validate.numberLengthValidation(parseInt(phone), 10) === false) {
            phoneError = true;
        } else {
            phoneError = false;
        }

        setEmailError(emailError);
        setPasswordError(passwordError);
        setPasswordRetypeError(passwordRetypeError);
        setFirstNameError(firstNameError);
        setLastNameError(lastNameError);
        setOrganizationError(organizationError);
        setPhoneError(phoneError);

        if(emailError || passwordError || passwordRetypeError || firstNameError || lastNameError || organizationError || phoneError) {
            return false;
        } else {
            return true;
        }
    } 

    
    const handleSubmit = () => {
        let isValid = validateForm();
        if(isValid) {
            setBtnLoading(true);
            let data={
                "brand_name": organization,
                "email": email,
                "first_name": firstName,
                "last_name": lastName,
                "password": password,
                "phone_number": phone,
              };
            PostRegisterBrandAPI(data, queryParams.get('thumzup') === 'true' ? true : false)
            .then(res=>{
                if(res){
                    setBtnLoading(false);
                    if(res.status==='SUCCESS' && queryParams.get('thumzup') === 'true'){
                        navigate('/get-started/');
                        localStorage.setItem('access-token', res.data.token)
                        dispatch(login({...res.data.authentication_response, token: res.data.token}, false));
                    } else if(res.status === 'SUCCESS') {
                        navigate('/signup-verification/', {email, id: res.data.admin_id});
                        navigate({
                            pathname: "/signup-verification/",
                            search: `?${createSearchParams({
                                email,
                                id: res.data.admin_id
                            })}`
                        });
                    } else {
                        setApiErrorMsg(res.error)
                    }
                    
                }else{
                    setApiErrorMsg('Error in calling api')
                }
            })
            .catch(error=>{
                setApiErrorMsg(error)
            })
        }
    }

    const checkNumericValues = (text, variable) => {
        const re = /^[A-Za-z']*$/;
        if((text === '' || re.test(text)) && variable === "firstName") {
            setFirstName(text);
        }
        if((text === '' || re.test(text)) && variable === "lastName") {
            setLastName(text);
        }
    }

    useEffect(() => {
        document.title = "Register - Thumzup"
    }, []);

    return (
        <Layout pageHeadline="Start a Thumzup Campaign Today!" pageSubHeading="Next Generation Social Media Marketing">
            <h3 className={classes.smallHeading} id="form-heading">Sign Up</h3>
            <form autoComplete="off">
                <label className={classes.loginFormLabels} htmlFor="email" id="email-label">Email</label>
                <input className={`${classes.loginFormInput} ${emailError && classes.invalidFormInputs}`} type="email" id="email" value={email} onChange={e => setEmail(e.target.value)} style={{ marginBottom: 0 }} />
                {emailError && <span className={classes.fieldError} id="error-email" style={{ paddingLeft: 0 }}>Invalid email address</span>}
                <p className={classes.inputToolTip} id="email-tooltip">Your email address serves as your username.</p>
                
                <label className={classes.loginFormLabels} htmlFor="pass" id="pass-label">Password</label>
                <div className={classes.loginPasswordCont}>
                    <input className={`${classes.loginFormInput} ${passwordError && classes.invalidFormInputs}`} type={passVisible ? "text" : "password"} id="pass" style={{ width: '100%', marginBottom: 0 }} value={password} onChange={e => setPassword(e.target.value)} />
                    {passVisible ? <Visibility className={classes.EyeIcon} onClick={handleVisiblity} id="open-eye" color="disabled" /> : <VisibilityOff className={classes.EyeIcon} onClick={handleVisiblity} id="closed-eye" color="disabled" />}
                </div>
                {passwordError && <span className={classes.fieldError} id="error-password" style={{ paddingLeft: 0 }}>Invalid password</span>}
                <p className={classes.inputToolTip} id="password-tooltip">Your password must be 6 characters or more and cannot contain space</p>

                <label className={classes.loginFormLabels} htmlFor="retypepass" id="retypepass-label">Retype Password</label>
                <div className={classes.loginPasswordCont}>
                    <input className={`${classes.loginFormInput} ${passwordRetypeError && classes.invalidFormInputs}`} type={passVisibleRetype ? "text" : "password"} id="retypepass" style={{ width: '100%' }} value={passwordRetype} onChange={e => setPasswordRetype(e.target.value)} />
                    {passVisibleRetype ? <Visibility className={classes.EyeIcon} onClick={handleVisiblityRetype} id="open-eye-retype" color="disabled" /> : <VisibilityOff className={classes.EyeIcon} onClick={handleVisiblityRetype} id="closed-eye-retype" color="disabled" />}
                </div>
                {passwordRetypeError && <span className={classes.fieldError} id="error-retype-pass" style={{ paddingLeft: 0 }}>Both passwords must match</span>}

                <div>
                    <div className={classes.registerNameFieldsCont}>
                        <div className={classes.registerNameFields}>
                            <label className={classes.loginFormLabels} htmlFor="first-name" id="first-name-label">First Name</label>
                            <input className={`${classes.loginFormInput} ${firstNameError && classes.invalidFormInputs}`} type="text" id="first-name" style={{ width: '100%' }} value={firstName} onChange={e => checkNumericValues(e.target.value, "firstName")} />
                            {firstNameError && <span className={classes.fieldError} id="error-first-name" style={{ paddingLeft: 0 }}>First name is required</span>}

                        </div>
                    
                        <div className={classes.registerNameFields}>
                            <label className={classes.loginFormLabels} htmlFor="last-name" id="last-name-label">Last Name</label>
                            <input className={`${classes.loginFormInput} ${lastNameError && classes.invalidFormInputs}`} type="text" id="last-name" style={{ width: '100%' }} value={lastName} onChange={e => checkNumericValues(e.target.value, "lastName")} />
                            {lastNameError && <span className={classes.fieldError} id="error-last-name" style={{ paddingLeft: 0 }}>Last name is required</span>}
                        </div>
                    </div>
                </div>
               
                <label className={classes.loginFormLabels} htmlFor="org-name" id="org-name-label">Organization Name</label>
                <input className={`${classes.loginFormInput} ${organizationError && classes.invalidFormInputs}`} type="text" id="org-name" value={organization} onChange={(e) => setOrganization(e.target.value)} />
                {organizationError && <span className={classes.fieldError} id="error-org-name" style={{ paddingLeft: 0 }}>Organization name is required</span>}

                <label className={classes.loginFormLabels} htmlFor="phone" id="phone-label">Phone Number</label>
                <div className={`${classes.phoneContainer} ${phoneError && classes.invalidFormInputs}`}>
                    <div className={classes.phoneSelect}>
                        <span style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }} id="phone-code"><img src={AmericanFlag} alt="american-flag" id="flag-icon" width={30} height={30} /> +1</span>
                    </div>
                    <input className={classes.phoneInput} type="number" id="phone" value={phone} onChange={e => {setFullPhone(phoneCode + e.target.value); setPhone(e.target.value)}} />
                </div>
                    {phoneError && <span className={classes.fieldError} id="error-phone" style={{ paddingLeft: 0 }}>Phone number is invalid</span>}


                {apiErrorMsg && <span className={classes.fieldError} id="error-api-message" style={{ paddingLeft: 0}}>{apiErrorMsg}</span>}


                <button className={classes.loginSubmitBtn} type="button" id="submit-btn" onClick={() => handleSubmit(phoneCode, phone)} disabled={btnLoading}>{btnLoading ? <CircularProgress size={20} className={classes.btnLoading} /> : "Get Started"}</button>
                <div className={classes.loginRemeberMeSection}>
                    <p className={classes.termsText} id="terms-txt">By clicking ‘Get Started’, you agree to the <a href="https://www.thumzupmedia.com/advertiser-terms-of-service" className={classes.anchorLinks} id="terms-and-conditions">Terms & Conditions</a>, and acknowledge receipt of our <a href="https://www.thumzupmedia.com/privacy-policy/" className={classes.anchorLinks} id="privacy-notice">Privacy Notice.</a></p>
                </div>
            </form>  
        </Layout>
    )
};

export default withStyles(styles)(BrandRegister);