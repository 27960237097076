const Validation = {
    notNullValidation(text) {
        if (text === null || text === "" || text === 0 || text === undefined || text.length === 0) {
            return false;
        }
        return true;
    },

    textValidation(text) {
        //Regex for text or null
        let textReg = /^[a-zA-z ]*$/;

        if (textReg.test(text)) {
            return true;
        } else {
            return false;
        }
    },

    numberValidation(number) {
        //Regex for text or null
        let numberReg = /^([1-9][0-9]+|[1-9])$/;
        if (numberReg.test(number)) {
            return true;
        } else {
            return false;
        }
    },
    campaignkeywordsValidation(text) {
        //Regex for text or null
        let textReg = new RegExp("^[a-zA-z0-9 #]{1,255}$");
        if (textReg.test(text)) {
            return true;
        } else {
            return false;
        }
    },
    fieldLengthValidation(text, length = 50) {
        text = text.replace(/(\r\n|\n|\r)/gm, "");
        //Regex for text or null
        let textReg = new RegExp("^[a-zA-z0-9,*%#$*@!+-_ ]{1," + length + "}$");
        // let textReg = new RegExp("^[a-zA-z0-9,*%#$*@!.\+\-_ ]{1,155}$");
        if (textReg.test(text)) {
            return true;
        } else {
            return false;
        }
    },
    //Password validation is return value in string.
    passwordValidation(password) {
        let passRegex = new RegExp("^.{6,}$");
        if (passRegex.test(password)) {
            return true;
        } else {
            return false;
        }
    },
    phoneNumberValidation(phone) {
        let phoneRegex = /^\+(9[976]\d|8[987530]\d|6[987]\d|5[90]\d|42\d|3[875]\d|2[98654321]\d|9[8543210]|8[6421]|6[6543210]|5[87654321]|4[987654310]|3[9643210]|2[70]|7|1)\d{1,14}$/
        if (phoneRegex.test(phone)) {
            return true;
        } else {
            return false;
        }
    },
    emailValidation(email) {
        // eslint-disable-next-line 
        let EmailReg = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

        if (EmailReg.test(email)) {
            return true;
        } else {
            return false;
        }
    },
    cardNumberValidation(number) {
        let textReg = new RegExp("^[0-9]{13,16}$");
        if (textReg.test(number)) {
            return true;
        } else {
            return false;
        }
    },
    numberLengthValidation(number, length) {
        let textReg = new RegExp("^[0-9]{" + length + "}$");
        if (textReg.test(number)) {
            return true;
        } else {
            return false;
        }
    },
    textLengthValidation(text, length) {
        let textReg = new RegExp("^[a-zA-z ]{1," + length + "}$");
        if (textReg.test(text)) {
            return true;
        } else {
            return false;
        }
    },
    cardExpiryMonth(month, year) {

        let today_mm = new Date();
        let today_yy = new Date();
        today_yy = today_yy.getFullYear() % 100;
        today_mm = today_mm.getMonth() + 1;
        let textReg = new RegExp("^0[1-9]|1[0-2]$");

        if (textReg.test(month)) {
            year = parseInt(year);
            month = parseInt(month);
            if (year === today_yy) {
                if (month <= today_mm) {
                    return false;
                } else {
                    return true;
                }
            }
        } else {
            return false;
        }

    },
    cardExpiryYear(number) {
        let today_yy = new Date();
        today_yy = today_yy.getFullYear() % 100;
        if (number >= today_yy) {
            return true;
        } else {
            return false;
        }
    },
    latitudeValidation(lat) {
        let latRegex = /^(\+|-)?(?:90(?:(?:\.0{1,6})?)|(?:[0-9]|[1-8][0-9])(?:(?:\.[0-9]{1,20})?))$/;
        if (latRegex.test(lat)) {
            return true;
        } else {
            return false;
        }
    },
    longitudeValidation(long) {
        let longRegex = /^(\+|-)?(?:180(?:(?:\.0{1,6})?)|(?:[0-9]|[1-9][0-9]|1[0-7][0-9])(?:(?:\.[0-9]{1,20})?))$/;
        if (longRegex.test(long)) {
            return true;
        } else {
            return false;
        }
    },
    zipcodeValidation(zipcode) {
        let zipcodeRegex = /^\d{5}(?:[-\s]\d{4})?$/;
        if (zipcodeRegex.test(zipcode)) {
            return true;
        } else {
            return false;
        }
    },
    postDurationValidtion(duration) {
        let durationRegex = /^(1[4-9]|[2-9]\d|[1-2]\d{2}|3[0-5]\d|36[0-5])$/;
        if (durationRegex.test(duration)) {
            return true;
        } else {
            return false;
        }
    },
    recievedDiscountValidation(duration) {
        let durationRegex = /^([1-9]|[1-9][0-9])$/;

        if (durationRegex.test(duration)) {
            return true;
        } else {
            return false;
        }
    },
    recievedDiscountCoupenCodeValidation(discount) {
        let discountRegex = /^[a-zA-Z0-9]{3,8}$/;

        if (discountRegex.test(discount)) {
            return true;
        } else {
            return false;
        }
    },
    minimumFollowersValidation(followers) {
        let followersRegex = /^(?:[5-9]\d{1,5}|[1-9]\d{2,5}|1000000|50)$/;
        if (followersRegex.test(followers)) {
            return true;
        } else {
            return false;
        }
    }
};

export default Validation;