import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { withStyles } from '@material-ui/styles'
import { WHITE_BG, GRAY_BG_F7 } from '../../utils/CssConstants'
import CircularProgress from '@material-ui/core/CircularProgress'
import GetPostSummaryReportAPI from '../../services/BrandSummary/GetPostSummaryReportAPI'
import Pagination from '@mui/material/Pagination'
import TableData from './TableData/TableData'
import { toast } from 'react-toastify'
import GetLikesFollowers from '../../services/BrandSummary/GetLikesFollowers'

const styles = theme => ({
    rootUsers: {
        display: 'flex',
        background: WHITE_BG,
        margin: '30px 10px',
        padding: 15,
        borderRadius: 20
    },
    componentCont: {
        background: GRAY_BG_F7,
        width: '100%',
        borderRadius: 30,
        height: 'calc(100vh + 180px)',
    },
    loaderContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: 'calc(100vh - 290px)'
    },
    campaignListTop: {
        margin: 30,
        marginLeft: 50,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'baseline',
        [theme.breakpoints.down('sm')]: {
            margin: '20px 20px'
        }
    },
    campaignListHeading: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 24,
        marginBottom: 20,
        width: 'fit-content'
    },
    campaignListContent: {
        width: '100%',
        marginBottom: 20,
        // height: '75%',
        [theme.breakpoints.down('sm')]: {
            overflowX: 'auto'
        }
    },
    campaignListTable: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            width: 1400
        }
    },
    campaignListTheadRow: {
        display: 'flex',
        background: WHITE_BG,
        border: '1px solid #000000',
        boxSizing: 'border-box',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: 10,
        padding: '15px 30px',
        width: '100%',
        minHeight: '12%',
        flexWrap: 'wrap',
        marginBottom: 10,
        justifyContent: 'center',
        alignItems: 'center'
    },
    campaingListHeadings: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 20,
        padding: '5px 10px',
        borderRight: '1px solid #CECECE',
        textAlign: 'center',
        height: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        "&:last-child": {
            borderRight: 'none'
        }
    },
    smallSpace: {
        flex: 1
    },
    mediumSpace: {
        flex: 3
    },
    mediumSpaceTwo: {
        flex: 4
    },
    paginationCont: {
        display: 'flex',
        justifyContent: 'center',
        width: '100%'
    }
})


const BrandSummary = ({ classes }) => {

    const [currentPage, setCurrentPage] = useState(1)
    const [loader, setLoader] = useState(true)
    const [report, setReport] = useState([])
    const [totalReports, setTotalReports] = useState(0)
    const [errorData, setErrorData] = useState("Please select a brand")
    const postsPerPage = 10
    const [likesFollower, setLikesFollower] = useState([])
    const userDetails = useSelector((state) => state.authReducer.userDetails)
    const brandView = useSelector((state) => state.authReducer.brandView)
    let [isLikesColumn, setIsLikesColumn] = useState(false)
    // Get current post summary
    const indexOfLastPost = currentPage * postsPerPage
    const indexOfFirstPost = indexOfLastPost - postsPerPage
    const currentPosts = report



    const fetchData = async (page, perPage, brandId) => {
        setLoader(true);
        try {
            let data;
            if (userDetails.type_id === 1801) {
                // If userDetails.type_id is 1801, don't pass brandId
                data = await GetPostSummaryReportAPI(page, perPage);
            } else {
                data = await GetPostSummaryReportAPI(page, perPage, brandId);
            }
            if (data.status === 'SUCCESS') {
                setReport(data.data.post_summary_report);
                setTotalReports(data.data.total_count);
                setIsLikesColumn(data?.data?.likes_column_enable)
                if (data.data.total_count <= 0) setErrorData('No records');
            } else {
                toast.error(data.error);
            }
            await GetLikesFollowers(userDetails.brand_id)
                .then(response => {
                    setLikesFollower(response);
                })
                .catch(error => {
                    toast.error(error.error);
                });
        } catch (err) {
            toast.error(err?.error);
        } finally {
            setLoader(false);
        }
    };


    const handlePagination = async (event, value) => {
        if (!brandView && userDetails.type_id !== 1801) {
            setErrorData('Please select a brand');
            setReport([]);
            return;
        }
        setCurrentPage(value);
        await fetchData(value, postsPerPage, userDetails.brand_id);
    };

    useEffect(() => {
        document.title = "Brand Summary - Thumzup"
        if (!brandView) {
            setErrorData("Please select a brand")
            setLoader(false)
            setReport([])
            setTotalReports(0)
            setCurrentPage(1)
        } else {
            fetchData(1, postsPerPage, userDetails.brand_id);
        }

    }, [userDetails, brandView])

    useEffect(() => {
        if (userDetails.type_id === 1801) {
            fetchData(1, postsPerPage);
        }

    }, [])


    return (
        <div className={classes.rootUsers}>
            <div className={classes.componentCont}>
                <div className={classes.campaignListTop}>
                    <h3 className={classes.campaignListHeading} id="page-heading">Brand Summary</h3>
                    {totalReports > 0 &&
                        <div className='ms-auto d-flex '>
                            <p className='texttheme'>Total Followers (Reach): {likesFollower?.follower_count == null ? 0 : likesFollower?.follower_count}</p>
                            <p className='texttheme ms-5 me-5'>Total Likes: {likesFollower?.like_count == null ? 0 : likesFollower?.like_count}</p>
                        </div>
                    }
                </div>

                {loader === true ?
                    <div className={classes.loaderContainer}>
                        <CircularProgress size={80} />
                    </div>
                    :
                    (totalReports > 0) ?
                        <>
                            <div className={classes.campaignListContent}>
                                <table className={classes.campaignListTable}>
                                    <thead>
                                        <tr className={classes.campaignListTheadRow}>
                                            <th className={`${classes.campaingListHeadings} ${classes.smallSpace}`} id="index">ID</th>
                                            <th className={`${classes.campaingListHeadings} ${classes.mediumSpaceTwo}`} id="instagram-handle">Instagram Handle</th>
                                            <th className={`${classes.campaingListHeadings} ${classes.mediumSpaceTwo}`} id="post-date">Post Date</th>
                                            <th className={`${classes.campaingListHeadings} ${classes.mediumSpace}`} id="followers-count">Followers Count</th>
                                            {isLikesColumn && <th className={`${classes.campaingListHeadings} ${classes.mediumSpace}`} id="likes-count">Likes Count</th>}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <TableData postsData={currentPosts} isLikesColumn={isLikesColumn} seriesIndex={currentPage < 2 ? currentPage : indexOfFirstPost + 1} />
                                    </tbody>
                                </table>
                            </div>

                            <div className={classes.paginationCont}>
                                <Pagination color='primary' onChange={handlePagination} count={Math.ceil(totalReports / postsPerPage)} page={currentPage} showFirstButton showLastButton />
                            </div>
                        </>
                        :
                        <div className={classes.loaderContainer}>
                            <h1 id="no-records">{errorData}</h1>
                        </div>
                }
            </div>
        </div>
    )
}

export default withStyles(styles)(BrandSummary);