import axios from 'axios';
import Helpers from '../../../utils/Helpers';

/**
 * @description POST API for recharging campaign
 * @async
 * @function PostRechargeCampaignAPI
 * @returns Promise
**/


const PostRechargeCampaignAPI = async (details) => {

    const { commission } = JSON.parse(sessionStorage.getItem('campaignCharges'))

    try {
        const stripeCharge = Helpers.calculateStripeCharge(!details.campaignInfo.couponDetails?.coupon_id ? details.noOfPost * details.campaignInfo.price_per_post * commission : details.campaignInfo.couponDetails?.campaign_payable_amount)
        let apiUrl = process.env.REACT_APP_API_URL + "/campaign/recharge-campaign";
        const formData = {
            budget_total: parseFloat(details.total).toFixed(2),
            campaign_id: details.campaignInfo.campaign_id,
            customerid: details.selectedCard.customer_id,
            posts_total: details.noOfPost,
            stripe_charge: stripeCharge,
            coupon_id: details.campaignInfo.couponDetails?.coupon_id
        };

        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('access-token')
            } 
        }
        
        const response = await axios.post(apiUrl, formData, config);
        return response.data;
        
    } catch (e) {
      return e;
    }
}

export default PostRechargeCampaignAPI;
  