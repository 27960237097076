import axios from 'axios';

/**
 * @description Get API to fetch an address's details
 * @async
 * @function GetPlaceDetailsAPI
 * @returns Promise
**/


const GetPlaceDetailsAPI = async (placeId) => {

    try {

    let apiUrl = process.env.REACT_APP_API_URL + `/campaign/google-place-detail/${placeId}`;
    const config = {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('access-token')
        } 
    }
    const response = await axios.get(apiUrl, config)
    return response.data.data[0];

    } catch (e) {
        return e;
    }

}

export default GetPlaceDetailsAPI;
  