import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { WHITE_BG, GRAY_BG_F7 } from '../../utils/CssConstants';
import "react-datepicker/dist/react-datepicker.css";
import CampaignTabs from './CampaignTabs/CampaignTabs';
import CreateCampaign from './CreateCampaign/CreateCampaign';
import PaymentMethods from './PaymentMethods/PaymentMethods';
import CampaignList from './CampaignList/CampaignList';

const styles = theme => ({
    rootCampaign: {
        display: 'flex',
        background: WHITE_BG,
        margin: '30px 10px',
        padding: 15,
        borderRadius: 20,
        [theme.breakpoints.down('sm')]: {
            margin: '0 10px'
        }
    },
    componentCont: {
        background: GRAY_BG_F7,
        padding: 30,
        width: '100%',
        borderRadius: 30,
        minHeight: '100vh',
        height: 'auto'
    }
})

const Campaign = ({ classes }) => {
    
    const [activeTab, setActiveTab] = useState(1);
    const [stateChanged, setStateChanged] = useState(false);
    const [rechargeTab, setRechargeTab] = useState(false)
    const userDetails = useSelector((state) => state.authReducer.userDetails);
   

    const useQuery = () => {
        return new URLSearchParams(useLocation().search);
    }
    
    const navigate = useNavigate();
    let query = useQuery();

    const handleActiveTab = (val) => {
        if(stateChanged) {
            if(window.confirm("You have unsaved changes, are you sure?")) {
                setActiveTab(val);
                setStateChanged(false);
            }
        } else {
            setStateChanged(false);
            setActiveTab(val)
        }
    }

    useEffect(() => {
        if(userDetails.is_verified) {
            if(userDetails.status_id === 1901 && userDetails.type_id !== 1802) {
                navigate('/get-started')
            }
        } else {
            navigate('/signup-verification')
        }
        if(userDetails.type_id === 1802 || userDetails.type_id === 1806) {
            setActiveTab(3)
        }
        if(query.get('recharge') && !rechargeTab) {
            setRechargeTab(true)
            setActiveTab(3)
        } else {
            setRechargeTab(false)
        }
    }, [userDetails, navigate, query]);

    return (
        <div className={classes.campaignContainer}>
            {userDetails.type_id === 1801 &&
                <CampaignTabs activeTab={activeTab} handleActiveTab={handleActiveTab} />
            }
            <div className={classes.rootCampaign}>
                <div className={classes.componentCont}> 
                    {activeTab === 2 ?
                        <PaymentMethods />
                    : activeTab === 3 ?
                        <CampaignList filter={rechargeTab ? 405 : 401} setRechargeTab={setRechargeTab} />
                    :
                        <CreateCampaign stateChanged={stateChanged} setStateChanged={setStateChanged} setActiveTab={setActiveTab} />
                    }
                </div>
            </div>
        </div>
    )
}

export default withStyles(styles)(Campaign);