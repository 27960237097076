import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams } from 'react-router-dom';
import { withStyles } from '@material-ui/core/styles';
import { CAMPAIGNLIST_FILTER_LI_STYLES, PRIMARY_COLOR, WHITE_BG } from '../../../utils/CssConstants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';
import Pagination from '@mui/material/Pagination';
import TableData from './TableData/TableData';
import CircularProgress from '@material-ui/core/CircularProgress';
import { getCampaignLists } from '../../../store/actions/Campaign/campaignListActions';
import GetCampaignListAPI from '../../../services/Campaign/CampaignList/GetCampaignListAPI';
import GetRechargeCampaignListAPI from '../../../services/Campaign/CampaignList/GetRechargeCampaignListAPI';
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";


const styles = theme => ({
    loaderContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh'
    },
    campaignListTop: {
        marginBottom: 20
    },
    campaignListHeading: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 24,
        marginBottom: 20
    },
    campaignListFilters: {
        display: 'flex',
        listStyle: 'none',
        padding: 0,
        margin: 0,
    },
    filterStyles: CAMPAIGNLIST_FILTER_LI_STYLES,
    filterResponsiveStyles: {
        [theme.breakpoints.down('sm')]: {
            fontSize: '0.95rem'
        }
    },
    filterActive: {
        color: PRIMARY_COLOR,
        fontWeight: 600
    },
    campaignListContent: {
        width: '100%',
        marginBottom: 20,
        [theme.breakpoints.down('sm')]: {
            overflowX: 'auto'
        }
    },
    campaignListTable: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            width: 1400
        }
    },
    campaignListTheadRow: {
        display: 'flex',
        background: WHITE_BG,
        border: '1px solid #000000',
        boxSizing: 'border-box',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: 10,
        padding: '15px 30px',
        width: '100%',
        minHeight: '12%',
        flexWrap: 'wrap',
        marginBottom: 10,
        justifyContent: 'center',
        alignItems: 'center',
    },
    campaingListHeadings: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: '0.90rem',
        padding: '5px 10px',
        borderRight: '1px solid #CECECE',
        textAlign: 'center',
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    smallSpace: {
        flex: 1
    },
    mediumSpace: {
        flex: 3
    },
    mediumSpaceTwo: {
        flex: 4
    },
    largeSpace: {
        flex: 7
    },
    campaignListBottom: {
        padding: '20px 0',
        display: 'flex',
        justifyContent: 'center',
        '&:before': {
            background: 'black'
        }
    }
})

const CampaignList = ({ classes, filter = 401, setRechargeTab }) => {

    const [searchParams, setSearchParams] = useSearchParams();
    const [currentPage, setCurrentPage] = useState(1);
    const [loader, setLoader] = useState(true);
    const [campaignListAPIError, setCampaignListAPIError] = useState("");
    const [selectedFilter, setSelectedFilter] = useState(filter);
    const [totalCampaigns, setTotalCampaigns] = useState(0);
    const [totalActiveCampaigns, setTotalActiveCampaigns] = useState(0);
    const [totalInactiveCampaigns, setTotalInactiveCampaigns] = useState(0);
    const [totalExpiredCampaigns, setTotalExpiredCampaigns] = useState(0);
    const [totalRechargeCampaigns, setTotalRechargeCampaigns] = useState(0);
    const campaignsPerPage = 10;

    const campaigns = useSelector((state) => state.campaignListReducer.campaignLists);
    const brandView = useSelector((state) => state.authReducer.brandView);
    const userDetails = useSelector((state) => state.authReducer.userDetails);
    const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHER_KEY);
    const dispatch = useDispatch()

    const applyFilter = (filterStatus) => {
        if (selectedFilter !== filterStatus) {
            setSelectedFilter(filterStatus);
            setLoader(true);
            setCurrentPage(1)
        }
    }

    const handlePagination = (event, value) => {
        setCurrentPage(value);
        setLoader(true)
        if (selectedFilter === 405) {
            GetRechargeCampaignListAPI(value, 10, (brandView ? userDetails.brand_id : null)).then(data => {
                setLoader(false);
                if (data !== undefined) {
                    dispatch(getCampaignLists(data.data.campaigns));
                    setTotalRechargeCampaigns(data.data.total_active)
                }
            }).catch(err => (err.response) && setCampaignListAPIError(err.response.data.error));
            return
        }
        GetCampaignListAPI(value, 10, selectedFilter, (brandView ? userDetails.brand_id : null)).then(data => {
            setLoader(false);
            if (data !== undefined) {
                dispatch(getCampaignLists(data.data.campaigns));
                setTotalCampaigns(data.data.total)
                setTotalActiveCampaigns(data.data.total_active)
                setTotalInactiveCampaigns(data.data.total_inactive)
                setTotalExpiredCampaigns(data.data.total_expired)
                setTotalRechargeCampaigns(data.data.total_recharge)
            }
        }).catch(err => (err.response) && setCampaignListAPIError(err.response.data.error));

    }



    const selectedTotalCampaign = (filter) => {
        switch (filter) {
            case 406:
                return totalExpiredCampaigns
            case 402:
                return totalActiveCampaigns
            case 403:
                return totalInactiveCampaigns
            case 405:
                return totalRechargeCampaigns
            default:
                return totalCampaigns
        }
    }

    useEffect(() => {
        if (searchParams.has('recharge') && filter === 405) {
            setRechargeTab(false)
        }

        if (selectedFilter !== 405 && searchParams.has('recharge')) {
            applyFilter(405)
            setRechargeTab(true)
        }

        searchParams.delete("recharge")
        setSearchParams(searchParams)
    }, [searchParams])

    useEffect(() => {
        document.title = "Campaigns - Thumzup"
        if (selectedFilter === 405 || searchParams.has('recharge')) {
            GetCampaignListAPI(currentPage, 10, selectedFilter, (brandView ? userDetails.brand_id : null)).then(data => {
                if (data !== undefined) {
                    setTotalCampaigns(data.data.total)
                    setTotalActiveCampaigns(data.data.total_active)
                    setTotalInactiveCampaigns(data.data.total_inactive)
                    setTotalExpiredCampaigns(data.data.total_expired)
                    setTotalRechargeCampaigns(data.data.total_recharge)
                    GetRechargeCampaignListAPI(currentPage, 10, (brandView ? userDetails.brand_id : null)).then(data => {
                        setLoader(false);
                        if (data !== undefined) {
                            dispatch(getCampaignLists(data.data.campaigns))
                            setTotalRechargeCampaigns(data.data.total_active)
                        }
                    }).catch(err => (err.response) && setCampaignListAPIError(err.response.data.error));
                }
            }).catch(err => (err.response) && setCampaignListAPIError(err.response.data.error));
        } else {
            GetCampaignListAPI(currentPage, 10, selectedFilter, (brandView ? userDetails.brand_id : null)).then(data => {
                if (data !== undefined) {
                    dispatch(getCampaignLists(data.data.campaigns));
                    setTotalCampaigns(data.data.total)
                    setTotalActiveCampaigns(data.data.total_active)
                    setTotalInactiveCampaigns(data.data.total_inactive)
                    setTotalExpiredCampaigns(data.data.total_expired)
                    setTotalRechargeCampaigns(data.data.total_recharge)
                }
                setLoader(false);
            }).catch(err => (err.response) && setCampaignListAPIError(err.response.data.error));
        }

        if (searchParams.has("recharge") && selectedFilter === 405) {
            searchParams.delete("recharge")
            setRechargeTab(false)
            setSearchParams(searchParams)
        }
    }, [selectedFilter, brandView, userDetails.brand_id]); // eslint-disable-line react-hooks/exhaustive-deps

    //Get Current campaigns to render
    const indexOfLastCampaign = currentPage * campaignsPerPage;
    const indexOfFirstCampaign = indexOfLastCampaign - campaignsPerPage;
    const currentCampaigns = campaigns;

    return (
        <Elements stripe={stripePromise}>
            <div className={classes.campaignListTop}>
                <h3 className={classes.campaignListHeading} id="page-heading">Campaign List</h3>
                <div>
                    <ul className={classes.campaignListFilters}>
                        <li id="filter-option-all" className={`${classes.filterStyles} ${classes.filterResponsiveStyles} ${selectedFilter === 401 && classes.filterActive}`} onClick={() => applyFilter(401)}>All ({totalCampaigns})</li>
                        <li id="filter-option-active" className={`${classes.filterStyles} ${classes.filterResponsiveStyles} ${selectedFilter === 402 && classes.filterActive}`} onClick={() => applyFilter(402)}>Active ({totalActiveCampaigns})</li>
                        <li id="filter-option-inactive" className={`${classes.filterStyles} ${classes.filterResponsiveStyles} ${selectedFilter === 403 && classes.filterActive}`} onClick={() => applyFilter(403)}>Inactive ({totalInactiveCampaigns})</li>
                        <li id="filter-option-expired" className={`${classes.filterStyles} ${classes.filterResponsiveStyles} ${selectedFilter === 406 && classes.filterActive}`} onClick={() => applyFilter(406)}>Expired ({totalExpiredCampaigns})</li>
                        <li id="filter-option-recharge" className={`${classes.filterStyles} ${classes.filterResponsiveStyles} ${selectedFilter === 405 && classes.filterActive}`} onClick={() => applyFilter(405)}>Needs Recharge ({totalRechargeCampaigns})</li>
                    </ul>
                </div>
            </div>

            {loader === true ?
                <div className={classes.loaderContainer}>
                    {campaignListAPIError !== "" ?
                        <h1 id="no-record-txt">No Records</h1>
                        : <CircularProgress size={80} />}
                </div>
                :
                (selectedTotalCampaign(selectedFilter) > 0) ?
                    <>
                        <div className={classes.campaignListContent}>
                            <table className={classes.campaignListTable}>
                                <thead>
                                    <tr className={classes.campaignListTheadRow}>
                                        <th className={`${classes.campaingListHeadings} ${classes.smallSpace}`} style={{ borderRight: 'none' }} id="index">ID</th>
                                        {userDetails.type_id !== 1806 && selectedFilter !== 406 &&
                                            <th className={`${classes.campaingListHeadings} ${classes.smallSpace}`} id="campaign-switch"><FontAwesomeIcon icon={faCircle} id="switch-icon" color="#797979" /></th>
                                        }
                                        <th className={`${classes.campaingListHeadings} ${userDetails.type_id === 1802 || userDetails.type_id === 1806 ? classes.mediumSpace : classes.mediumSpaceTwo}`} style={{ borderLeft: selectedFilter === 406 ? '1px solid #CECECE' : '' }} id="campaign-name">{userDetails.type_id === 1802 || userDetails.type_id === 1806 ? 'Brand' : 'Campaign'}</th>
                                        <th className={`${classes.campaingListHeadings} ${classes.mediumSpaceTwo}`} id="campaign-photo">Campaign Photo</th>
                                        <th className={`${classes.campaingListHeadings} ${classes.mediumSpace}`} id="campaign-type">Campaign Type</th>
                                        <th className={`${classes.campaingListHeadings} ${classes.mediumSpace}`} id="budget">Budget</th>
                                        <th className={`${classes.campaingListHeadings} ${classes.mediumSpace}`} id="budget-remaining">Budget Remaining</th>
                                        <th className={`${classes.campaingListHeadings} ${classes.mediumSpace}`} id="status">Status</th>
                                        <th className={`${classes.campaingListHeadings} ${classes.mediumSpace}`} id="session">Session</th>
                                        {userDetails.type_id !== 1806 && <th className={`${classes.campaingListHeadings} ${classes.smallSpace}`} style={{ borderRight: 'none' }} id="action">Action</th>}
                                    </tr>
                                </thead>
                                <tbody>
                                    <TableData campaignsData={currentCampaigns} seriesIndex={currentPage < 2 ? currentPage : indexOfFirstCampaign + 1} selectedFilter={selectedFilter} setTotalCampaigns={setTotalCampaigns} setTotalActiveCampaigns={setTotalActiveCampaigns} setTotalInactiveCampaigns={setTotalInactiveCampaigns} setTotalExpiredCampaigns={setTotalExpiredCampaigns} setTotalRechargeCampaigns={setTotalRechargeCampaigns} currentPage={currentPage} />
                                </tbody>
                            </table>
                        </div>
                        <div className={classes.campaignListBottom}>
                            <Pagination color='primary' onChange={handlePagination} count={Math.ceil(selectedTotalCampaign(selectedFilter) / 10)} page={currentPage} showFirstButton showLastButton />
                        </div>
                    </>
                    :
                    <div className={classes.loaderContainer}>
                        <h1 id="no-record-txt">No Records</h1>
                    </div>
            }
        </Elements>
    );
};

export default withStyles(styles)(CampaignList);