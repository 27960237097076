
const PostVerifyAPI = async (payload) => {
    const url = process.env.REACT_APP_API_URL + "/auth/verify";
    const params = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(payload),
    };
    try {
      const response = await fetch(url, params);
      const responseJson = await response.json();
      return responseJson;
    } catch (error) {
      return error;
    }
}

export default PostVerifyAPI;
