import * as actionTypes from '../../types/brands';

const initialState = {
    brands: [],
};

const brandsReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.GET_BRAND:
            return { ...state, brands: action.payload };
        default:
            return state;
    }
}

export default brandsReducer;