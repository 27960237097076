import React, { useRef, useEffect } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { FORM_INPUT_STYLES, CAMPAIGN_FORM_STEPS_HEADING, FORM_INPUT_CONTAINER_STYLES, CAMPAIGN_FORM_STEPS_LABEL, WHITE_BG, PRIMARY_COLOR } from '../../../../utils/CssConstants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import CancelIcon from '@material-ui/icons/Cancel';
import Helpers from '../../../../utils/Helpers';


const styles = {
    modalContainer: {
        position: 'fixed',
        top: '0',
        left: '22.5%',
        right: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
        width: '60%',
        margin: '10px'

    },
    campaignInfoPopup: {
        background: 'rgba(0, 0, 0, 0.8)',
        border: '1px solid #1549d6',
        boxSizing: 'border-box',
        borderRadius: 20,
    },
    campaignmain: {
        boxSizing: 'border-box',
        width: '100%',
        overflowY: 'auto',
        maxHeight: '100%',
    },
    /* WebKit browsers */
    '&::-webkit-scrollbar': {
        width: '10px',
    },
    '&::-webkit-scrollbar-track': {
        background: '#f1f1f1',
        borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb': {
        background: '#888',
        borderRadius: '10px',
    },
    '&::-webkit-scrollbar-thumb:hover': {
        background: '#555',
    },

    /* Firefox */
    '&': {
        scrollbarWidth: 'thin',
        scrollbarColor: '#888 #f1f1f1',
    },

    top: {
        margin: 15

    },
    header: {
        margin: 15,
        borderBottom: '2px solid #CECECE',
        height: '25%',
        boxSizing: 'border-box',
        justifyContent: 'space-between',
        flexDirection: 'row',
        display: 'flex'
    },
    headerTextLeft: {
        marginBottom: 10
    },
    crossIcon: {
        cursor: 'pointer',
        color: WHITE_BG,
    },
    content: {
        margin: 15,
        marginBottom: 0,
        height: '50%',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        alignItems: 'center'
    },
    contentTile: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'flex-start',
        marginBottom: 10
    },
    formInputContStyles: FORM_INPUT_CONTAINER_STYLES,
    formInputStyles: FORM_INPUT_STYLES,
    formHeadingStyles: CAMPAIGN_FORM_STEPS_HEADING,
    formInputLabels: CAMPAIGN_FORM_STEPS_LABEL,
    cardCheck: {
        width: '25%',
        borderLeft: '1px solid #B7B7B7',
        borderRight: 'none',
        justifyContent: 'center',
        alignItems: 'center',
        paddingRight: 0
    },
    infoLabels: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 24,
        color: WHITE_BG,
        marginRight: 10
    },
    infoData: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 24,
        color: WHITE_BG,
        margin: 0,
    },
    dates: {
        display: 'flex',
        marginRight: 30
    },
    bottom: {
        width: '100%',
        marginTop: 2.5
    },
    budgetPostDetails: {
        width: '50%'
    },
    adres: {
        borderBottom: "1px solid #6c757dd6",
        paddingTop: 10
    },
    showRule: {
        height:'5px',
        marginLeft:'10px',
        color: '#2fc3fd',
        textDecoration: 'underline',
        cursor: 'pointer',
        marginBottom:0,
        "&:hover": {
            color: "#0cb1f1",
            textDecoration: 'underline',
            cursor: 'pointer'
        }
    },

};

const CampaignInfoPopup = ({ classes, campaignInfo, closeModal, campaignDetailError, openCampaignModal, isCampaignRuleModal }) => {

    const node = useRef();
    const { commission } = JSON.parse(sessionStorage.getItem('campaignCharges'))

    useEffect(() => {
        function handleClickOutside(event) {
            if (node.current && !node.current.contains(event.target) && !isCampaignRuleModal) {
                closeModal()
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };

        // eslint-disable-next-line
    }, [node, isCampaignRuleModal]);


    return (
        <div className={classes.modalContainer}>
            <div className={classes.campaignmain} ref={node}>
                <div className={classes.campaignInfoPopup}>
                    {campaignDetailError !== "" ? campaignDetailError :
                        <div className={classes.top}>
                            <div className={classes.header}>
                                <div className={classes.headerTextLeft}>
                                    <label className={classes.infoLabels} id="campaign-name-label">Campaign Name:</label>
                                    <p className={classes.infoData} id="campaign-name">{campaignInfo.name}</p>
                                </div>
                                <CancelIcon color="primary" id="cancel-icon" className={classes.crossIcon} onClick={closeModal} />
                            </div>
                            <div className={classes.content}>
                                <div className={classes.budgetPostDetails}>
                                    <div className={classes.contentTile}>
                                        <label className={classes.infoLabels} id="budget-label">Budget: </label>
                                        <p className={classes.infoData} id="budget"> {"$" + Helpers.numberWithCommas((campaignInfo.budget_total).toFixed(2))}</p>
                                    </div>
                                    <div className={classes.contentTile}>
                                        <label className={classes.infoLabels} id="budget-label">Budget Approved: </label>
                                        <p className={classes.infoData} id="budget"> {campaignInfo.budget_approved !== null ? "$" + Helpers.numberWithCommas((campaignInfo.budget_approved * commission).toFixed(2)) : "$" + 0.00}</p>
                                    </div>
                                    <div className={classes.contentTile}>
                                        <label className={classes.infoLabels} id="budget-label">Budget Paid: </label>
                                        <p className={classes.infoData} id="budget"> {campaignInfo.budget_paid !== null ? "$" + Helpers.numberWithCommas((campaignInfo.budget_paid * commission).toFixed(2)) : "$" + 0.00}</p>
                                    </div>
                                    <div className={classes.contentTile}>
                                        {/* <div className={classes.dates}> */}
                                        <label className={classes.infoLabels} id="start-date-label">Start Date:</label>
                                        <p className={classes.infoData} id="start-date">{Helpers.formatDate(campaignInfo.start_date + 'Z', false)}</p>
                                        {/* </div> */}
                                        {/* <div className={classes.dates} style={{ marginRight: 0 }}>
                                        <label className={classes.infoLabels} id="end-date-label">End Date:</label>
                                        <p className={classes.infoData} id="end-date">{moment.tz(moment(new Date(campaignInfo.end_date + 'Z')).utc(), 'America/Los_Angeles').format("MM/DD/YYYY")}</p>
                                    </div> */}
                                    </div>
                                    <div className={classes.contentTile}>
                                        {/* <div className={classes.dates} style={{ marginRight: 0 }}> */}
                                        <label className={classes.infoLabels} id="end-date-label">End Date:</label>
                                        <p className={classes.infoData} id="end-date">{campaignInfo.end_date === null ? 'Infinity' : Helpers.formatDate(campaignInfo.end_date + 'Z', false)}</p>
                                        {/* </div> */}
                                    </div>
                                    {(campaignInfo.location !== null && campaignInfo.type_id !== 302) &&
                                        <div className={classes.contentTile}>
                                            <label className={classes.infoLabels} id="location-label">Location:</label>
                                            <p className={classes.infoData} id="location">{campaignInfo.location}</p>
                                        </div>
                                    }
                                </div>
                                <div className={classes.budgetPostDetails}>
                                    <div className={classes.contentTile}>
                                        <label className={classes.infoLabels} id="budget-label">Total Posts: </label>
                                        <p className={classes.infoData} id="budget"> {campaignInfo.posts_total !== null ? Helpers.numberWithCommas(campaignInfo.posts_total) : 0}</p>
                                    </div>
                                    <div className={classes.contentTile}>
                                        <label className={classes.infoLabels} id="budget-label">Posts Approved: </label>
                                        <p className={classes.infoData} id="budget"> {campaignInfo.posts_approved !== null ? Helpers.numberWithCommas(campaignInfo.posts_approved) : 0}</p>
                                    </div>
                                    <div className={classes.contentTile}>
                                        <label className={classes.infoLabels} id="budget-label">Posts Remaining: </label>
                                        <p className={classes.infoData} id="budget"> {campaignInfo.posts_remaining !== null ? Helpers.numberWithCommas(campaignInfo.posts_remaining) : 0}</p>
                                    </div>
                                    <div className={classes.contentTile}>
                                        <label className={classes.infoLabels} id="budget-label">Posts Paid: </label>
                                        <p className={classes.infoData} id="budget"> {campaignInfo.posts_paid !== null ? Helpers.numberWithCommas(campaignInfo.posts_paid) : 0}</p>
                                    </div>
                                    <div className={classes.contentTile}>
                                        <label className={classes.infoLabels} id="perpost-label">Per Post:</label>
                                        <p className={classes.infoData} id="perpost">({"$" + campaignInfo.price_per_post} per post)</p>
                                    </div>
                                    {campaignInfo.preferred_network !== null &&
                                        <div className={classes.contentTile}>
                                            <label className={classes.infoLabels} id="network-label">Preferred Networks:</label>
                                            <p className={classes.infoData} id="network">{campaignInfo.preferred_network}</p>
                                        </div>
                                    }


                                </div>


                                {/* <div className={classes.contentTile}>
                                <label  className={classes.infoLabels}>Keywords:</label>
                                <p className={classes.infoData}>{campaignInfo.hash_tags}</p>
                            </div> */}
                            </div>

                            {campaignInfo.address && campaignInfo.address.length > 0 ? (
                                <div>
                                    {campaignInfo.address.map((addr, index) => (
                                        <div className={` ${classes.adres}`} >
                                            <div>
                                                <div className={classes.contentTile}  >
                                                    <label className={classes.infoLabels} style={{ paddingLeft: 10 }}>Address {index + 1}:</label>
                                                    <p className={classes.infoData} >{addr.address1} {addr.city} {addr.state} {addr.zip_code}</p>
                                                </div>
                                            </div>

                                        </div>
                                    ))}

                                </div>
                            ) : null}
                        </div>
                    }


                </div>
                {campaignInfo.card !== null &&
                    <div className={classes.bottom}>
                        <div className={classes.formInputContStyles} style={{ background: 'rgba(0, 0, 0, 0.8)' }}>
                            <label htmlFor="previous_card" className={`${classes.formInputLabels} ${classes.infoLabels}`} style={{ width: '45%', justifyContent: 'center' }} id="card-details-label">Card Details</label>
                            <input className={classes.formInputStyles} style={{ width: '65%', background: 'rgba(0, 0, 0, 0)', color: '#FFFFFF' }} id="previous_card" type="text" value={"**** **** **** " + campaignInfo.card} readOnly={true} />
                            <label className={`${classes.formInputLabels} ${classes.cardCheck}`}><FontAwesomeIcon icon={faCheckCircle} id="check-icon" color={PRIMARY_COLOR} /></label>
                        </div>
                    </div>
                }

                <div className={classes.bottom}>
                    <div className={classes.formInputContStyles} style={{ background: 'rgba(0, 0, 0, 0.8)',height:'70px' }}>
                        <p className={classes.showRule} onClick={openCampaignModal}>Show Campaign Rules</p>
                    </div>
                </div>


            </div>
        </div>
    )
}

export default withStyles(styles)(CampaignInfoPopup);