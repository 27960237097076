import * as actionTypes from '../../types/posthistory';

const initialState = {
    postHistory: [],
};

const postHistoryReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.GET_POST_HISTORY:
            return { ...state, postHistory: action.payload};
        default:
            return state;
    }
}

export default postHistoryReducer;