// AppContext.js
import React, { createContext, useState } from 'react';

export const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [loader, setLoader] = useState(true);
  const [totalUsers, setTotalUsers] = useState(0);
  const [searchValue, setSearchValue] = useState('');
  const [errorData, setErrorData] = useState("Please select a brand");


  const resetState = () => {
    setLoader(true);
    setTotalUsers(0);
    setSearchValue('');
    setErrorData("Please select a brand");
  };

  return (
    <AppContext.Provider value={{
      loader, setLoader,
      totalUsers, setTotalUsers,
      searchValue, setSearchValue,
      errorData, setErrorData,
      resetState
    }}>
      {children}
    </AppContext.Provider>
  );
};
