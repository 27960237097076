const PostCategoryAPI = async (payload) => {

    const url = process.env.REACT_APP_API_URL + "/category/brand-sub-category";
    const params = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + localStorage.getItem('access-token'),
      },
      body: JSON.stringify(payload),
    };
    try {
      const response = await fetch(url, params);
      const responseJson = await response.json();
      return responseJson;
    } catch (error) {
      return error;
    }
}

export default PostCategoryAPI;
