import axios from 'axios';


/**
 * @description API to validate coupon in create campaign
 * @async
 * @function PostValidateCouponAPI
 * @returns Promise
**/

const PostValidateCouponAPI = async (coupon, payoutAmount) => {

    try {

        const apiUrl = `${process.env.REACT_APP_API_URL}/coupon/validate`;
        const payload = {
            campaign_amount: parseFloat(payoutAmount).toFixed(2),
            coupon: coupon.toUpperCase()
        }

        const config = {
            headers: {
                'Authorization': 'Bearer ' + localStorage.getItem('access-token'),
                'Content-Type': 'application/json'
            }
        }

        const response = await axios.post(apiUrl, payload, config);
        return response.data;

    } catch(e) {
        return e;
    }

}


export default PostValidateCouponAPI;