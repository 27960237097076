import React, { useState, useEffect, useRef } from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import VideoJS from './VideoJS';

const defaultStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: '#000',
    border: '1px solid #000',
    boxShadow: 24,
};

const Videopopup = ({ videoURL, videoBoxOpen, handleClose }) => {
    const [videoDimensions, setVideoDimensions] = useState(null);
    const playerRef = useRef(null);

    useEffect(() => {
        const getVideoDimensions = async () => {
            const video = document.createElement('video');
            video.src = videoURL;
            video.addEventListener('loadedmetadata', () => {
                try {
                    const width = video.videoWidth;
                    const height = video.videoHeight;
                    let newWidth = width > height ? 800 : 400;
                    setVideoDimensions({ width: newWidth });
                } catch (error) {
                    console.error('Error fetching video dimensions:', error);
                }
            });
        };

        if (videoBoxOpen) {
            getVideoDimensions();
        }
    }, [videoBoxOpen, videoURL]);

    const videoJsOptions = {
        autoplay: true,
        controls: true,
        responsive: true,
        fluid: true,
        sources: [{
            src: videoURL,
            type: 'video/mp4'
        }]
    };

    const handlePlayerReady = (player) => {
        playerRef.current = player;

        player.on('waiting', () => {
            console.log('player is waiting');
        });

        player.on('dispose', () => {
            console.log('player will dispose');
        });
    };

    return (
        <Modal
            open={videoBoxOpen}
            onClose={handleClose}
        >
            <Box sx={{ ...defaultStyle, width: videoDimensions?.width }}>
                {videoDimensions && (
                    <VideoJS
                        options={videoJsOptions}
                        onReady={handlePlayerReady}
                        width={videoDimensions.width}
                        height={videoDimensions.height}
                    />
                )}
            </Box>
        </Modal>
    );
};

export default Videopopup;
