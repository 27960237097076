import axios from 'axios';

/**
 * @description Get API to add a custom subcategory
 * @async
 * @function PostAddNewSubCategoryAPI
 * @returns Promise
**/


const PostAddNewSubCategoryAPI = async (subCategoryName) => {

    try {

        let apiUrl = process.env.REACT_APP_API_URL + "/category/add-new-sub-category";
        const formData = {
            name: subCategoryName,
        }
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('access-token'),
            } 
        }
        const response = await axios.post(apiUrl, formData, config)
        return response.data

    } catch (e) {
        return e;
    }
}

export default PostAddNewSubCategoryAPI;
  