import axios from 'axios';

/**
 * @description API to validate card details
 * @async
 * @function PostCardValidateAPI
 * @returns Promise
**/


const PostCardValidateAPI = async (body) => {

  const apiUrl = process.env.REACT_APP_API_URL + "/card/validate";
  let cardNumberStripped = body.billingDetails.cardNumber.replace(/ /g,'');
  const formData = {
    "card_exp_month": parseInt(body.billingDetails.expiryMonth),
    "card_exp_year": parseInt("20" + body.billingDetails.expiryYear),
    "card_number": cardNumberStripped,
    "cvc": body.billingDetails.cvv
  }

  const config = {
    headers: {
      Authorization: 'Bearer ' + localStorage.getItem('access-token')
    } 
  }

  const response = await axios.post(apiUrl, formData, config);
  return response.data;
}

export default PostCardValidateAPI;
  