// import axios from 'axios';

/**
 * @description POST API to login into app
 * @async
 * @function PostRegisterBrandAPI
 * @returns Promise
**/


const PostRegisterBrandAPI = async (data, thumzup = false) => {
    // try {
    //     const response = await axios.post(process.env.REACT_APP_API_URL + "/auth/register-brand", data)
    //     return response;
    // } catch (error) {
    //     alert('PostRegisterBrandAPI error', error)
    // }

    // const response = await axios.post(process.env.REACT_APP_API_URL + "/auth/register-brand", data)
    // return response;

    const url = process.env.REACT_APP_API_URL + `/auth/register-brand?thumzup=${thumzup}`;
    const params = {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
      },
      body: JSON.stringify(data),
    };
    try {
      const response = await fetch(url, params);
      const responseJson = await response.json();
      return responseJson;
    } catch (error) {
      return error;
    }

}

export default PostRegisterBrandAPI;
