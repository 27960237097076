import axios from 'axios';

/**
 * @description Get API to fetch a brand campaign's details
 * @async
 * @function GetCampaignDetailAPI
 * @returns Promise
**/


const GetCampaignDetailAPI = async (id) => {

    let apiUrl = process.env.REACT_APP_API_URL + "/campaign/getCampaignDetail?id=" + id;
    const config = {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('access-token')
        } 
    }

    const response = await axios.get(apiUrl, config)
    return response.data
}

export default GetCampaignDetailAPI;
  