import React, { useState, useRef, useCallback } from 'react';
import { withStyles } from '@material-ui/core/styles';
import { WHITE_BG, INVALID_FORM_VALIDATION, PRIMARY_COLOR } from '../../../../utils/CssConstants';
import { ToastContainer, toast } from 'react-toastify';
import PostUpdateImageAPI from '../../../../services/Campaign/CreateCampaign/PostUpdateImageAPI';
import { useDispatch } from 'react-redux';
import { getCampaignLists } from '../../../../store/actions/Campaign/campaignListActions';
import { CircularProgress } from '@material-ui/core';
import Cropper from 'react-easy-crop'
import Slider from '@material-ui/core/Slider'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import getCroppedImg from './ImageCrop/CropImage';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';



const styles = theme => ({
    imageUpload: {
        display: 'none',
        cursor: 'pointer'
    },


    invalidFormInputs: INVALID_FORM_VALIDATION,
    campaignNamePlaceholder: {
        fontFamily: 'sans-serif',
        fontStyle: 'normal',
    },
    campaignNameText: {
        wordBreak: 'break-word',
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 36,
    },
    changelink: {
        color: '#0d6efd',
        textDecoration: 'underline',
        margin: '10px 0 0 0',
        cursor: 'pointer'
    },
    cropContainer: {
        position: 'relative',
        width: '100%',
        height: 200,
        background: '#333',
        [theme.breakpoints.up('sm')]: {
            height: 400,
        },
    },
    cropButton: {
        flexShrink: 0,
        marginLeft: 16,
        color: '#FFF',
        width: '23%',
        border: `1px solid #1549d6`,
        height: 40,
        outline: 'none',
        fontSize: 18,
        background: '#1549d6',
        boxSizing: 'border-box',
        fontStyle: 'normal',
        fontFamily: 'Poppins',
        fontWeight: 'normal',
        borderRadius: 20,
        textTransform: 'capitalize',
        '&:hover': {
            backgroundColor: '#0838bb'
        },
    },
    controls: {
        padding: 16,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'stretch',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            alignItems: 'center',
        },
    },
    sliderContainer: {
        display: 'flex',
        flex: '1',
        alignItems: 'center',
    },
    sliderLabel: {
        [theme.breakpoints.down('xs')]: {
            minWidth: 65,
        },
    },
    slider: {
        padding: '22px 0px',
        marginLeft: 32,
        color: '#1549d6',
        [theme.breakpoints.up('sm')]: {
            flexDirection: 'row',
            alignItems: 'center',
            margin: '0 16px',
        },
    },
    formSubmit: {
        background: PRIMARY_COLOR,
        color: WHITE_BG,
        border: 'none',
        borderRadius: 10,
        width: '50%',
        outline: 'none',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 600,
        textAlign: 'center',
        paddingTop: 4,
        fontSize: 19,
        width: '280px',
        height: 40,
        marginLeft: 20,
        cursor: 'pointer'
    },

});


const style = theme => ({
    position: 'absolute',
    top: 0,
    left: '50%',
    transform: 'translate(-50%, 10%)',
    width: 750,
    boxShadow: 24,
    height: 'auto',
    maxHeight: 600,
    overflowY: 'scroll',
    scrollbarWidth: 'none',
    background: '#1d2226',
    color: '#FFF',
    padding: 2,
    borderRadius: 2,
    outline: 'none',
    [theme.breakpoints.down('sm')]: {
        width: '100%',
    },
});

const PreImageUploader = ({ classes, Preloadedimage, setPreloadedimage, PreloadedimageName, setPreloadedimageName, Preloadedimageerror, setPreloadedimageerror, campaignType, checkformState }) => {

    const dispatch = useDispatch();


    const [cropModal, setCropModal] = useState(false);
    const [editMode, setEditMode] = useState(false);
    const fileInputRef = useRef(null);
    const [fileType, setFileType] = useState("");

    const [btnLoading, setbtnLoading] = useState(false)
    const [crop, setCrop] = useState({ x: 0, y: 0 })
    const [rotation, setRotation] = useState(0)
    const [zoom, setZoom] = useState(1)
    const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)


    const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
        setCroppedAreaPixels(croppedAreaPixels)
    }, [])

    const showCroppedImage = useCallback(async () => {
        try {
            const croppedImage = await getCroppedImg(
                Preloadedimage,
                croppedAreaPixels,
                rotation,
                fileType
            )
            handleImage(croppedImage, true);
        } catch (e) {
            console.error(e)
        }
    }, [croppedAreaPixels, rotation])

    const onClose = useCallback(() => {
        setCropModal(false);
        setEditMode(false)
    }, [])

    const handleCancelImg = () => {
        if (editMode) {
            onClose()
        } else {
            onClose()
            handleImage(null)
        }
    }



    const handleValidateImage = async (e) => {
        let file = e.target.files[0];

        e.target.value = '';
        if (!file) {
            toast.error("Please Upload A Valid Image", { toastId: "err1" });
            setPreloadedimageerror(true);
            file = "";
            return;
        }

        // Check file type (only allow jpg, jpeg, and png)
        if (!file.name.match(/\.(jpg|jpeg|png)$/)) {
            toast.error("Please Upload A Valid Image", { toastId: "err1" });
            setPreloadedimageerror(true);
            e.target.value = '';  // Reset the input
            return;
        }

        const fileSizeInMB = file.size / (1024 * 1024);
        if (fileSizeInMB > 2) {
            toast.error("Required image less than 2MB", { toastId: "err1" });
            e.target.value = '';  // Reset the input
            return;
        }

        // Continue with image handling
        setFileType(file.type);
        handleImage([file]);
        setCropModal(true);
    };

    const handleImage = async (val, afterEdited = false) => {

        setPreloadedimageerror(false)
        checkformState(val)
        if (!val) {
            setPreloadedimage('')
            setPreloadedimageName('')
            return
        }

        if (val[0]?.name) {
            setPreloadedimageName(val[0].name);
        }

        const myReader = new FileReader();

        if (afterEdited) {
            setPreloadedimage(val)
        } else {
            myReader.readAsDataURL(val[0]);
        }

        myReader.onloadend = function (loadEvent) {
            setPreloadedimage(loadEvent.target.result)
        }

        setCropModal(false);
    }

    const openFilePicker = () => {
        if (fileInputRef.current) {
            fileInputRef.current.click(); // Trigger file input click
        }
    };
    return (
        <>
            <div className={classes.formSubmit}>
                <div>
                    <input
                        ref={fileInputRef}
                        className={classes.imageUpload}
                        type="file"
                        onChange={(e) => handleValidateImage(e)}
                        accept="image/png, image/jpeg, image/jpg"
                    />
                    <label htmlFor={`PreLoaderImage`} style={{ cursor: 'pointer' }} name={'Uploader Photo'} onClick={openFilePicker}>Upload Image</label>
                </div>
            </div>

            {cropModal &&
                <div>
                    <Modal
                        open={cropModal}
                        onClose={handleCancelImg}
                    >
                        <Box sx={style}>
                            <Typography id="modal-modal-title" variant="h6" component="h2">Edit Photo</Typography>
                            <div className={classes.cropContainer}>
                                <Cropper
                                    image={Preloadedimage}
                                    crop={crop}
                                    rotation={rotation}
                                    zoom={zoom}
                                    aspect={campaignType == 'NON_GEO' ? (4 / 3) : campaignType == "GEO" ? (3 / 3.40) : (4 / 3)}
                                    onCropChange={setCrop}
                                    onRotationChange={setRotation}
                                    onCropComplete={onCropComplete}
                                    onZoomChange={setZoom}
                                    showGrid={true}
                                />
                            </div>
                            <div className={classes.controls}>
                                <div className={classes.sliderContainer}>
                                    <Typography
                                        variant="overline"
                                        classes={{ root: classes.sliderLabel }}
                                    >
                                        Zoom
                                    </Typography>
                                    <Slider
                                        value={zoom}
                                        min={1}
                                        max={3}
                                        step={0.1}
                                        aria-labelledby="Zoom"
                                        classes={{ root: classes.slider }}
                                        onChange={(e, zoom) => setZoom(zoom)}
                                    />
                                </div>
                                <div className={classes.sliderContainer}>
                                    <Typography
                                        variant="overline"
                                        classes={{ root: classes.sliderLabel }}
                                    >
                                        Rotation
                                    </Typography>
                                    <Slider
                                        value={rotation}
                                        min={0}
                                        max={360}
                                        step={1}
                                        aria-labelledby="Rotation"
                                        classes={{ root: classes.slider }}
                                        onChange={(e, rotation) => setRotation(rotation)}
                                    />
                                </div>

                                <Button
                                    onClick={showCroppedImage}
                                    variant="contained"
                                    classes={{ root: classes.cropButton }}
                                    style={{ width: '26%' }}
                                    disabled={btnLoading}>
                                    {btnLoading ? <CircularProgress size={20} className={classes.btnLoading} /> : "Save Photo"}
                                </Button>
                            </div>
                        </Box>
                    </Modal>
                </div>

            }

        </>
    );
};

export default withStyles(styles)(PreImageUploader);
