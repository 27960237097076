export const PRIMARY_COLOR = "#1549d6";
export const BTN_COLOR_THEME = "#1549d6";
export const WHITE_BG = "#FFFFFF";
export const LOGIN_FONT_COLOR = "#25213B";
export const BTN_COLOR_GREEN = "#62d39d";
export const GRAY_BG = "#E5E5E5";
export const GRAY_BG_F7 = "#F7F7F7";
export const LINKS_COLOR = "#37B1C8";
export const PLACEHOLDER_COLOR ="#7E7E7E"

export const FORM_INPUT_STYLES = {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 20,
    color: '#000000',
    boxSizing: 'border-box',
    padding: 20,
    border: 'none',
    width: '100%',
    outline: 'none',
    VerticalAlign: 'center',
    borderLeft: '1px solid #B7B7B7',
    // marginTop: 10
}

export const FORM_INPUT_CONTAINER_STYLES = {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 20,
    color: '#000000',
    boxSizing: 'border-box',
    borderRadius: 15,
    border: '1px solid #B7B7B7',
    padding: 20,
    width: '100%',
    height: '100px',
    marginBottom: 10,
    marginTop: 10,
    outline: 'none',
    display: 'flex',
    flexDirection: 'row',
    background: "#FFFFFF",
}


export const CAMPAIGN_FORM_STEPS_HEADING = {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 20,
    color: '#000000',
    paddingLeft: 15,
    marginBottom: 15,
}

export const CAMPAIGN_FORM_STEPS_LABEL = {
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 600,
    fontSize: 20,
    color: '#000000',
    paddingRight: 15,
    width: '26%',
    display: 'flex',
    alignItems: 'center',
    height: 58,
}

export const FORM_GROUP = {
    marginBottom: 30,
}

export const INVALID_FORM_VALIDATION = {
    border: '2px solid #FF0000 !important',
}

export const FIELD_INCORRECT_DATA_VALIDATION = {
    marginBottom: 10,
    paddingLeft: 15,
    color: '#FF0000',
    fontWeight: 'bold',
    display: 'block'
}


export const CAMPAIGNLIST_FILTER_LI_STYLES = {
    paddingRight: 15,
    borderRight: '2px solid #CECECE',
    marginRight: 10,
    fontFamily: 'Poppins',
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 20,
    color: '#797979',
    cursor: 'pointer',
    '&:last-child': {
        borderRight: 'none'
    }
};
