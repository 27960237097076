import axios from 'axios';

/**
 * @description API to block user
 * @async
 * @function BlockUserAPI
 * @param {string} userId - ID of the user to block
 * @returns {Promise} - Promise representing the API call
**/

const BlockUserAPI = async (userId) => {
    try {
        const apiUrl = process.env.REACT_APP_API_URL + `/admin/block-user/${userId}`;
        const config = {
            headers: {
                'Accept': 'application/json',
                'Authorization': `Bearer ${localStorage.getItem('access-token')}`
            }
        };
        const response = await axios.put(apiUrl, null, config);
        return response.data;
    } catch (error) {
        return error;
    }
};

export default BlockUserAPI;
