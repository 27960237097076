import axios from 'axios';

/**
 * @description Update Instagram handle for a user
 * @async
 * @function UpdateInstagramHandleAPI
 * @returns Promise
**/
const UpdateInstagramHandleAPI = async (userId, instagramHandle) => {
    try {
        const apiUrl = `${process.env.REACT_APP_API_URL}/admin/update/instagram-handle/${userId}?instagramHandle=${encodeURIComponent(instagramHandle)}`;
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('access-token')
            }
        };

        const response = await axios.put(apiUrl, null, config);
        return response.data;
    } catch (error) {
        console.error('Error updating Instagram handle:', error.response ? error.response.data : error.message);
        throw error;
    }
};

export default UpdateInstagramHandleAPI;