import * as actionTypes from '../../types/dashboard';

export const getPosts = (posts) => async (dispatch) => {
    await dispatch({
        type: actionTypes.GET_POSTS,
        payload: posts
    });
};

export const approvePost = (postId) => async (dispatch) => {
    await dispatch({
        type: actionTypes.APPROVE_POST,
        payload: postId
    });
};

export const flagPost = postId => async dispatch => {
    await dispatch({
        type: actionTypes.FLAG_POST,
        payload: postId
    })
}

export const rejectPost = (postId) => async (dispatch) => {
    await dispatch({
        type: actionTypes.REJECT_POST,
        payload: postId
    });
};