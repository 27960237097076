import axios from 'axios';

/**
 * @description Get API to fetch a country's states
 * @async
 * @function GetStatesAPI
 * @returns Promise
**/


const GetStatesAPI = async () => {

    try {

        let apiUrl = process.env.REACT_APP_API_URL + "/common/states/212";
        const response = await axios.get(apiUrl)
        return response.data

    } catch (e) {
        return e;
    }
}

export default GetStatesAPI;
  