import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { getPosts } from '../../store/actions/Dashboard/dashboardActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import "../../assets/css/style.css";
import TableData from './TableData/TableData';
import Pagination from '@mui/material/Pagination';
import GetPostsAPI from '../../services/Dashboard/GetPostsAPI';
import GetPostsTotalAPI from '../../services/Dashboard/GetPostsTotalAPI';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GetCampaignCharges from "../../services/Campaign/CreateCampaign/GetCampaignCharges";


const styles = theme => ({
    loaderContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh'
    },
    filterBtnContainers: {
        // flexDirection: 'column',
        width: 'fit-content',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-around',
            alignItems: 'center',
            width: '75%'
        }
    }
})

const Dashboard = ({ classes }) => {

    const [currentPage, setCurrentPage] = useState(1);
    const [loader, setLoader] = useState(true);
    const [totalPosts, setTotalPosts] = useState(0);
    const [selectedFilter, setSelectedFilter] = useState(702)
    const postsPerPage = 10


    const dispatch = useDispatch();
    const navigate = useNavigate();


    const userDetails = useSelector((state) => state.authReducer.userDetails);
    const brandView = useSelector((state) => state.authReducer.brandView);
    const posts = useSelector((state) => state.dashboardReducer.posts);

    const applyFilter = (filterStatus) => {
        if(selectedFilter !== filterStatus) {
            setSelectedFilter(filterStatus);
            setLoader(true);
            setCurrentPage(1)
        }
    }

    const handlePagination = (event, value) => {
        setCurrentPage(value);
        setLoader(true)
        GetPostsTotalAPI(selectedFilter, (brandView ? userDetails.brand_id : null)).then(data => {
            setTotalPosts(data.total_posts)
        }).catch(err => err);
        GetPostsAPI(value, 10, selectedFilter, (brandView ? userDetails.brand_id : null)).then(data => {
            if(data !== undefined) {
                dispatch(getPosts(data));
            }
            setLoader(false);
        }).catch(err => console.log(err));
    }
    
    useEffect(() => {
        GetCampaignCharges().then(data => {
            if(data) {
                sessionStorage.setItem('campaignCharges', JSON.stringify({
                    commission: 1 + (data.commission_percentage_for_campaign/100),
                    stripeFees: data.stripe_fixed_fee,
                    stripePercentage: (data.stripe_percentage_fee/100).toPrecision(2)
                }))
            }
        })
    })

    useEffect(() => {
        document.title = "Posts Dashboard - Thumzup";

        if(userDetails.is_verified) {
            if (userDetails.status_id === 1901) {
                navigate('/get-started')
            }
        } else {
            navigate('/signup-verification')
        }

        GetPostsTotalAPI(selectedFilter, (brandView ? userDetails.brand_id : null)).then(data => {
            setTotalPosts(data.total_posts)
        }).catch(err => err);
        GetPostsAPI(currentPage, 10, selectedFilter, (brandView ? userDetails.brand_id: null)).then(data => {
            if(data !== undefined) {
                dispatch(getPosts(data));
            }
            setLoader(false);
        }).catch(err => console.log(err));
    }, [userDetails, selectedFilter, brandView]); // eslint-disable-line react-hooks/exhaustive-deps

    // Get Current posts
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = posts;

    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber)

    return (
        <>
            <div className="dashboard-filters">
                <div className={classes.filterBtnContainers}>
                    <button className={`${"dashboard-filters-btn"} ${selectedFilter === 702 ? "dashboard-filters-btn-active" : ""}`} onClick={() => applyFilter(702)}>Pending</button>
                    <button className={`${"dashboard-filters-btn"} ${selectedFilter === 710 ? "dashboard-filters-btn-active" : ""}`} onClick={() => applyFilter(710)}>Flagged</button>
                </div>
                <div className={classes.filterBtnContainers}>
                    <button className={`${"dashboard-filters-btn"} ${selectedFilter === 703 ? "dashboard-filters-btn-active" : ""}`} onClick={() => applyFilter(703)}>Approved</button>
                    <button className={`${"dashboard-filters-btn"} ${selectedFilter === 705 ? "dashboard-filters-btn-active" : ""}`} onClick={() => applyFilter(705)}>Rejected</button>
                </div>
                <div className={classes.filterBtnContainers}>
                    {/* <button className={`${"dashboard-filters-btn"} ${selectedFilter === 711 ? "dashboard-filters-btn-active" : ""}`} onClick={() => applyFilter(711)}>Money Being Sent</button> */}
                    <button className={`${"dashboard-filters-btn"} ${selectedFilter === 708 ? "dashboard-filters-btn-active" : ""}`} onClick={() => applyFilter(708)}>Paid</button>
                </div>
            </div>
            {loader === true ?
                <div className={classes.loaderContainer}>
                    <CircularProgress size={80} />
                </div>
            :
                (totalPosts > 0) ?
                    <div className="table-dashboard">   
                        <div className="table-full">
                            <div className="table-head">
                                <div className="row table-row">
                                    <div className="borderline col first-col small" id="index">ID</div>
                                    <div className="borderline col medium" id="user-info">User</div>
                                    <div className="borderline col large" id="post-image">Post Content</div>
                                    {/* <div className="borderline col large" id="insta-image">Instagram Content</div> */}
                                    <div className="borderline col large" id="post-caption">Post Caption</div>
                                    <div className="borderline col large" id="brand-campaign">Brand Campaign</div>
                                    <div className="borderline col small" id="amount">Amt.</div>
                                    <div className="borderline col small-2" id="date">Date Posted</div>
                                    {selectedFilter === 705 && <div className="borderline col medium" id="reject-reason">Rejected Reason</div>}
                                    {selectedFilter === 710 && <div className="borderline col medium" id="reject-reason">Flagged Notes</div>}
                                    <div className="borderline col medium last-col" id="status">Status</div>
                                </div>
                            </div>
                            <div className="fulltable">
                                <TableData postsData={currentPosts} currentPage={currentPage} paginate={handlePagination} seriesIndex={currentPage < 2  ? currentPage : indexOfFirstPost + 1} selectedFilter={selectedFilter} toastError={(arg) => toast.error(arg)} toastSuccess={(arg) => toast.success(arg)} brandView={brandView} userDetails={userDetails} />          
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'center', width: '100%', padding: '20px 0' }}>
                            <Pagination color='primary' onChange={handlePagination} count={Math.ceil(totalPosts / 10)} page={currentPage} showFirstButton showLastButton />
                        </div>
                    </div>
                :
                    <div className={classes.loaderContainer}>
                        <h1 id="no-records">No Records</h1>
                    </div>
            }
        </>
        
  );
};

export default withStyles(styles)(Dashboard);