import axios from 'axios';

/**
 * @description API to validate card details
 * @async
 * @function PostCardValidateAPI
 * @returns Promise
**/


const PostCardValidateAPI = async (body) => {

  try {

    const apiUrl = process.env.REACT_APP_API_URL + "/card/add";
    let cardNumberStripped = body.cardNumber.replace(/ /g,'');
    const formData = {
        "card_exp_month": parseInt(body.expiryMonth),
        "card_exp_year": parseInt("20" + body.expiryYear),
        "card_number": cardNumberStripped,
        "cvc": body.cvv
    }

    const config = {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('access-token')
        } 
    }

    const response = await axios.post(apiUrl, formData, config)
    return response.data;

    } catch (e) {
        return e;
    }
}

export default PostCardValidateAPI;
  