import axios from 'axios';
import Helpers from '../../../utils/Helpers';


/**
 * @description API to validate CSV in create campaign
 * @async
 * @function PostCSVValidateAPI
 * @returns Promise
**/


const PostCSVValidateAPI = async (body) => {

  try {

    const apiUrl = process.env.REACT_APP_API_URL + "/campaign/upload/csv/validation";
    let myFile = Helpers.dataURLtoFile(body.csvFile, body.csvFileName);

    const formData = new FormData();
    formData.append('file', myFile);
    const config = {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access-token'),
        'Content-Type': 'multipart/form-data'
      } 
    }

    const response = await axios.post(apiUrl, formData, config);
    return response.data;

  } catch (e) {
    return { status: 'FAILURE' }
  }
}

export default PostCSVValidateAPI;
  