import axios from 'axios';

/**
 * @description Get API to fetch a brand's handle and hashtag
 * @async
 * @function GetHandleAndHashtag
 * @returns Promise
**/


const GetHandleAndHashtagAPI = async () => {

    try {

    let apiUrl = process.env.REACT_APP_API_URL + "/campaign/handle-and-hashtag";
    const config = {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('access-token')
        } 
    }

    const response = await axios.get(apiUrl, config)
    return response.data

    } catch (e) {
        return e;
    }

}

export default GetHandleAndHashtagAPI;
  