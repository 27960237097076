// import { store } from '../../store/index';

const GetCategoryAPI = async () => {
    // const ReduxState= store.getState()
    // const token = ReduxState.authReducer.userDetails.token;
    const url = process.env.REACT_APP_API_URL + "/category";
    const params = {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json; charset=utf-8",
        Authorization: "Bearer " + localStorage.getItem('access-token'),

      },
    };
    try {
      const response = await fetch(url, params);
      const responseJson = await response.json();
      return responseJson;
    } catch (error) {
      return error;
    }
}

export default GetCategoryAPI;
