import PostRefreshTokenAPI from '../services/Auth/PostRefreshTokenAPI';
import moment from 'moment-timezone'


const Helpers = {

    numberWithCommas(x) {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    },

    calculateBudgetWithStripeCharge(budgetWithCommission) {

        if (!budgetWithCommission) return Number(0).toFixed(2)
        const percentageFee = 0.029
        const fixedFee = 0.30

        return Number((Number(budgetWithCommission) + fixedFee) / (1 - percentageFee)).toFixed(2)
    },

    calculateStripeCharge(budgetWithCommission) {
        if (!budgetWithCommission) return Number(0).toFixed(2)
        const percentageFee = 0.029
        const fixedFee = 0.30

        return Number(((Number(budgetWithCommission) + fixedFee) / (1 - percentageFee)) - Number(budgetWithCommission)).toFixed(2)
    },

    formatDate(date, time = true) {
        let newDate = new Date(date);
        newDate = moment.tz(moment(date).utc(), 'America/Los_Angeles').format('YYYY/MM/DD hh:mm A');
        if (time === false) newDate = moment.tz(moment(date).utc(), 'America/Los_Angeles').format('YYYY/MM/DD');
        return newDate.toString();
    },
    blobToFile(theBlob, fileName) {
        //A Blob() is almost a File() - it's just missing the two properties below which we will add
        theBlob.lastModifiedDate = new Date();
        theBlob.name = fileName;
        return theBlob;
    },
    // Create Blob file from URL
    blobCreationFromURL(inputURI) {

        var binaryVal;

        // mime extension extraction
        var inputMIME = inputURI.split(',')[0].split(':')[1].split(';')[0];

        // Extract remaining part of URL and convert it to binary value
        if (inputURI.split(',')[0].indexOf('base64') >= 0)
            binaryVal = atob(inputURI.split(',')[1]);

        // Decoding of base64 encoded string
        else
            binaryVal = unescape(inputURI.split(',')[1]);

        // Computation of new string in which hexadecimal
        // escape sequences are replaced by the character 
        // it represents

        // Store the bytes of the string to a typed array
        var blobArray = [];
        for (var index = 0; index < binaryVal.length; index++) {
            blobArray.push(binaryVal.charCodeAt(index));
        }

        return new Blob([blobArray], {
            type: inputMIME
        });
    },

    dataURLtoFile(dataurl, filename) {

        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);

        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }

        return new File([u8arr], filename, { type: mime });
    },

    async refreshToken() {
        let oldToken = localStorage.getItem('access-token');
        if (oldToken !== undefined && oldToken !== null) {
            await PostRefreshTokenAPI().then(data => {
                localStorage.setItem('access-token', data.data.token)
                setTimeout(Helpers.refreshToken, ((parseInt(data.data.token_expiry_time) - 60) / 60) * 60000)
            });
        }
    },

    convertNumbers(n) {
        if (n < 1e3) return n;
        if (n >= 1e3 && n < 1e6)
            return +(n / 1e3).toFixed(1) + "K";
        if (n >= 1e6 && n < 1e9)
            return +(n / 1e6).toFixed(1) + "M";
        if (n >= 1e9 && n < 1e12)
            return +(n / 1e9).toFixed(1) + "B";
        if (n >= 1e12) return +(n / 1e12).toFixed(1) + "T";
    },

    dataURLtoFile(dataurl, filename) {
        var arr = dataurl.split(','),
            mime = arr[0].match(/:(.*?);/)[1],
            bstr = atob(arr[1]),
            n = bstr.length,
            u8arr = new Uint8Array(n);
        while (n--) {
            u8arr[n] = bstr.charCodeAt(n);
        }
        return new File([u8arr], filename, { type: mime });
    },

    returnDefaultState() {

        const initialBillingState = {
            newCard: "**** **** **** ****",
            nameOnCard: "",
            billingAddress: "",
            cardNumber: "",
            expiryMonth: "",
            expiryYear: "",
            cvv: "",
            paymentMode: "newCard",
            selectedPreviousCard: null,
            couponDetails: null
        };

        const initialCampaignState = {
            campaignName: "Promote the Business",
            customName: false,
            campaignType: "302",
            noOfPosts: "10",
            perPost: "10",
            payoutAmount: "",
            startDate: "",
            endDate: "",
            expirable: false,
            location: "United States",
            network: "Instagram",
            isTwitterEnable: false,
            isTiktokEnable: false,
            keywords: "",
            limitPosting: "Monthly",
            limitPostingNumber: 1,
            image: "",
            imageName: "",
            csvFile: "",
            csvFileName: "",
            storeSelection: "Few",
            brandInstaHandle: "",
            brandTwitterHandle: "",
            brandTIKTOKHandle: "",
            brandInstaHashtags: "",
            brandMoreHashtags: [],
            minFollowers: '50',
            postDuration: '14',
            hiddenCampaign: false,
            purchaseRequired: false,
            appointmentOnly: false,
            postEventDay: false,
            is_recieved_discount: false,
            recieved_discount: '',
            recieved_discount_coupon_code: "",
            isMustBe21OrOlder: false,
            ispreloadedimage: false,
            purchaseValue: "",
            Preloadedimage: "",
            PreloadedimageName: "",
            addressData: [{
                id: 0,
                arraysI: 0,
                address: "",
                state: "",
                city: "",
                zipcode: "",
                // latitude: "",
                // longitude: "",
                addressError: "",
                stateError: "",
                cityError: "",
                zipcodeError: "",
                // latitudeError: "",
                // longitudeError: "",
            }]
        };

        const initialProgressState = {
            detailsComplete: false,
            billingComplete: false,
            reviewComplete: false,
            step: 1
        }


        return {
            initialBillingState,
            initialCampaignState,
            initialProgressState,
            previousCards: []
        }
    }

};

export default Helpers;