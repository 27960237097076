import React, { useState } from 'react'
import { withStyles } from "@material-ui/core/styles"


const styles = {
    seeMore: {
        fontWeight: 500,
        textDecoration: 'underline',
        cursor: 'pointer',
        width: 'fit-content',
        '&:hover': {
            color: 'rgba(0, 0, 0, 0.85)'
        },
        userSelect: 'none'
    }
}


const SeeMore = ({ classes, index, caption }) => {

    const [expanded, setExpanded] = useState(false);

    const conciseText = (str) => {

        if(str.length <= 75) return str
        if(expanded) return str

        return (
            <p style={{ margin: 0 }}>{str.slice(0, 50)}...{" "}</p>
        )

    }

    return (
        <>
            {conciseText(caption, index)}
            {caption.length > 75 && (
                <span className={classes.seeMore} onClick={() => setExpanded(!expanded)}>
                    {expanded ? "See Less" : "See More"}
                </span>
            )}
        </>
    )

}

export default withStyles(styles)(SeeMore)