import * as actionTypes from '../../types/createcampaign';


const initialState = {
    campaignDetails: {
        campaignName: "Promote the Business",
        customName: false,
        campaignType: "302",
        noOfPosts: "100",
        perPost: "10",
        payoutAmount: "",
        startDate: "",
        endDate: "",
        expirable: false,
        location: "United States",
        network: "Instagram",
        isTwitterEnable: false,
        isTiktokEnable: false,
        keywords: "",
        limitPosting: "NoLimit",
        limitPostingNumber: 1,
        image: "",
        imageName: "",
        csvFile: "",
        csvFileName: "",
        storeSelection: "Few",
        brandInstaHandle: "",
        brandTwitterHandle: "",
        brandTIKTOKHandle: "",
        brandInstaHashtags: "",
        brandMoreHashtags: [],
        minFollowers: '50',
        postDuration: '14',
        hiddenCampaign: false,
        purchaseRequired: false,
        appointmentOnly: false,
        postEventDay: false,
        is_recieved_discount: false,
        recieved_discount: '',
        recieved_discount_coupon_code: "",
        isMustBe21OrOlder: false,
        purchaseValue:"",
        ispreloadedimage:false,
        Preloadedimage:"",
        PreloadedimageName: "",
        addressData: [{
            id: 0,
            arraysI: 0,
            address: "",
            state: "",
            city: "",
            zipcode: "",
            // latitude: "",
            // longitude: "",
            addressError: "",
            stateError: "",
            cityError: "",
            zipcodeError: "",
            // latitudeError: "",
            // longitudeError: "",
        }],
    },
    billingDetails: {
        newCard: "**** **** **** ****",
        nameOnCard: "",
        billingAddress: "",
        cardNumber: "",
        expiryMonth: "",
        expiryYear: "",
        cvv: "",
        paymentMode: "newCard",
        selectedPreviousCard: null,
        couponDetails: null
    },
    previousCards: [],
    progressDetails: {
        detailsComplete: false,
        billingComplete: false,
        reviewComplete: false,
        step: 1
    }
};


const createCampaignReducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.INSERT_CAMPAIGN_DETAILS:
            return { ...state, campaignDetails: action.payload };
        case actionTypes.INSERT_BILLING_DETAILS:
            return { ...state, billingDetails: action.payload };
        case actionTypes.INSERT_PROGRESS_DETAILS:
            return { ...state, progressDetails: action.payload };
        case actionTypes.GET_PREVIOUS_CARDS:
            return { ...state, previousCards: action.payload };
        case actionTypes.RESET_CREATE_CAMPAIGN:
            return { ...state, billingDetails: action.billingDetails, campaignDetails: action.campaignDetails, progressDetails: action.progressDetails };
        default:
            return state;
    }
}

export default createCampaignReducer;
