import React, { useEffect, useState } from 'react';
import { withStyles } from "@material-ui/core/styles";
import { Link } from 'react-router-dom';
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../store/actions/Auth/authActions';
import { WHITE_BG, PRIMARY_COLOR, BTN_COLOR_THEME, LOGIN_FONT_COLOR, INVALID_FORM_VALIDATION, FIELD_INCORRECT_DATA_VALIDATION } from '../../utils/CssConstants';
import PostLoginAPI from '../../services/Auth/PostLoginAPI';
import Validate from "../../utils/Validation";
import CircularProgress from '@material-ui/core/CircularProgress';
import Helpers from '../../utils/Helpers';


const styles = theme => ({
    root: {
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    login: {
        background: WHITE_BG,
        display: 'flex',
        flexDirection: 'column',
        borderRadius: 6,
        boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
        width: '40%',
        [theme.breakpoints.down('sm')]: {
            width: '85%'
        }
    },
    loginHead: {
        background: PRIMARY_COLOR,
        borderRadius: 4,
        padding: '12px 12px',
        display: 'flex',
        flexDirection: 'row',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center'
        },
    },
    loginTextCont: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            marginTop: 10,
        },
    },
    loginHeadText: {
        fontFamily: 'Mulish, sans-serif',
        fontStyle: 'normal',
        fontWeight: 800,
        fontSize: 22,
        margin: 0,
        letterSpacing: '0.05em',
        color: WHITE_BG
    },
    loginBottom: {
        padding: '30px 50px',
    },
    loginFormContainer: {
        background: WHITE_BG,
        display: 'flex',
        flexDirection: 'column'
    },
    loginFormLabels: {
        fontFamily: 'Mulish',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 14,
        letterSpacing: '0.05em',
        color: LOGIN_FONT_COLOR,
    },
    loginFormInput: {
        height: 40,
        padding: 10,
        marginBottom: 10,
        width: '100%',
        border: '1px solid #E4E4E4',
        boxSizing: 'border-box',
        borderRadius: 5,
    },
    loginPasswordCont: {
        position: 'relative',
        top: 0,
        left: 0,
    },
    EyeIcon: {
        position: 'absolute',
        right: 10,
        top: 7,
        cursor: 'pointer' 
    },
    loginBottomSection: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '30px 30px 20px 50px',
        borderTop: '1px solid #E4E4E4',
    },
    loginRemeberMeSection : {
        display: 'flex',
    },
    loginSubmitBtn: {
        background: BTN_COLOR_THEME,
        border: 'none',
        borderRadius: 4,
        height: 40,
        fontFamily: 'Inter, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: 12,
        letterSpacing: '0.05em',
        color: WHITE_BG,
        width: '25%',
        marginBottom: '5%',
        marginTop: '4%',
        display: 'block',
        [theme.breakpoints.down('sm')]: {
            height: 40,
        },
    },
    loginRemeberMe: {
        marginRight: 10,
        height: 20,
        width: 20,
    },
    loginRemeberMeText: {
        fontFamily: 'Mulish',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 14,
        letterSpacing: '0.05em',
        color: LOGIN_FONT_COLOR,
    },
    loginForgotPassword: {
        display: 'flex',
        justifyContent: 'flex-end',
        padding: '10px 30px 20px 50px',
    },
    loginForgotPasswordLink: {
        fontFamily: 'Mulish',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 14,
        letterSpacing: '0.05em',
        color: LOGIN_FONT_COLOR,
        cursor: 'pointer'
    },
    thumzupHead: {
        width: 200,
        [theme.breakpoints.down('sm')]: {
            width: 'auto',
        },
    },
    invalidFormInputs: INVALID_FORM_VALIDATION,
    fieldError: FIELD_INCORRECT_DATA_VALIDATION,
    btnLoading: {
        color: "#FFF !important"
    },
});

const Login = ({ classes }) => { 

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isAuth = useSelector((state) => state.authReducer.isAuthenticated);
    const [passVisible, setPassVisible] = useState(false);
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [loginError, setLoginError] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);

    const handleEmail = (val) => {
        if (Validate.emailValidation(val) === false) {
            setEmailError(true);
        } else {
            setEmailError(false);
        }
        setEmail(val);
    }

    const handlePassword = (val) => {
        if (Validate.passwordValidation(val) === false) {
            setPasswordError(true);
        } else {
            setPasswordError(false);
        }
        setPassword(val);
    }
    
    const handleLogin = async () => {
        setBtnLoading(true);
        handleEmail(email);
        handlePassword(password);
        setLoginError(false);
        if(emailError === false && passwordError === false) {
            let request = await PostLoginAPI(email, password);
            if(request) {
                setBtnLoading(false);
                if(request.status ===  200) {
                    if(request.data.data.type_id !== 1801 &&request.data.data.type_id !== 1805) {
                        dispatch(login(request.data.data));
                        localStorage.setItem('access-token', request.data.token);
                        setTimeout(Helpers.refreshToken, ((parseInt(request.data.token_expiry_time) - 60) / 60) * 60000)
                    } else {
                        setLoginError(true);
                    }
                } else {
                    setLoginError(true);
                }
            }
        }
    }

    const handleVisiblity = () => {
        setPassVisible(!passVisible)
    }

    useEffect(() => {
        document.title = "Login - Thumzup"
        if(isAuth) {
            navigate('/dashboard')
        }
      }, [isAuth, navigate]);

    return (
        <div className={classes.root}>
            <div className={classes.login}>
                <div className={classes.loginHead}>
                    <div className={classes.loginTextCont}>
                        <h1 className={`${classes.loginHeadText} ${classes.thumzupHead}`} id="company-name">Thumzup</h1>
                    </div>

                    <div className={classes.loginTextCont}>
                        <h1 className={classes.loginHeadText} id="site-heading">ADMIN PANEL</h1>
                    </div>                  
                </div>
                <div className={classes.loginBottom}>
                    <div className={classes.loginFormContainer}> 
                        <label className={classes.loginFormLabels} htmlFor="email" id="email-label">Email</label>
                        <input className={`${classes.loginFormInput} ${emailError && classes.invalidFormInputs}`} type="text" id="email" value={email} onChange={e => handleEmail(e.target.value)} />
                        {emailError && <span className={classes.fieldError} style={{ paddingLeft: 0 }}>Invalid email address</span>}

                        <label className={classes.loginFormLabels} htmlFor="pass" id="pass-label">Password</label>
                        <div className={classes.loginPasswordCont}>
                            <input className={`${classes.loginFormInput} ${passwordError && classes.invalidFormInputs}`} type={passVisible ? "text": "password"} id="pass" value={password} onChange={e => handlePassword(e.target.value)} />
                            {passVisible ? <Visibility className={classes.EyeIcon} onClick={handleVisiblity} id="open-eye" color="disabled"  /> : <VisibilityOff className={classes.EyeIcon} onClick={handleVisiblity} id="closed-eye" color="disabled" />}
                        </div>
                        {passwordError && <span className={classes.fieldError} id="error-password-length" style={{ paddingLeft: 0 }}>Password must be minimum six characters</span>}
                        {loginError &&  <span className={classes.fieldError} id="error-invalid-email" style={{ paddingLeft: 0 }}>Invalid email or password, please try again.</span>}
                    </div>
                </div>
                <div className={classes.loginBottomSection}>
                    <div className={classes.loginRemeberMeSection}>
                        <input className={classes.loginRemeberMe} type="checkbox" id="remember" />
                        <label className={classes.loginRemeberMeText} htmlFor="remember" id="remember-label">Remember Me</label>
                    </div>
                    <button className={classes.loginSubmitBtn} id="login-btn" onClick={handleLogin} disabled={btnLoading}>{btnLoading ? <CircularProgress size={20} className={classes.btnLoading} /> : "LOG IN"}</button>
                </div>
                <div className={classes.loginForgotPassword}>
                    <Link to="/forgot-password" id="forgot-pass" className={classes.loginForgotPasswordLink}>Forgot Password</Link>
                </div>
            </div> 
        </div>
    )
};

export default withStyles(styles)(Login);