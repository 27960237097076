import axios from 'axios';

/**
 * @description Get API to fetch number of posts in dashboard
 * @async
 * @function GetPostsTotalAPI
 * @returns Promise
**/


const GetPostsTotalAPI = async (statusId, brandId = null) => {

    let apiUrl = process.env.REACT_APP_API_URL + "/posts/total?statusId=" + statusId;
    if(brandId !== null) apiUrl = apiUrl + '&brandId=' + brandId;
    
    const config = {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('access-token')
        } 
    }

    const response = await axios.get(apiUrl, config)
    return response.data.data;

}

export default GetPostsTotalAPI;
  