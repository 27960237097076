import * as actionTypes from '../../types/createcampaign';

export const insertCampaignDetails = (data) => async (dispatch) => {
    await dispatch({
        type: actionTypes.INSERT_CAMPAIGN_DETAILS,
        payload: data
    });
};

export const insertBillingDetails = (data) => async (dispatch) => {
    await dispatch({
        type: actionTypes.INSERT_BILLING_DETAILS,
        payload: data
    });
};

export const getPreviousCards = (data) => async (dispatch) => {
    await dispatch({
        type: actionTypes.GET_PREVIOUS_CARDS,
        payload: data
    });
};

export const insertProgressDetails = (data) => async (dispatch) => {
    await dispatch({
        type: actionTypes.INSERT_PROGRESS_DETAILS,
        payload: data
    });
};

export const resetCreateCampaign = (billingDetails, campaignDetails, progressDetails) => async (dispatch) => {
    await dispatch({
        type: actionTypes.RESET_CREATE_CAMPAIGN,
        billingDetails: billingDetails,
        campaignDetails: campaignDetails,
        progressDetails: progressDetails
    });
};