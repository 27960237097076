import axios from 'axios'

/**
 * @description Get API to fetch address sugguestions around the search query
 * @async
 * @function GetAddressSuggestionsAPI
 * @returns Promise
**/


const GetAddressSuggestionsAPI = async searchQuery => {

    const encoded = encodeURIComponent(searchQuery)

    try {

        const apiURL = `${process.env.REACT_APP_API_URL}/campaign/search-address?searchInput=${encoded}`;
        const config = {
            headers: {
                Authorization: `Bearer ${localStorage.getItem('access-token')}`
            }
        };

        const response = await axios.get(apiURL, config);
        return response.data;

    } catch(e) {
        return e;
    }

}

export default GetAddressSuggestionsAPI;