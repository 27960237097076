import React from 'react';
import { Navigate } from 'react-router-dom';
import Sidebar from '../components/Shared/Sidebar';
import Header from '../components/Shared/Header';

const ProtectedRoutes = ({ isAuth, children}) => {

    if(isAuth) {
        return (
            <div className="side d-flex">
                <Sidebar />
                    
                <div className="full-content w-100">
                    <Header />
                    {children}
                </div>  
            </div>
        )
    } else {
        return <Navigate to={'/brand-login'} />
    }
    
};

export default ProtectedRoutes;