import { datadogRum } from '@datadog/browser-rum'


export const initializeDatadog = (userDetails = false) => {

    datadogRum.init({
        applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
        clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
        site: process.env.REACT_APP_DATADOG_SITE,
        env: process.env.REACT_APP_DATADOG_ENV,
        service:'thumzup-web-admin',
        // Specify a version number to identify the deployed version of your application in Datadog 
        // version: '1.0.0',
        sampleRate: 100,
        sessionReplaySampleRate: 20,
        trackInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel:'mask-user-input'
    });
    
    datadogRum.startSessionReplayRecording();
    
    if(userDetails) {
        datadogRum.setUser({
            id: userDetails.admin_id,
            name: userDetails.first_name + userDetails.last_name,
            email: userDetails.email
        })
    }

}