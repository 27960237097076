import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';


const styles = theme => ({
    progressbarContainer: {
        display: 'flex',
        marginBottom: 20,
        width: '100%'
    },
    progressbar: {
        display: 'flex',
        width: '100%',
        alignItems: 'baseline',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    stepSection: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'baseline',
        padding: 10,
        [theme.breakpoints.down('sm')]: {
            width: '80%'
        }
    },
    activeStep: {
        fontWeight: '600 !important',
    },
    progressbarText: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 20,
        color: '#000000',
        marginLeft: 5,
    },
    progressbarLine: {
        borderTop: '2px solid #797979',
        width: '20%',
        margin: '0px 20px',
        height: 7,
        [theme.breakpoints.down('sm')]: {
            width: '80%',
            textAlign: 'center'
        }
    }
})

const ProgressBar = ({ classes, detailsComplete, billingComplete, reviewComplete, step }) => {
    return (
        <div className={classes.progressbarContainer}>
            <div className={classes.progressbar}>
                <div className={classes.stepSection}>
                    <FontAwesomeIcon icon={faCircle} color={detailsComplete ? "#1549d6": "#797979"} id="circle-icon" />
                    <p className={`${classes.progressbarText} ${step === 1 && classes.activeStep} `} id="details-txt">Details</p>
                </div>
                <span className={classes.progressbarLine}></span>
                <div className={classes.stepSection}>
                    <FontAwesomeIcon icon={faCircle} color={billingComplete ? "#1549d6": "#797979"} id="circle-icon" />
                    <p className={`${classes.progressbarText} ${step === 2 && classes.activeStep} `} id="payment-txt">Payment</p>
                </div>
                <span className={classes.progressbarLine}></span>
                <div className={classes.stepSection}>
                    <FontAwesomeIcon icon={faCircle} color={reviewComplete ? "#1549d6": "#797979"} id="circle-icon" />
                    <p className={`${classes.progressbarText} ${step === 3 && classes.activeStep} `} id="preview-txt">Preview</p>
                </div>
            </div>
        </div>
    )
}

export default withStyles(styles)(ProgressBar);