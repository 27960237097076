import React from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { withStyles } from '@material-ui/core';
import { WHITE_BG, PRIMARY_COLOR } from "../../../utils/CssConstants"
import CircularProgress from '@material-ui/core/CircularProgress'


const style = theme => ({
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 500,
    boxShadow: 10,
    height: 'auto',
    maxHeight: 600,
    overflowY: 'scroll',
    background: WHITE_BG,
    padding: 2,
    borderRadius: 2,
    outline: 'none',
    [theme.breakpoints.down('sm')]: {
        width: '100%',
    }
});

const styles = theme => ({
    formGroup: {
        display: 'flex',
        flexDirection: 'column',
        margin: '20px 0',
    },
    formLabels: {
        fontWeight: 'bold',
        fontSize: 18,
        marginBottom: 5
    },
    formInputs: {
        width: '100%',
        padding: 10,
        fontWeight: 'normal',
        fontSize: 16,
        borderRadius: 5,
        border: '0.5px solid #CECECE',
        outline: 'none'
    },
    labelError: {
        color: 'rgba(255, 0, 0, 0.9)',
        fontSize: 18,
    },
    submitBtn: {
        background: PRIMARY_COLOR,
        color: WHITE_BG,
        border: 'none',
        borderRadius: 5,
        height: 40,
        width: 100,
        marginBottom: 20,
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 18,
        [theme.breakpoints.down('sm')]: {
            width: '50%'
        }
    }
})

const Notes = ({ openNotes, setOpenNotes, updateStatus, classes, postDetails }) => {

    const formik = useFormik({
        initialValues: {
            note: postDetails.notes
        },
        validationSchema: yup.object({
            note: yup.string().nullable().default(postDetails.notes),
        }),
        onSubmit: values => handleSubmit(values)
    })

    const handleSubmit = async inputs => {
        await updateStatus(postDetails.postId, postDetails.index, inputs.note, postDetails.statusId);
    }

  return (
    <Modal
        open={openNotes}
        onClose={() => setOpenNotes(false)}
    >
        <Box sx={style}>
            <form onSubmit={formik.handleSubmit}>
                <div className={classes.formGroup}>
                    <label className={`${classes.formLabels} ${formik.touched.note && formik.errors.note && classes.labelError}`} htmlFor="note" >{formik.touched.note && formik.errors.note ? formik.errors.note : 'Your Note'}</label>
                    <textarea className={classes.formInputs} type='text' name="note" placeholder="Enter your notes" value={formik.values.note} onChange={formik.handleChange} rows={4} cols={50} /> 
                </div>
                <div style={{ textAlign: 'center' }}>
                    <button className={classes.submitBtn} type="submit">Submit</button>
                </div>
            </form>
        </Box>
    </Modal>
  );
}

export default withStyles(styles)(Notes);