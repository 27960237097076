import axios from 'axios';
import Helpers from '../../../utils/Helpers';


/**
 * @description API to upload brand campaign csv for store locations
 * @async
 * @function PostUploadCsvAPI
 * @returns Promise
**/


const PostUploadCsvAPI = async (body) => {

  try {

    const apiUrl = process.env.REACT_APP_API_URL + "/campaign/upload/csv?campaignId=" + body.campaignId;
    let myFile = Helpers.dataURLtoFile(body.campaignDetails.csvFile, body.campaignDetails.csvFileName);

    const formData = new FormData();
    formData.append('file', myFile);

    const config = {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access-token'),
        'Content-Type': 'multipart/form-data'
      } 
    }

    const response = await axios.post(apiUrl, formData, config);
    return response.data;

  } catch (e) {
    return e;
  }
}

export default PostUploadCsvAPI;
  