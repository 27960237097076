import React, { useEffect, useState } from 'react';
import { withStyles } from "@material-ui/core/styles";
import { Container, CircularProgress } from '@material-ui/core';
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import logo from '../../assets/images/thumbLogoBlue.png';
import circle from '../../assets/images/Ellipse 1Blue.png';
import labelBlue from '../../assets/images/verification-label-blue.png';
import { WHITE_BG, LINKS_COLOR, BTN_COLOR_THEME, FIELD_INCORRECT_DATA_VALIDATION } from '../../utils/CssConstants';
import PostVerifyAPI from '../../services/Auth/PostVerifyAPI';
import GetAdminStatusAPI from '../../services/Auth/GetAdminStatusAPI';
import { register } from '../../store/actions/Auth/authActions';



const styles = theme => ({
    root: {
        maxWidth: '100%',
        minHeight: '100vh',
    },
    height_100: {
        height: '100%'
    },
    main: {
        padding: '5%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '80vw',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            paddingLeft: 0
        },
    },
    verification_label: {
        padding: '11px 12px',
        border: '1px solid black',
        borderRadius: 10,  
    },
    verification_img: {
        [theme.breakpoints.down('sm')]: {
            width: 320,
        }
    },
    pageHeadingContainer: {
        display: 'flex',
    },
    mainHeadingText: {
        marginLeft: '4px',
        fontSize: '3em',
        fontWeight: 600,
        fontFamily: "Poppins",
        [theme.breakpoints.down('sm')]: {
            fontSize: '2.5em'
        },
    },
    subText: {
        marginLeft: '14px',
        fontSize: 22,
        fontWeight: 500,
        fontFamily: "Poppins",
    },
    infoText: {
        fontWeight: 600,
        fontSize: '0.8125em',
        fontFamily: 'Poppins',
        color: 'black',
        textAlign: 'center'
    },
    link: {
        color: LINKS_COLOR,
        cursor: 'pointer'
    },
    btnContainer: {
        flex: 1,
        width: '100%',
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'flex-end',
        marginBottom: '40px',
        [theme.breakpoints.down('sm')]: {
            flex: 1,
            alignItems: 'flex-start',
            justifyContent: 'flex-start',
            marginBottom: '40px',
            margin: '30% 40px',
        },

    },
    btn: {
        background: BTN_COLOR_THEME,
        border: 'none',
        borderRadius: 4,
        height: 40,
        fontFamily: 'Inter, sans-serif',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: 12,
        letterSpacing: '0.05em',
        color: WHITE_BG,
        width: '237px',
    },
    chatBtn: {
        position: 'absolute', bottom: 10, left: 20,
        width: '203px',
        height: '37px',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'white',
        border: 'none',
        padding: '10px 15px',
        fontSize: '11px',
        fontWeight: '500',
        boxShadow: '2px 2px 4px 1px rgba(0, 0, 0, 0.1)'
    },
    btnLoading: {
        marginRight: 10,
        color: "#FFF !important"
    },
    fieldError: FIELD_INCORRECT_DATA_VALIDATION
});
function useQuery() {
    return new URLSearchParams(useLocation().search);
}
const SignupSuccess = ({ classes, children }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isAuth = useSelector((state) => state.authReducer.isAuthenticated);
    const userDetailsReducer = useSelector((state) => state.authReducer.userDetails);
    let query = useQuery();

    const [showPage, setShowPage] = useState(false);
    const [showErr, setShowErr] = useState(false);
    const [showErrMsg, setShowErrMsg] = useState("");
    const [userDetails, setUserDetails] = useState("");
    const [showVerifiedLabel, setShowVerifiedLabel] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const [apiError, setAPIError] = useState("");



    useEffect(() => {
        //call verification api
        document.title = "Welcome - Thumzup";
        let payload = {
            "verification_code": query.get("code")
        }
        PostVerifyAPI(payload)
            .then((res) => {
                if (res) {
                    //api respond true
                    if (res.status === 'SUCCESS') {
                        //store userdata and auth to true in redux
                        dispatch(register({ ...res.data, token: res.token }))
                        localStorage.setItem('access-token', res.token);
                        setUserDetails(res.data.first_name + " " + res.data.last_name);
                        //check status
                        if (res.data.status_id === 1901) {
                            //user is draft
                            setShowVerifiedLabel(true)
                            setShowPage(true)
                        }
                        if (res.data.status_id === 1902) {
                            //user is active
                            navigate('/campaigns')
                        }
                    }
                    //api respond false
                    if (res.status === 'FAILURE') {
                        //but user is already verified before
                        setShowErrMsg("The Link has expired")
                        if (isAuth && (userDetails && userDetails.is_verified)) {
                            if (userDetails.status_id === 1901) {
                                //user is draft
                                setShowVerifiedLabel(true)
                                setShowPage(true)
                            }
                            if (userDetails.status_id === 1902) {
                                //user is active
                                navigate('/campaigns')
                            }
                        }
                        //but user is not verified before
                        else {
                            setShowVerifiedLabel(false)
                            setShowErr(true)
                        }
                    }
                }
            })
            .catch(error => {
                console.log('error', error)
            })

            if (isAuth && (userDetailsReducer && userDetailsReducer.is_verified)) {
                if (userDetailsReducer.status_id === 1901) {
                    //user is draft
                    setShowVerifiedLabel(false)
                    setShowPage(true)
                }
            }
    }, [])

    const handleProceed=()=>{
        setBtnLoading(true);
        GetAdminStatusAPI()
        .then(res=>{
            if(res){
                setBtnLoading(false);
                if (res.status === 'SUCCESS') {
                    if (res.data.statusid === 1901) {
                        //user is draft
                        navigate('/get-started')
                    }
                    if (res.data.statusid === 1902) {
                        //user is active
                        navigate('/campaigns')
                    }
                }
                if (res.status === 'FAILURE') {
                    setAPIError(res.error)
                }
            }
        })
        .catch(error=>{
            console.log('error GetAdminStatusAPI', error)
        })
    }

    if (showPage || showErr) {
        return (
            <div className={classes.root} >
                {/* <button className={classes.chatBtn}>
                    <img src={chat} style={{ marginRight: '6px' }} alt="chat" />
                    Need Help? Let’s Chat!
                </button> */}
                <div style={{ position: 'relative', top: 0, left: 0, height: '100vh' }} >
                    <Container fixed className={classes.height_100} style={{}} >
                        <div className={`${classes.main} ${classes.height_100}`}>
                            {
                                showPage ?
                                    <>
                                        {
                                            // showVerifiedLabel &&
                                            <div className={classes.verification_label}>
                                                <img className={classes.verification_img} src={labelBlue} alt="label" id="verified-img" />
                                            </div>
                                        }
                                        <div className={classes.pageHeadingContainer} style={{ marginBottom: 22, marginTop: '10%', alignItems: 'center' }} >
                                            <img src={logo} width={118} height={118} id="thumzup-logo" alt="welcome" />
                                            <h2 className={classes.mainHeadingText} id="main-heading">Welcome, {userDetails}</h2>
                                        </div>
                                        <div className={classes.pageHeadingContainer} style={{ marginBottom: 30 }} >
                                            <img src={circle} width={30} height={30} alt="circle" id="circle" />
                                            <h2 className={classes.subText} id="sub-txt">Let’s get to know each other.<br />
                                                <p style={{ fontSize: 18 }} id="few-txt">We will ask a few questions to help us learn about you and your marketing needs.</p></h2>
                                        </div>

                                        {apiError !== "" &&
                                            <span className={classes.fieldError}id="error-message">{apiError}</span>
                                        }

                                        <div className={classes.btnContainer} >
                                            <button onClick={() => handleProceed()} id="next-btn" className={classes.btn} disabled={btnLoading}>{btnLoading ? <CircularProgress size={20} className={classes.btnLoading} /> : "Let's Go"}</button>
                                        </div>
                                    </>
                                    :
                                    showErr ?
                                        <>
                                            <div className={classes.pageHeadingContainer} style={{ marginBottom: 22, marginTop: '10%', alignItems: 'center' }} >
                                                <img src={logo} width={118} height={118} alt="welcome" id="thumzup-logo" />
                                                <h2 className={classes.mainHeadingText} id="main-heading">{showErr ? "Verification Expired" : "Verification Error"}</h2>
                                            </div>
                                            <div className={classes.pageHeadingContainer} style={{ marginBottom: 30 }} >
                                                <img src={circle} width={30} height={30} alt="circle" id="circle" />
                                                <h2 className={classes.subText} id="sub-txt">{showErrMsg}</h2>
                                            </div>
                                        </>
                                        :
                                        null
                            }

                        </div>
                    </Container>
                </div>
            </div>
        )

    } else {
        return (
            <div style={{ display: 'flex', height: '100vh', justifyContent: 'center', alignItems: 'center' }}>
                <CircularProgress />
            </div>
        )
    }
};

export default withStyles(styles)(SignupSuccess);