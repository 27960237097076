import axios from 'axios';

/**
 * @description Get API to confirm payment through 3D
 * @async
 * @function GetConfirmPaymentAPI
 * @returns Promise
**/


const GetConfirmPaymentAPI = async (paymentIntentId) => {

    try {

        let apiUrl = process.env.REACT_APP_API_URL + "/campaign/confirmCampaignCardPayment?PaymentIntentId=" + paymentIntentId;
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('access-token')
            } 
        }

        const response = await axios.get(apiUrl, config)
        return response.data;

    } catch (e) {
        return e;
    }
}

export default GetConfirmPaymentAPI;
  