import axios from 'axios';

/**
 * @description Get API to fetch a brand campaigns
 * @async
 * @function GetCampaignListAPI
 * @returns Promise
**/


const GetCampaignListAPI = async (page, size, campaignStatus, brandId = null) => {

    if(campaignStatus === 401){
        campaignStatus = ""
    };

    let apiUrl = process.env.REACT_APP_API_URL + "/campaign/getCampaignList?pageNumber=" + page + "&pageSize=" + size + "&statusId=" + campaignStatus;
    if(brandId !== null) apiUrl = apiUrl + '&brandId=' + brandId;
    const config = {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('access-token')
        } 
    }

    const response = await axios.get(apiUrl, config);
    return response.data;
}

export default GetCampaignListAPI;
  