import * as actionTypes from '../../types/auth';

const initialState = {
    isAuthenticated: false,
    userDetails: {},
    rememberMe: false,
    brandView: false,
};

const authReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.LOGIN:
            return { ...state, isAuthenticated: true, userDetails: action.payload, rememberMe: action.rememberMe };
        case actionTypes.UPDATE_STATUS:
                return { ...state, userDetails: action.payload };
        case actionTypes.LOGOUT:
                return { ...state, isAuthenticated: false, userDetails: action.payload, brandView: false };
        case actionTypes.REGISTER:
            return { ...state, isAuthenticated: true, userDetails: action.payload };
        case actionTypes.BRAND_VIEW:
            return { ...state, brandView: action.brandView , userDetails: action.payload };
        default:
            return state;
    }
};

export default authReducer;