import axios from 'axios'

/**
 * @description Get API to fetch a brands details
 * @async
 * @function GetBrandDetailsAPI
 * @returns Promise
**/


const GetBrandDetailsAPI = async brandId => {

    try {

        let apiUrl = `${process.env.REACT_APP_API_URL}/getBrandProfile?brandId=${brandId}`;
        const config = {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('access-token')
            } 
        }
        const response = await axios.get(apiUrl, config)
        return response.data

    } catch (e) {
        return e
    }
}

export default GetBrandDetailsAPI
  