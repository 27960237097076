import axios from 'axios';
import Helpers from '../../../utils/Helpers';

/**
 * @description API to upload brand campaign Preloaded image
 * @async
 * @function PostUploadPreloadedImageAPI
 * @returns Promise
**/


const PostUploadPreloadedImageAPI = async (body) => {
    
  try {

    const apiUrl = process.env.REACT_APP_API_URL + "/campaign/upload/preloaded/image";
    let myFile = Helpers.dataURLtoFile(body.campaignDetails.Preloadedimage, body.campaignDetails.PreloadedimageName);

    const formData = new FormData();
    formData.append('file', myFile);

    const config = {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access-token'),
        'Content-Type': 'multipart/form-data'
      } 
    }

    const response = await axios.post(apiUrl, formData, config);
    return response.data;

  } catch (e) {
    return e;
  }
}

export default PostUploadPreloadedImageAPI;
  