import axios from 'axios';

/**
 * @description POST API for forgot password
 * @async
 * @function PostForgotPasswordAPI
 * @returns Promise
**/


const PostForgotPasswordAPI = async (userEmail) => {
  
    try {

        let apiUrl = process.env.REACT_APP_API_URL + "/forget-password?email=" + encodeURIComponent(userEmail);
        const response = await axios.post(apiUrl);
        return response.data;
        
    } catch (e) {
      return e;
    }
}

export default PostForgotPasswordAPI;
  