import React, { useState, useCallback } from 'react'
import Cropper from 'react-easy-crop'
import Slider from '@material-ui/core/Slider'
import Button from '@material-ui/core/Button'
import Typography from '@material-ui/core/Typography'
import { withStyles } from '@material-ui/core/styles'
import getCroppedImg from './CropImage'
import { styles } from './styles'
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';


const style = theme => ({
  position: 'absolute',
  top: 0,
  left: '50%',
  transform: 'translate(-50%, 10%)',
  width: 750,
  boxShadow: 24,
  height: 'auto',
  maxHeight: 600,
  overflowY: 'scroll',
  scrollbarWidth: 'none',
  background: '#1d2226',
  color: '#FFF',
  padding: 2,
  borderRadius: 2,
  outline: 'none',
  [theme.breakpoints.down('sm')]: {
    width: '100%',
  }
});


const ImageCropModal = ({
  classes,
  open,
  closeCropModal,
  image,
  handleImage,
  campaignType,
  fileForm,
  editMode,
  setEditMode,
  fileType
}
) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  const [rotation, setRotation] = useState(0)
  const [zoom, setZoom] = useState(1)
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null)

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels)
  }, [])

  const showCroppedImage = useCallback(async () => {
    try {
      const croppedImage = await getCroppedImg(
        image,
        croppedAreaPixels,
        rotation,
        fileType
      )
      handleImage(croppedImage, true);
      onClose();
    } catch (e) {
      console.error(e)
    }
  }, [croppedAreaPixels, rotation])

  const onClose = useCallback(() => {
    closeCropModal();
    setEditMode(false)
  }, [])

  const handleCancelImg = () => {
    if (editMode) {
      onClose()
    } else {
      onClose()
      handleImage(null)
      fileForm.current.reset()
    }
  }


  return (
    <div>
      <Modal
        open={open}
        onClose={handleCancelImg}
      >
        <Box sx={style}>
          <Typography id="modal-modal-title" variant="h6" component="h2">Edit Photo</Typography>
          <div className={classes.cropContainer}>
            <Cropper
              image={image}
              crop={crop}
              rotation={rotation}
              zoom={zoom}
              aspect={campaignType == '301' ? (4 / 3) : campaignType == '302' ? (3 / 3.40) : (4 / 3)}
              onCropChange={setCrop}
              onRotationChange={setRotation}
              onCropComplete={onCropComplete}
              onZoomChange={setZoom}
              showGrid={true}
            />
          </div>
          <div className={classes.controls}>
            <div className={classes.sliderContainer}>
              <Typography
                variant="overline"
                classes={{ root: classes.sliderLabel }}
              >
                Zoom
              </Typography>
              <Slider
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                aria-labelledby="Zoom"
                classes={{ root: classes.slider }}
                onChange={(e, zoom) => setZoom(zoom)}
              />
            </div>
            <div className={classes.sliderContainer}>
              <Typography
                variant="overline"
                classes={{ root: classes.sliderLabel }}
              >
                Rotation
              </Typography>
              <Slider
                value={rotation}
                min={0}
                max={360}
                step={1}
                aria-labelledby="Rotation"
                classes={{ root: classes.slider }}
                onChange={(e, rotation) => setRotation(rotation)}
              />
            </div>
            <Button
              onClick={showCroppedImage}
              variant="contained"
              classes={{ root: classes.cropButton }}
            >
              Save Photo
            </Button>
          </div>
        </Box>
      </Modal>
    </div>
  )
}

export default withStyles(styles)(ImageCropModal)