import React, { useEffect, useState } from 'react';
import { withStyles } from "@material-ui/core/styles";
import { Container, CircularProgress } from '@material-ui/core';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import logo from '../../assets/images/thumbLogoBlue.png';
import SelectField from '../Custom/SelectField';
import { WHITE_BG, BTN_COLOR_THEME, FIELD_INCORRECT_DATA_VALIDATION } from '../../utils/CssConstants';
import GetCategoryAPI from '../../services/Auth/GetCategoryAPI';
import PostCategoryAPI from '../../services/Auth/PostCategoryAPI';
import PostAddNewSubCategoryAPI from '../../services/Auth/PostAddNewSubCategoryAPI';
import { logout } from '../../store/actions/Auth/authActions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const styles = theme => ({
    root: {
        maxWidth: '100%',
        minHeight: '100vh',
    },
    height_100: {
        height: '100%'
    },
    main: {
        padding: '5%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '80vw',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    pageHeadingContainer: {
        display: 'flex',
    },
    mainHeadingText: {
        marginLeft: '4px',
        fontSize: '3em',
        fontWeight: 600,
        fontFamily: "Poppins",
    },
    fieldsContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '80%',
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    field: {
        width: '100%',
        marginTop: '44px',
    },
    selectLabel: {
        fontFamily: 'Poppins',
        fontWeight: 600,
        fontSize: '13px',
        marginBottom: '10px',
        display: 'inline-block'
    },
    btnContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '20vh',
        marginBottom: '10vh'

    },
    btn: {
        backgroundColor: BTN_COLOR_THEME,
        border: 'none',
        borderRadius: 4,
        height: 40,
        fontFamily: 'Inter, sans-serif',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: 12,
        letterSpacing: '0.05em',
        color: WHITE_BG,
        width: '237px',
        minWidth: 'fit-content',
        [theme.breakpoints.down('sm')]: {
            width: '150px'
        }
        
    },
    chatBtn: {
        position: 'absolute', bottom: 10, left: 20,
        width: '203px',
        height: '37px',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'white',
        border: 'none',
        padding: '10px 15px',
        fontSize: '11px',
        fontWeight: '500',
        boxShadow: '2px 2px 4px 1px rgba(0, 0, 0, 0.1)'
    },
    btnLoading: {
        marginRight: 10,
        color: "#FFF !important"
    },
    fieldError: FIELD_INCORRECT_DATA_VALIDATION,
});

const GetStarted = ({ classes }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userDetails = useSelector((state) => state.authReducer.userDetails);
    const [options1, setOptions1] = useState([]);
    const [options2, setOptions2] = useState([]);
    const [selectedOptions1, setSelectedOptions1] = useState([]);
    const [selectedOptions2, setSelectedOptions2] = useState([]);
    const [btnLoading, setBtnLoading] = useState(false);
    const [apiError, setApiError] = useState("");


    useEffect(() => {
        document.title = "Preferences - Thumzup";
        if (userDetails.status_id === 1902) {
            navigate('/dashboard')
        }
        GetCategoryAPI()
            .then(res => {
                if (res) {
                    if (res.status === 'SUCCESS') {
                        setOptions1(res.data.category.map(cat => ({ id: cat.id, value: cat.name })))
                        setOptions2(res.data.sub_category.map(cat => ({ id: cat.id, value: cat.name })))
                    }
                    if (res.status === 'FAILURE') {
                        setApiError(res.error)
                    }
                }
            })
            .catch(err => {
                console.log('err from GetCategoryAPI', err)
            })
    }, [selectedOptions2, userDetails, navigate])

    const handleNewSubcategory = (subCategoryName) => {
        PostAddNewSubCategoryAPI(subCategoryName).then(response => {
            if(response.status === "SUCCESS") {
                setSelectedOptions2(selectedOptions2 => [...selectedOptions2, { id: response.data.id, value: response.data.name }])
            } else {
                toast.error(response.response.data.error);
            }
        })        
    }

    const isAllowed = () => {
        if (selectedOptions1.length > 0 && selectedOptions2.length > 0) {
            return true
        }
        return false
    }

    const goNext = () => {
        setBtnLoading(true);
        let payload = {
            "category_id": selectedOptions1[0].id,
            "sub_category_id": selectedOptions2.map(cat=>cat.id)
        };
        PostCategoryAPI(payload)
        .then(res=>{
            if (res) {
                setBtnLoading(false);
                if (res.status === 'SUCCESS') {
                    navigate('/instagram-setup')
                }
                if (res.status === 'FAILURE') {
                    setApiError(res.error)
                }
            }
        })
        .catch(err=>{
            console.log('error PostCategoryAPI',err)
        })
    }

    const handleLogout = () => {
        dispatch(logout({}));
        navigate('/')
    }

    return (
        <div className={classes.root} >
            {/* <button className={classes.chatBtn}>
                <img src={chat} style={{ marginRight: '6px' }} alt="chat" />
                Need Help? Let’s Chat!
            </button> */}
            <div style={{ position: 'relative', top: 0, left: 0, height: '100vh' }} >
                <Container fixed className={classes.height_100} style={{}} >
                    <div className={`${classes.main} ${classes.height_100}`}>
                        <div className={classes.pageHeadingContainer} style={{ marginBottom: 22, alignItems: 'center' }} >
                            <img src={logo} width={118} height={118} alt="welcome" id="thumzup-logo" />
                            <h2 className={classes.mainHeadingText} id="main-heading">Ready? You’ve got this.</h2>
                        </div>
                        <div className={classes.fieldsContainer}>
                            <div className={classes.field} >
                                <span className={classes.selectLabel} id="industry-label">Select your Business Industry</span>
                                <SelectField
                                    options={options1}
                                    selectedValues={selectedOptions1}
                                    onSelect={(selectedList, selectedItem) => { setSelectedOptions1(selectedList) }}
                                    onRemove={(selectedList, removedItem) => { setSelectedOptions1(selectedList) }}
                                    placeholder='Add your business catagory.'
                                    selectionLimit={1}
                                    id="industry-input"
                                />
                            </div>
                            <div className={classes.field} >
                                <span className={classes.selectLabel} id="service-label">Brand / Product / Service</span>
                                <SelectField
                                    options={options2}
                                    selectedValues={selectedOptions2}
                                    onSelect={(selectedList, selectedItem) => { setSelectedOptions2(selectedList) }}
                                    onRemove={(selectedList, removedItem) => { setSelectedOptions2(selectedList) }}
                                    placeholder='Add three maximum details. '
                                    selectionLimit={3}
                                    id="service-input"
                                    onAddingSubCat={handleNewSubcategory}
                                />
                            </div>
                            {apiError !== "" &&
                                <span className={classes.fieldError} style={{ marginTop: 20 }} id="error-message">{apiError}</span>
                            }
                            <div className={classes.btnContainer}>
                                <button onClick={() => handleLogout()} id="logout-btn" className={classes.btn} style={{ width: 100 }}>Logout</button>
                                <button disabled={isAllowed() === true ? false : true} id="submit-btn" onClick={goNext} className={`${classes.btn}`} style={isAllowed() ? {} : { backgroundColor: '#C7C3C3' }}>
                                    {btnLoading ? <CircularProgress size={20} className={classes.btnLoading} /> : "Next"}
                                </button>
                            </div>
                        </div>
                    </div>
                </Container>
                <ToastContainer theme='colored' />
            </div>
        </div>
    )
};

export default withStyles(styles)(GetStarted);