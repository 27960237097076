import axios from 'axios';

/**
 * @description Get API to fetch all brands
 * @async
 * @function GetBrandsAPI
 * @returns Promise
**/


const GetBrandsAPI = async () => {

    try {

        let apiUrl = process.env.REACT_APP_API_URL + "/brand";
        const config = {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('access-token')
            } 
        }
        const response = await axios.get(apiUrl, config)
        return response.data

    } catch (e) {
        return e;
    }
}

export default GetBrandsAPI;
  