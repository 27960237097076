import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { FORM_INPUT_STYLES, CAMPAIGN_FORM_STEPS_HEADING, FORM_INPUT_CONTAINER_STYLES, CAMPAIGN_FORM_STEPS_LABEL, WHITE_BG, PRIMARY_COLOR, FORM_GROUP, FIELD_INCORRECT_DATA_VALIDATION, INVALID_FORM_VALIDATION } from '../../../../utils/CssConstants';

import CancelIcon from '@material-ui/icons/Cancel';
import GetCampaignPreference from '../../../../services/Campaign/CampaignList/GetCampaignPreference';


const styles = theme => ({
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.7)', // semi-transparent dark background
        zIndex: 1000,
    },
    modalContainer: {
        width: '40%',
        position: 'fixed',
        top: '20%',
        left: '32%',
        alignItems: 'center',
        flexDirection: 'column',
        zIndex: 1001,
        [theme.breakpoints.down('sm')]: {
            width: '80%',
            left: '7.5%'
        }
    },
    campaignInfoPopup: {
        background: 'rgba(0, 0, 0, 1)',
        border: '1px solid #1549d6',
        boxSizing: 'border-box',
        borderRadius: 20,
        width: '100%'
    },
    top: {
        margin: 15
    },
    header: {
        margin: 15,
        borderBottom: '2px solid #CECECE',
        height: '25%',
        boxSizing: 'border-box',
        justifyContent: 'space-between',
        flexDirection: 'row',
        display: 'flex'
    },
    headerTextLeft: {
        marginBottom: 10
    },
    crossIcon: {
        cursor: 'pointer',
        color: WHITE_BG,
    },
    content: {
        margin: 15,
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        overflowY: 'auto',
        overflowX: 'hidden',
        height: '250px',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            height: 400
        }
    },

    formGroup: FORM_GROUP,
    formInputStyles: FORM_INPUT_STYLES,
    formHeadingStyles: CAMPAIGN_FORM_STEPS_HEADING,
    formInputLabels: CAMPAIGN_FORM_STEPS_LABEL,
    fieldError: FIELD_INCORRECT_DATA_VALIDATION,
    invalidFormInputs: INVALID_FORM_VALIDATION,
    cardCheck: {
        width: '10%',
        borderLeft: '1px solid #B7B7B7',
        borderRight: 'none',
        justifyContent: 'center',
        alignItems: 'center',
        paddingRight: 0
    },
    infoLabels: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 20,
        marginRight: 10,
        color: WHITE_BG,
    },
    infoData: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 20,
        color: WHITE_BG,
        margin: 0,
    },
    dates: {
        display: 'flex',
        marginRight: 30
    },
    bottom: {
        width: '100%',
        marginTop: 2.5
    },
    budgetPostDetails: {
        width: '50%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            '&:last-child': {
                marginTop: 20
            }

        }
    },
    newCardDetails: {
        width: '60%',
        '&:last-child': {
            width: '40%'
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            '&:last-child': {
                width: '100%'
            },
        }
    },
    cardInput: {
        padding: 0,
        width: '80%',
        background: 'rgba(0, 0, 0, 0.8)',
        color: WHITE_BG,
        cursor: 'pointer',
        borderLeft: 'none'
    },
    btnSection: {
        margin: 15,
        height: '50%',
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'baseline'
    },
    formSubmit: {
        background: PRIMARY_COLOR,
        color: WHITE_BG,
        border: 'none',
        outline: 'none',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 20,
        width: '20%',
        minWidth: 120,
        height: 50,
        borderRadius: 5,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    btnLoading: {
        color: "#FFF !important"
    },
    crossIconCoupon: {
        cursor: 'pointer',
        color: '#CECECE',
        marginLeft: 10,
        position: 'relative',
        top: 1,
        right: 45,
        zIndex: 9
    },
    applyBtn: {
        background: PRIMARY_COLOR,
        color: WHITE_BG,
        border: 'none',
        borderRadius: 5,
        height: 40,
        width: 80,
        outline: 'none',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 16
    },
    couponDetails: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 18,
        marginBottom: 0,
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
})

const CampaignRulePopup = ({ classes, campaignPreferenceData, closeCampaignModal, openCampaignModal }) => {


    const node = useRef();


    useEffect(() => {
        function handleClickOutside(event) {
            if (node.current && !node.current.contains(event.target)) {
                closeCampaignModal()
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };

        // eslint-disable-next-line
    }, [node]);

    return (
        <>
            <div className={classes.overlay} onClick={closeCampaignModal}></div>
            <div ref={node} className={classes.modalContainer} >
                <div className={classes.campaignInfoPopup}>
                    <div className={classes.top}>
                        <div className={classes.header}>
                            <div className={classes.headerTextLeft}>
                                <p className={classes.infoLabels} style={{ fontSize: 24, width: '100%' }} id="campaign-name-label">Campaign Rules:</p>
                            </div>
                            <CancelIcon color="primary" id="cancelicon" className={classes.crossIcon} onClick={closeCampaignModal} />
                        </div>

                        <div className={classes.content}>
                            <div className='mt-3' style={{ marginLeft: '0' }}>

                                <div>
                                    {campaignPreferenceData?.minimum_followers &&
                                        <div className={classes.contentTile}>
                                            <p className={`${classes.infoLabels} ${classes.formInputLabelResponsive}`} style={{ width: '100%' }}>A minimum of {campaignPreferenceData?.minimum_followers < 1 || campaignPreferenceData?.minimum_followers == undefined || campaignPreferenceData?.minimum_followers == null ? 0 : campaignPreferenceData?.minimum_followers} followers is required.</p>
                                        </div>
                                    }
                                    {campaignPreferenceData?.is_hidden_campaign &&
                                        <div className={classes.contentTile}>
                                            <p className={`${classes.infoLabels} ${classes.formInputLabelResponsive}`} style={{ width: '100%' }}>The campaign is hidden.</p>
                                        </div>
                                    }
                                    {campaignPreferenceData?.is_purchase_required &&
                                        <div className={classes.contentTile}>
                                            <p className={`${classes.infoLabels} ${classes.formInputLabelResponsive}`} style={{ width: '100%' }}>Purchase Required - DM Receipt to Instagram handle</p>
                                        </div>
                                    }
                                    {campaignPreferenceData?.post_stay_days &&
                                        <div className={classes.contentTile}>
                                            <p className={`${classes.infoLabels} ${classes.formInputLabelResponsive}`} style={{ width: '100%' }}>The post must stay up for {campaignPreferenceData?.post_stay_days} {campaignPreferenceData?.post_stay_days > 1 ? 'days' : 'day'}.</p>
                                        </div>
                                    }
                                    {campaignPreferenceData?.by_appointment_only &&
                                        <div className={classes.contentTile}>
                                            <p className={`${classes.infoLabels} ${classes.formInputLabelResponsive}`} style={{ width: '100%' }}>By appointment only.</p>
                                        </div>
                                    }
                                    {campaignPreferenceData?.is_post_photo_on_event_day &&
                                        <div className={classes.contentTile}>
                                            <p className={`${classes.infoLabels} ${classes.formInputLabelResponsive}`} style={{ width: '100%' }}>Photo must stay up the day of the event.</p>
                                        </div>
                                    }
                                    {campaignPreferenceData?.post_limit &&
                                        <div className={classes.contentTile}>
                                            <p className={`${classes.infoLabels} ${classes.formInputLabelResponsive}`} style={{ width: '100%' }}>
                                                {campaignPreferenceData?.post_limit > 1 ? `${campaignPreferenceData?.post_limit} posts` : `${campaignPreferenceData?.post_limit} post`} per {' '}
                                                {campaignPreferenceData?.time_period == 'Daily' ? campaignPreferenceData?.post_limit > 1 ? 'days' : 'day' : null}
                                                {campaignPreferenceData?.time_period == 'Weekly' ? campaignPreferenceData?.post_limit > 1 ? 'Weeks' : 'Week' : null}
                                                {campaignPreferenceData?.time_period == 'Monthly' ? campaignPreferenceData?.post_limit > 1 ? 'Months' : 'Month' : null}
                                                {campaignPreferenceData?.time_period == 'Campaign' ? campaignPreferenceData?.post_limit > 1 ? 'Campaign' : 'Campaign' : null}

                                            </p>
                                        </div>
                                    }
                                    {campaignPreferenceData?.is_must_be21_or_older &&
                                        <div className={classes.contentTile}>
                                            <p className={`${classes.infoLabels} ${classes.formInputLabelResponsive}`} style={{ width: '100%' }}>Must be 21 or older</p>
                                        </div>
                                    }
                                    {/* {campaignPreferenceData?.ispreloadedimage &&
                                        <div className={classes.contentTile} >
                                            <p className={`${classes.infoLabels} ${classes.formInputLabelResponsive}`} style={{ width: '100%' }}>Post pre-loaded image</p>
                                            <div>
                                                <img src={campaignPreferenceData?.Preloadedimage} style={{ width: 150, height: 150 }} />
                                            </div>
                                        </div>
                                    } */}

                                </div>
                            </div>
                        </div>


                    </div>
                </div>
            </div>
        </>
    )
}

export default withStyles(styles)(CampaignRulePopup);