import * as actionTypes from '../../types/users';

export const getUsers = (users) => async (dispatch) => {
    await dispatch({
        type: actionTypes.GET_USER_HISTORY,
        payload: users
    });
};

export const getAllUsers = (users) => async (dispatch) => {
    await dispatch({
        type: actionTypes.GET_USER_ALL_HISTORY,
        payload: users
    });
};