import * as actionTypes from '../../types/common';

const initialState = {
    countryStates: []

};


const commonReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.GET_COUNTRY_STATES:
            return { ...state, countryStates: action.payload}
        default:
            return state;
    }
}

export default commonReducer;
