import React, { useEffect, useState } from 'react';
import { withStyles } from "@material-ui/core/styles";
import { Container } from '@material-ui/core';
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import logo from '../../assets/images/thumbLogoBlue.png';
import { WHITE_BG, BTN_COLOR_THEME, FIELD_INCORRECT_DATA_VALIDATION, INVALID_FORM_VALIDATION } from '../../utils/CssConstants';
import { logout, updateStatus } from '../../store/actions/Auth/authActions';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import CircularProgress from '@material-ui/core/CircularProgress';
import PostAddHashtagAndHandleAPI from '../../services/Auth/PostAddHashtagAndHandleAPI';
import Validate from '../../utils/Validation';

const styles = theme => ({
    root: {
        maxWidth: '100%',
        minHeight: '100vh',
    },
    height_100: {
        height: '100%'
    },
    main: {
        padding: '5%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        width: '80vw',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    pageHeadingContainer: {
        display: 'flex',
    },
    mainHeadingText: {
        marginLeft: '4px',
        fontSize: '3em',
        fontWeight: 600,
        fontFamily: "Poppins",
    },
    fieldsContainer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '80%',
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    field: {
        width: '100%',
        marginTop: '44px',
        display: 'flex',
        flexDirection: 'column'
    },
    selectLabel: {
        fontFamily: 'Poppins',
        fontWeight: 600,
        fontSize: '13px',
        marginBottom: '10px',
        display: 'inline-block'
    },
    btnContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'space-between',
        marginTop: '20vh',
        marginBottom: '10vh'

    },
    btn: {
        backgroundColor: BTN_COLOR_THEME,
        border: 'none',
        borderRadius: 4,
        height: 40,
        fontFamily: 'Inter, sans-serif',
        fontStyle: 'normal',
        fontWeight: '700',
        fontSize: 12,
        letterSpacing: '0.05em',
        color: WHITE_BG,
        width: '237px',
    },
    chatBtn: {
        position: 'absolute', bottom: 10, left: 20,
        width: '203px',
        height: '37px',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'white',
        border: 'none',
        padding: '10px 15px',
        fontSize: '11px',
        fontWeight: '500',
        boxShadow: '2px 2px 4px 1px rgba(0, 0, 0, 0.1)'
    },
    btnLoading: {
        marginRight: 10,
        color: "#FFF !important"
    },
    fieldError: FIELD_INCORRECT_DATA_VALIDATION,
    invalidFormInputs: INVALID_FORM_VALIDATION,
    formInputContStyles: {
        color: '#000',
        width: '100%',
        border: '1px solid #A7A5A5',
        height: 40,
        display: 'flex',
        outline: 'none',
        padding: 5,
        fontSize: 14,
        background: '#FFF',
        boxSizing: 'border-box',
        fontStyle: 'normal',
        fontFamily: 'Poppins',
        fontWeight: 'normal',
        borderRadius: 5,
        flexDirection: 'row'
    },
    formInputStyles:  {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 14,
        color: '#000000',
        boxSizing: 'border-box',
        padding: 5,
        border: 'none',
        width: '100%',
        outline: 'none',
        VerticalAlign: 'center',
    },
    formInputLabels: {
        color: '#000',
        width: '4%',
        height: 25,
        display: 'flex',
        fontSize: 14,
        fontStyle: 'normal',
        alignItems: 'center',
        fontFamily: 'Poppins',
        borderRight: '1px solid #A7A5A5',
        justifyContent: 'center',
        fontWeight: 600
    },
});

const InstagramSetup = ({ classes }) => {

    let navigate = useNavigate();
    const dispatch = useDispatch();
    const userDetails = useSelector((state) => state.authReducer.userDetails);
    const [btnLoading, setBtnLoading] = useState(false);
    const [handle, setHandle] = useState("");
    const [handleError, setHandleError] = useState(false);
    const [hashtag, setHashtag] = useState([]);
    const [hashtagError, setHashtagError] = useState(false);


    useEffect(() => {
        document.title = "Instagram Setup - Thumzup";
        if (userDetails.status_id === 1902) {
            navigate('/dashboard')
        }
    }, [userDetails, navigate])

    const validateForm = () => {
        setHandleError(false)
        setHashtagError(false)

        let handleError = false, hashtagError = false;

        if(Validate.notNullValidation(handle) === false) {
            handleError = true;
        } else {
            handleError = false;
        }

        if(Validate.notNullValidation(hashtag) === false) {
            hashtagError = true;
        } else {
            hashtagError = false;
        }

        setHandleError(handleError)
        setHashtagError(hashtagError)

        if(handleError || hashtagError) {
            return false;
        } else {
            return true;
        }
    }

    const handleHashtag = (newHashtag) => {
        const re = /^[a-zA-Z0-9\s,_]*$/; 
        if((newHashtag === '' || re.test(newHashtag))) {
            let commaSeperatedString = newHashtag.split(/[ ,]+/).join(',');
            setHashtag(commaSeperatedString)
        }
    }

    const handleInstaHandle = (text) => {
        const re = /^[a-zA-Z0-9_.]{1,30}$/; 
        if((text === '' || re.test(text))) {
            setHandle(text);
        }
    }

    const goNext = () => {
        let isValid = validateForm();
        if(isValid) {
            setBtnLoading(true);
            let hashtagFormatting = hashtag.split(',')
            hashtagFormatting = hashtagFormatting.filter(Boolean);
            hashtagFormatting = hashtagFormatting.map(i => '#' + i);
            hashtagFormatting = hashtagFormatting.toString();
            PostAddHashtagAndHandleAPI("@" + handle, hashtagFormatting).then(data => {
                setBtnLoading(false);
                if(data.status !== "SUCCESS") {
                    toast.error(data.error, "red");
                } else {
                    dispatch(updateStatus({...userDetails, status_id: 1902}));
                    navigate('/dashboard');
                }                
            });
        }
    }

    const handleLogout = () => {
        dispatch(logout({}));
        navigate('/')
    }

    return (
        <div className={classes.root} >
           <div style={{ position: 'relative', top: 0, left: 0, height: '100vh' }} >
                <Container fixed className={classes.height_100} style={{}} >
                    <div className={`${classes.main} ${classes.height_100}`}>
                        <div className={classes.pageHeadingContainer} style={{ marginBottom: 22, alignItems: 'center' }} >
                            <img src={logo} width={118} height={118} alt="welcome" id="thumzup-logo" />
                            <h2 className={classes.mainHeadingText} id="main-heading">Instagram Setup</h2>
                        </div>
                        <div className={classes.fieldsContainer}>
                            <div className={classes.field} >
                                <span className={classes.selectLabel} id="industry-label">Brand's Instagram Handle</span>
                                <div className={`${classes.formInputContStyles} ${handleError && classes.invalidFormInputs}`}>   
                                    <label htmlFor="handle" className={classes.formInputLabels} id="handle-label">@</label>
                                    <input className={classes.formInputStyles} id="handle" type="text" onChange={e => handleInstaHandle(e.target.value)} value={handle} />
                                </div>
                                <span className={classes.selectLabel} style={{ fontWeight: 'normal', fontSize: 10, marginTop: 5}}>Note: Enter Instagram handle without the @ symbol</span>
                                {handleError && <span className={classes.fieldError} id="error-handle" style={{ paddingLeft: 0 }}>Instagram handle cannot be empty</span>}
                            </div>
                            <div className={classes.field} >
                                <span className={classes.selectLabel} id="service-label">Brand's Instagram Hashtag</span>
                                <div className={`${classes.formInputContStyles} ${hashtagError && classes.invalidFormInputs}`}>   
                                    <label htmlFor="hashtag" className={classes.formInputLabels} id="hashtag-label">#</label>
                                    <input className={classes.formInputStyles} id="hashtag" type="text" onChange={e => handleHashtag(e.target.value)} value={hashtag} />
                                </div>
                                <span className={classes.selectLabel} style={{ fontWeight: 'normal', fontSize: 10, marginTop: 5}}>Note: Enter hashtags without # symbol and use space to seperate hashtags</span>
                                {hashtagError && <span className={classes.fieldError} id="error-hashtag" style={{ paddingLeft: 0 }}>Instagram hashtag cannot be empty</span>}
                            </div>
                            <div className={classes.btnContainer}>
                                <button onClick={() => handleLogout()} id="logout-btn" className={classes.btn} style={{ width: '15%' }}>Logout</button>
                                <button disabled={btnLoading} id="submit-btn" onClick={() => goNext()} className={classes.btn}>
                                    {btnLoading ? <CircularProgress size={20} className={classes.btnLoading} /> : "Continue"}
                                </button>
                            </div>
                        </div>
                    </div>
                </Container>
                <ToastContainer theme='colored' />
            </div>
        </div>
    )
};

export default withStyles(styles)(InstagramSetup);