import axios from 'axios';

/**
 * @description API to validate address in create campaign
 * @async
 * @function PostAddressValidateAPI
 * @returns Promise
**/


const PostAddressValidateAPI = async (body) => {

  try {
    
    const apiUrl = process.env.REACT_APP_API_URL + "/campaign/validate-addresses";

    const formData = {};
    let addresses = [];
    addresses = body.addressData.map((data) => ({
      address: data.address,
      city: data.city,
      state: data.state,
      latitude: data.latitude,
      longitude: data.longitude,
      zip_code: data.zipcode,
    }))
    formData.addresses = addresses;

    const config = {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access-token'),
        'Content-Type': 'application/json'
      } 
    }

    const response = await axios.post(apiUrl, JSON.stringify(addresses), config);
    return response.data;

  } catch (e) {
    return e;
  }

}

export default PostAddressValidateAPI;
  