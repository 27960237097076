import React, { useEffect, useState } from 'react';
import { withStyles } from "@material-ui/core/styles";
import { Visibility, VisibilityOff } from '@material-ui/icons';
import { useNavigate, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import { WHITE_BG, BTN_COLOR_THEME, LOGIN_FONT_COLOR, FIELD_INCORRECT_DATA_VALIDATION, INVALID_FORM_VALIDATION, PRIMARY_COLOR } from '../../utils/CssConstants';
import Layout from './Layout';
import Validate from '../../utils/Validation';
import { login } from '../../store/actions/Auth/authActions';
import CircularProgress from '@material-ui/core/CircularProgress';
import Helpers from '../../utils/Helpers';
import PostAgencyLoginAPI from '../../services/Auth/PostAgencyLoginAPI';



const styles = theme => ({
    loginFormLabels: {
        fontWeight: 600,
        fontSize: '1em',
        fontFamily: 'Poppins',
        color: LOGIN_FONT_COLOR,
        display: 'block'
    },
    invalidFormInputs: INVALID_FORM_VALIDATION,
    fieldError: FIELD_INCORRECT_DATA_VALIDATION,
    loginFormInput: {
        height: 40,
        padding: 10,
        marginBottom: 10,
        width: '80%',
        border: '1px solid #A7A5A5',
        outline: 'none',
        boxSizing: 'border-box',
        borderRadius: 5,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    loginPasswordCont: {
        position: 'relative',
        top: 0,
        left: 0,
        width: '80%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    EyeIcon: {
        position: 'absolute',
        right: 10,
        top: 7,
        cursor: 'pointer'
    },
    loginSubmitBtn: {
        background: BTN_COLOR_THEME,
        border: 'none',
        borderRadius: 4,
        height: 40,
        fontFamily: 'Inter, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: 12,
        letterSpacing: '0.05em',
        color: WHITE_BG,
        width: '237px',
        marginBottom: '5%',
        marginTop: '4%',
        display: 'block',
        [theme.breakpoints.down('sm')]: {
            height: 40,
        },
    },
    smallHeading: {
        fontWeight: 600,
        fontSize: '1.375em',
        fontFamily: 'Poppins',
        color: 'black',
        textAlign: 'center',
        marginBottom: '20px',
        marginRight: '25%'
    },
    infoText: {
        fontWeight: 600,
        fontSize: '0.875em',
        fontFamily: 'Poppins',
        color: 'black',
    },
    link: {
        color: PRIMARY_COLOR,
        cursor: 'pointer',
        [theme.breakpoints.down('sm')]: {
            display: 'block',
            marginTop: 5
        }
    },
    bottomLinks: {
        fontWeight: 500,
        fontSize: '11px',
        fontFamily: 'Poppins',
        color: PRIMARY_COLOR,
        textDecoration: 'none',
        "&:hover": {
            textDecoration: 'none',
            color: PRIMARY_COLOR,
        },

    },
    btnLoading: {
        marginRight: 10,
        color: "#FFF !important"
    },
    loginRemeberMeSection: {
        display: 'flex',
    },
    loginRemeberMe: {
        marginRight: 10,
        height: 15,
        width: 15,
    },
    loginRemeberMeText: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 11,
        letterSpacing: '0.05em',
        color: PRIMARY_COLOR,
        marginRight: 10
    },
});

const AgencyLogin = ({ classes }) => {

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isAuth = useSelector((state) => state.authReducer.isAuthenticated);
    const [passVisible, setPassVisible] = useState(false);
    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState(false);
    const [password, setPassword] = useState("");
    const [passwordError, setPasswordError] = useState(false);
    const [loginFailed, setLoginFailed] = useState(false);
    const [loginError, setLoginError] = useState("");
    const [rememberMe, setRememberMe] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);

    let queryParams = new URLSearchParams(useLocation().search);


    const handleVisiblity = () => {
        setPassVisible(!passVisible)
    }

    const validateForm = () => {
        setEmailError(false);
        setPasswordError(false);
        setLoginError("");
        setLoginFailed(false);

        let emailError, passwordError = false;



        if (Validate.emailValidation(email) === false) {
            emailError = true;
        } else {
            emailError = false;
        }

        if (Validate.passwordValidation(password) === false) {
            passwordError = true;
        } else {
            passwordError = false;
        }

        setEmailError(emailError);
        setPasswordError(passwordError);

        if (emailError || passwordError) {
            return false;
        } else {
            return true;
        }
    }

    const handleSubmit = async () => {
        let isValid = validateForm();
        if (isValid) {
            setBtnLoading(true);
            let data = {
                "password": password,
                "username": email
            };
            PostAgencyLoginAPI(data)
                .then(res => {
                    setBtnLoading(false);
                    if (res) {
                        if (res.status === 'SUCCESS') {
                            if (res.data.type_id === 1805) {
                                //check if its a verified user
                                dispatch(login({ ...res.data, token: res.token }, rememberMe));
                                localStorage.setItem('access-token', res.token)
                                setTimeout(Helpers.refreshToken, ((parseInt(res.token_expiry_time) - 60) / 60) * 60000)
                                if (res.data.is_verified) {
                                    //check status
                                    if (res.data.status_id === 1901) {
                                        //user is draft
                                        navigate('/get-started')
                                    }
                                    if (res.data.status_id === 1902) {
                                        //user is active
                                        navigate('/dashboard')
                                    }
                                } else {
                                    navigate('/signup-verification')
                                }
                            } else {
                                setLoginFailed(true);
                                setLoginError("Invalid email or password, please try again.")
                            }
                            if (queryParams.get('returnUrl')) navigate(queryParams.get('returnUrl'))
                        }
                        if (res.status === 'FAILURE') {
                            setLoginFailed(true);
                            setLoginError(res.error)
                        }
                    } else {
                        setLoginFailed(true);
                        setLoginError("There was an error, please try again.")
                    }
                })
                .catch(error => {
                    setLoginFailed(true);
                })
            // // setLoginSuccess("Success!");
            // let request = await PostLoginAPI(email, password);
            // console.log('res received', request)
            // if(request.status ===  200) {
            //     if(request.data.data.type_id === 1801) {
            //         dispatch(login(request.data.data));
            //         localStorage.setItem('access-token', request.data.token)
            //     } else {
            //         setLoginFailed(true);
            //     }
            // } else {
            //     console.log('err',request)
            //     setLoginFailed(true);
            // }
        }
    }

    useEffect(() => {
        document.title = "Login - Thumzup"
    }, []);

    return (
        <Layout pageHeadline="Welcome to The Thumzup Agency Area" pageSubHeading="Get paid to post about things you love">
            <h3 className={classes.smallHeading} style={{marginBottom:55}} id="sign-in-head">Sign In</h3>
            <label className={classes.loginFormLabels} htmlFor="email" id="email-label">User ID</label>
            <input className={`${classes.loginFormInput} ${emailError && classes.invalidFormInputs}`} type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
            {emailError && <span className={classes.fieldError} style={{ paddingLeft: 0 }}>Invalid email address</span>}
            <label className={classes.loginFormLabels} htmlFor="pass" id="pass-label">Password</label>
            <div className={classes.loginPasswordCont}>
                <input className={`${classes.loginFormInput} ${passwordError && classes.invalidFormInputs}`} type={passVisible ? "text" : "password"} id="pass" style={{ width: '100%' }} value={password} onChange={(e) => setPassword(e.target.value)} />
                {passVisible ? <Visibility className={classes.EyeIcon} onClick={handleVisiblity} color="disabled" id="open-eye" /> : <VisibilityOff className={classes.EyeIcon} onClick={handleVisiblity} color="disabled" id="closed-eye" />}
            </div>
            {passwordError && <span className={classes.fieldError} style={{ paddingLeft: 0 }}>Password must be minimum six characters</span>}
            {loginFailed && <span className={classes.fieldError} style={{ paddingLeft: 0 }}>{loginError}</span>}

            <button className={classes.loginSubmitBtn} id="login-btn" onClick={() => handleSubmit()} disabled={btnLoading}>{btnLoading ? <CircularProgress size={20} className={classes.btnLoading} /> : "LOG IN"}</button>

            <div className={classes.loginRemeberMeSection}>
                <input className={classes.loginRemeberMe} type="checkbox" checked={rememberMe} onChange={() => setRememberMe(!rememberMe)} id="remember" />
                <label className={classes.loginRemeberMeText} htmlFor="remember">Remember Me</label>
                {/* <Link to='/' className={classes.bottomLinks} id="forgot-username">Forgot username?</Link> */}
                <Link to='/forgot-password' className={classes.bottomLinks} style={{ marginLeft: '15px' }} id="forgot-pass">Forgot password?</Link>
            </div>

        </Layout>
    )
};

export default withStyles(styles)(AgencyLogin);