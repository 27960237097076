import React, { useState } from 'react';
import { withStyles } from "@material-ui/core/styles";
import { PRIMARY_COLOR } from '../../../utils/CssConstants';
import Helpers from '../../../utils/Helpers';
import SwitchButton from '../../Custom/SwitchButton';
import BlockUserAPI from '../../../services/Users/BlockUserAPI';
import { useDispatch, useSelector } from 'react-redux';
import { getUsers } from '../../../store/actions/Users/usersActions';
import { toast } from 'react-toastify';
import { CircularProgress } from '@material-ui/core';


const styles = {
    campaignListTDataRow: {
        display: 'flex',
        background: '#EDEEF7',
        border: '1px solid #000000',
        boxSizing: 'border-box',
        borderRadius: 10,
        padding: '15px 30px',
        width: '100%',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 10
    },
    campaingListData: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 20,
        padding: '5px 10px',
        textAlign: 'center',
        height: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        wordBreak: 'break-word'
    },
    smallSpace: {
        flex: 1
    },
    mediumSpace: {
        flex: 3
    },
    mediumSpaceTwo: {
        flex: 4
    },
    largeSpace: {
        flex: 7
    },
    red: {
        color: 'rgba(200, 55, 104, 1)',
    },
}

const TableData = ({ classes, usersData, seriesIndex, userDetails }) => {
    let dispatch = useDispatch();
    const [loadingState, setLoadingState] = useState({});

    const users = useSelector((state) => state.usersReducer.users);

    // Function to toggle user status
    const handleStatus = async (userId) => {
        setLoadingState(prevState => ({
            ...prevState,
            [userId]: true // Set loading state for this user ID to true
        }));
        try {
            const response = await BlockUserAPI(userId); // Assuming BlockUserAPI is imported
            // If the response is successful and user's account status was "ACTIVE", update it to "INACTIVE"
            if (response === true) {
                const updatedUsers = users.map(user => {
                    if (user.id === userId && user.account_status === "ACTIVE") {
                        return { ...user, account_status: "INACTIVE" };
                    }
                    return user;
                });
                // Dispatch an action to update the Redux store with the new user data
                dispatch(getUsers(updatedUsers));
                toast.success("User Status update to Inactive");
            }
        } catch (error) {
            console.error("Error blocking user:", error);
        } finally {
            setLoadingState(prevState => ({
                ...prevState,
                [userId]: false // Set loading state for this user ID to false, regardless of success or failure
            }));
        }
    };


    return (
        <>
            {(usersData.length > 0) && usersData.map((user, index) =>
                <tr className={classes.campaignListTDataRow} key={index}>
                    <td className={`${classes.campaingListData} ${classes.smallSpace}`} id="table-index">{seriesIndex + index}</td>
                    <td className={`${classes.campaingListData} ${classes.mediumSpaceTwo}`} id="table-campaign-name"><a href={'https://www.instagram.com/' + user.instagram_name} rel="noreferrer" target="_blank" style={{ color: PRIMARY_COLOR }}>{user.instagram_name !== null ? '@' + user.instagram_name : ''}</a></td>
                    <td className={`${classes.campaingListData} ${classes.mediumSpaceTwo}`} id="table-campaign-image">{user.phone_number}</td>
                    <td className={`${classes.campaingListData} ${classes.mediumSpace}`} id="table-posts-paid" style={{ wordBreak: 'break-word' }}>{user.posts_paid}</td>
                    <td className={`${classes.campaingListData} ${classes.mediumSpace}`} id="table-total-paid" style={{ wordBreak: 'break-word' }}>{user.total_paid !== (null || undefined) ? '$' + Helpers.numberWithCommas(user.total_paid) : '$0'}</td>
                    <td className={`${classes.campaingListData} ${classes.mediumSpace}`} id="table-posts-pending" style={{ wordBreak: 'break-word' }}>{user.posts_pending !== (null || undefined) ? Helpers.numberWithCommas(user.posts_pending) : '0'}</td>
                    <td className={`${classes.campaingListData} ${classes.mediumSpace}`} id="table-amount-pending" style={{ wordBreak: 'break-word' }}>{user.amount_pending !== (null || undefined) ? '$' + Helpers.numberWithCommas(user.amount_pending) : "$0"}</td>
                    <td className={`${classes.campaingListData} ${classes.mediumSpace}`} id="table-amount-approved" style={{ wordBreak: 'break-word' }}>{user.balance_approved_amount !== (null || undefined) ? '$' + Helpers.numberWithCommas(user.balance_approved_amount) : "$0"}</td>
                    <td className={`${classes.campaingListData} ${classes.mediumSpace}`} id="table-status" style={{ display: 'list-item', listStyle: 'none' }}><p style={{ margin: 0, textAlign: 'left' }}>{user.date_joined !== null ? Helpers.formatDate(user.date_joined + 'Z') : ""}</p></td>



                    <td className={`${classes.campaingListData} ${classes.mediumSpace}`} style={{ wordBreak: 'break-word' }} id="table-campaign-switch">
                        {user.account_status === "ACTIVE" ?
                            <>
                                {userDetails.type_id === 1802 ?
                                    <div className={`${"pendi"} `} style={{ cursor: 'pointer' }} id="table-status" onClick={() => handleStatus(user.id)}>
                                        Block
                                    </div>
                                    :
                                    "Active"
                                }
                            </>
                            :
                            <div className={classes.red}>Blocked</div>
                        }
                        {loadingState[user.id] && (
                            <CircularProgress size={18} style={{ marginRight: '5px' }} />
                        )}
                    </td>

                </tr>
            )}
        </>
    )
}

export default withStyles(styles)(TableData);