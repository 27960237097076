import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import rootReducer  from './reducers/index';
import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

const persistConfig = {
  key: 'authReducer',
  storage: storage,
  whitelist: ['authReducer', 'commonReducer', 'createCampaignReducer'], // which reducer want to store
  blacklist: ['dashboardReducer', 'campaignListReducer'],
};

const persistedReducer = persistReducer(persistConfig, rootReducer);


const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
export const store = createStore(persistedReducer, {}, composeEnhancers(applyMiddleware(thunk))); 
export const persistor = persistStore(store);