import axios from 'axios'

/**
 * @description Get API to fetch brand's post's summary
 * @async
 * @function GetPostSummaryReportAPI
 * @returns Promise
**/


const GetPostSummaryReportAPI = async (page, size, brandId = null) => {
  
    try {

      let apiUrl = `${process.env.REACT_APP_API_URL}/posts/post-summary-report?page=${page}&size=${size}`
      if(brandId) apiUrl = apiUrl + '&brandId=' + brandId
      const config = {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('access-token')}`  
        } 
      }

      const response = await axios.get(apiUrl, config)
      return response.data
  
    } catch (e) {
      return e.response.data
    }
}

export default GetPostSummaryReportAPI