import * as actionTypes from '../../types/campaignlist';

const initialState = {
    campaignLists: []
};


const campaignListReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.GET_CAMPAIGN_LIST:
            return { ...state, campaignLists: action.payload };
        case actionTypes.UPDATE_CAMPAIGN_STATUS:
            return {
                ...state,
                campaignLists: state.campaignLists.map((campaign, i) => campaign.campaign_id === action.payload[0] ? {...campaign, status_id: action.payload[1] } : campaign)
            }
        default:
            return state;
    }
}

export default campaignListReducer;
