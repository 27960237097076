import React, { useState, useEffect, useContext } from 'react';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { getUsers } from '../../store/actions/Users/usersActions';
import GetUsersHistoryAPI from '../../services/Users/GetUsersHistoryAPI';
import { withStyles } from '@material-ui/styles';
import { AppContext } from '../../utils/AppContext';



const styles = theme => ({

    searchBar: {
        border: '1px solid #CECECE',
        borderRadius: 5,
        width: '100%',
        outline: 'none',
        padding: 14,
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 18,

    },
    searchIcon: {
        position: 'absolute',
        right: 15,
        top: 17,
    },
})

const Search = ({ classes }) => {
    const { loader, setLoader,
        totalUsers, setTotalUsers,
        searchValue, setSearchValue,
        errorData, setErrorData } = useContext(AppContext);



    const [currentPage, setCurrentPage] = useState(1);

    const userDetails = useSelector((state) => state.authReducer.userDetails);
    const brandView = useSelector((state) => state.authReducer.brandView);

    const navigate = useNavigate();
    let dispatch = useDispatch();


    async function search(event, searchTerm) {
        if (event.key === 'Enter' || event.type === 'click') {
            if (searchTerm.trim() === '') {
                return
            }
            setCurrentPage(1)
            setLoader(true)
            await GetUsersHistoryAPI(currentPage, 10, searchTerm, (brandView ? userDetails.brand_id : null)).then(data => {
                if (data !== undefined) {
                    dispatch(getUsers(data.data.accounts));
                    setTotalUsers(data.data.total);
                    if (data.data.total <= 0) setErrorData('No records');

                }
                setLoader(false);
            }).catch(err => console.log(err));
        }
    }

    const resetSearch = async (value) => {
        const re = /^[a-zA-Z0-9_+.@\s-]*$/;
        if ((value.trim() === '' || re.test(value))) {
            setSearchValue(value)

            if (value === '' && brandView) {
                await GetUsersHistoryAPI(currentPage, 10, value, userDetails.brand_id).then(data => {
                    if (data !== undefined) {
                        dispatch(getUsers(data.data.accounts));
                        setTotalUsers(data.data.total);
                        if (data.data.total <= 0) setErrorData('No records');
                    }
                }).catch(err => console.log(err));
            } else if (!brandView && value === '') {
                setLoader(true)
                setErrorData('Please select a brand');
                setLoader(false);
                dispatch(getUsers([]));
                setTotalUsers([])
                setCurrentPage(1)
            }
        }

    }





    return (
        <div className='searchBarCont'>
            <div className={classes.searchContainer}>
                <input type="text" placeholder="Search User" className={classes.searchBar} value={searchValue} onChange={e => resetSearch(e.target.value)} onKeyDown={e => search(e, searchValue)} />
                <FontAwesomeIcon icon={faSearch} className={classes.searchIcon} color="#CECECE" size="lg" onClick={e => search(e, searchValue)} />
            </div>

        </div>
    );
};

export default withStyles(styles)(Search);