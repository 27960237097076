import * as actionTypes from '../../types/auth'

export const login = (data, rememberMe) => async (dispatch) => {
    await dispatch({
        type: actionTypes.LOGIN,
        payload: data,
        rememberMe: rememberMe
    });
};

export const updateStatus = (data) => async (dispatch) => {
    await dispatch({
        type: actionTypes.UPDATE_STATUS,
        payload: data,
    });
};

export const logout = (data) => async (dispatch) => {
    localStorage.removeItem('access-token');
    await dispatch({
        type: actionTypes.LOGOUT,
        payload: data
    });
};

export const register = (data) => (dispatch) => {
    dispatch({
        type: actionTypes.REGISTER,
        payload: data
    });
};

export const brandView = (data, brandView) => (dispatch) => {
    dispatch({
        type: actionTypes.BRAND_VIEW,
        payload: data,
        brandView: brandView
    });
};