import React, { useState } from 'react';
import { withStyles } from "@material-ui/core/styles";
import { Container } from '@material-ui/core';
import { useNavigate, useSearchParams } from "react-router-dom";
import { useSelector, useDispatch } from 'react-redux';
import wavyBg from '../../assets/images/wavyBackgroundBlue.png';
import logo from '../../assets/images/thumbLogoBlue.png';
import map from '../../assets/images/map.png';
import topImg from '../../assets/images/topcutout.png';
import circle from '../../assets/images/Ellipse 1Blue.png';
import { FIELD_INCORRECT_DATA_VALIDATION, PRIMARY_COLOR } from '../../utils/CssConstants';
import PostResendEmailAPI from '../../services/Auth/PostResendEmailApi';
import { logout } from '../../store/actions/Auth/authActions';



const styles = theme => ({
    root: {
        maxWidth: '100%',
        minHeight: '100vh',
        backgroundImage: `url(${wavyBg}), url(${map})`,
        backgroundRepeat: 'no-repeat, no-repeat',
        backgroundPosition: '100% 0%, 0px 170px',
        position: 'relative', top: 0, left: 0,
        [theme.breakpoints.down('sm')]: {
            backgroundPosition: '100% 0%, center',
        },
        overflowX:'hidden'
    },
    height_100: {
        height: '100%'
    },
    main: {
        padding:'5%',
        paddingBottom: '100px',
        display:'flex',
        flexDirection:'column',
        width:'60vw',
        justifyContent:'center',
        [theme.breakpoints.down('sm')]: {
           paddingBottom: 0,
           width:'100%',
        },
    },
    pageHeadingContainer: {
        display: 'flex',
    },
    mainHeadingText: {
        marginLeft: '4px',
        fontSize:'3em',
        fontWeight:600,
        fontFamily: "Poppins",
    },
    subText:{
        marginLeft: '14px',
        fontSize:'1.130em',
        fontWeight:500,
        fontFamily: "Poppins",
        width: '71%',
        [theme.breakpoints.down('sm')]: {
            wordBreak: 'break-word',
            fontSize:'1.130em',
        },
    },
    infoText: {
        fontWeight: 600,
        fontSize: '0.8125em',
        fontFamily: 'Poppins',
        color: 'black',
        textAlign:'center',
        marginRight: '20%'
    },
    link: {
        color: PRIMARY_COLOR,
        cursor: 'pointer',
        fontWeight: 800
    },
    chatBtn: {
        position: 'absolute', bottom: 10, left: 20,
        width: '203px',
        height: '37px',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: 'white',
        border: 'none',
        padding: '10px 15px',
        fontSize: '11px',
        fontWeight: '500',
        boxShadow: '2px 2px 4px 1px rgba(0, 0, 0, 0.1)'
    },
    fieldError: FIELD_INCORRECT_DATA_VALIDATION
});

const SignupVerification = ({ classes, children }) => {

    const navigate = useNavigate();
    const dispatch = useDispatch();
    const [searchParams, setSearchParams] = useSearchParams();

    let email = searchParams.get('email');
    const isAuth = useSelector((state) => state.authReducer.isAuthenticated);
    const adminId = useSelector((state) => state.authReducer.userDetails.admin_id);
    const [apiError, setAPIError] = useState("")
    const [successMsg, setSuccessMessage] = useState("")
    document.title = "Verification - Thumzup";

    const resendEmail = () => {
        PostResendEmailAPI(searchParams.get('id') ? searchParams.get('id') : adminId)
        .then(res=>{
            if(res){
                if(res.status==='SUCCESS'){
                    setSuccessMessage("Email sent successfully!")
                }
                if(res.status==='FAILURE'){
                    setAPIError(res.error)
                }
            }
        })
        .catch(error=>{
            console.log('error', error)
        })
    }

    const handleLogout = () => {
        dispatch(logout({}));
        navigate('/')
    }

    return (
        <div className={classes.root} >
            {/* <button className={classes.chatBtn}>
                <img src={chat} style={{ marginRight: '6px' }} alt="chat" />
                Need Help? Let’s Chat!
            </button> */}
            <div style={{ position: 'relative', top: 0, left: 0, height:'100vh' }} >
                {isAuth &&
                    <button className={classes.chatBtn} id="logout-btn" style={{ width: 'auto' }} onClick={() => handleLogout()}>Logout</button>
                }
                <img src={topImg} style={{ position: 'absolute', top: 40, right: 20 }} alt="top" id="top-logo" />
                <Container fixed className={classes.height_100} style={{}} >
                        <div className={`${classes.main} ${classes.height_100}`}>
                            <div className={classes.pageHeadingContainer} style={{ marginBottom:22, alignItems:'center' }} >
                                <img src={logo} width={118} height={118} alt="logo" id="thumzup-logo" />
                                <h2 className={classes.mainHeadingText} id="main-heading">You’re almost done!</h2>
                            </div>
                            <div className={classes.pageHeadingContainer} style={{marginBottom:30}} >
                                <img src={circle} width={30} height={30} alt="circle" id="circle" />
                                <h2 className={classes.subText} id="sub-txt">
                                    {`Please confirm ${email ? email : ''} is your email address. Please check your email. Haven't receieved verification email yet? `}
                                    <span className={classes.link} id="resend" onClick={()=>resendEmail()}> Resend it</span> 
                                </h2>
                            </div>
                            {apiError !== "" &&
                                <span className={classes.fieldError} id="error-message">{apiError}</span>
                            }
                            {successMsg !== "" &&
                                <span className={classes.fieldError} style={{ color: "#4BB543" }} id="success-message">{successMsg}</span>
                            }

                            <p className={classes.infoText} id="info-txt">Need help? Ask at <a href="mailto:team@thumzupmedia.com" className={classes.link} onClick={() =>{}}>team@thumzupmedia.com</a></p>
                            {/* or visit our <span className={classes.link} onClick={() =>{}}>Help Center</span>  */}
                        </div>
                </Container>
            </div>
        </div>
    )
};

export default withStyles(styles)(SignupVerification);