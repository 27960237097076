import axios from 'axios';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {  toast } from 'react-toastify';  // Import the toast function from the library
import 'react-toastify/dist/ReactToastify.css';  // Import the CSS for styling
import Helpers from './Helpers';
import { logout } from '../store/actions/Auth/authActions';
import { resetCreateCampaign } from '../store/actions/Campaign/createCampaignActions';

const Axios = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();


    axios.interceptors.response.use(
        response => response,
        error => {
            if (error.response) {
                if (error.response.status === 401 || error.response.status === 403) {
                    handleLogout();
                }
            } else {
                toast.error('Server network error',{toastId:1});
            }
            return Promise.reject(error);
        }
    );

    const handleLogout = () => {
        const defaultState = Helpers.returnDefaultState();
        dispatch(logout({}));
        dispatch(resetCreateCampaign(defaultState.initialBillingState, defaultState.initialCampaignState, defaultState.initialProgressState, defaultState.previousCards));
        navigate('/');
    };

    return null;
};

export default Axios;
