import axios from 'axios';

/**
 * @description Get API to fetch ALL Brands List
 * @async
 * @function GetBrandAPI
 * @returns Promise
**/


const GetBrandAPI = async (page, size) => {
  
    try {

      let apiUrl = process.env.REACT_APP_API_URL + "/brand/brand-list?pageNumber="+ page + "&size=" + size;
      const config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access-token')
        } 
      }

      const response = await axios.get(apiUrl, config)
      return response.data
  
    } catch (e) {
      return e
    }
}

export default GetBrandAPI;
  