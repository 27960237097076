import axios from 'axios';
import Helpers from '../../../utils/Helpers';

/**
 * @description API to Update brand campaign image
 * @async
 * @function PostUpdateImageAPI
 * @returns Promise
**/


const PostUpdateImageAPI = async (id, image, imageName) => {

  try {
    const apiUrl = process.env.REACT_APP_API_URL + "/campaign/image/" + parseInt(id);
    let myFile = Helpers.dataURLtoFile(image, imageName);

    const formData = new FormData();
    formData.append('file', myFile);

    const config = {
      headers: {
        Authorization: 'Bearer ' + localStorage.getItem('access-token'),
        'Content-Type': 'multipart/form-data'
      }
    }

    const response = await axios.put(apiUrl, formData, config);

    return response.data;

  } catch (e) {
    return e;
  }
}

export default PostUpdateImageAPI;
