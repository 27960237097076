import axios from 'axios';

/**
 * @description POST API to login into app
 * @async
 * @function PostLoginAPI
 * @returns Promise
**/


const PostLoginAPI = async (username, password) => {
  
    try {

        let apiUrl = process.env.REACT_APP_API_URL + "/auth";
        const body = {
            "username": username,
            "password": password
        };

        const response = await axios.post(apiUrl, body);
        return response;
        
    } catch (e) {
      console.error(e);
      return [];
    }
}

export default PostLoginAPI;
  