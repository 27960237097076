import React, { useState } from 'react';
import { withStyles } from "@material-ui/core/styles";
import { FIELD_INCORRECT_DATA_VALIDATION, PRIMARY_COLOR } from '../../../utils/CssConstants';
import Helpers from '../../../utils/Helpers';
import { useDispatch, useSelector } from 'react-redux';
import { getUsers } from '../../../store/actions/Users/usersActions';
import { toast } from 'react-toastify';
import { CircularProgress } from '@material-ui/core';
import Modal from '@mui/material/Modal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import BlockUserAPI from '../../../services/Users/BlockUserAPI';
import UpdateInstagramHandleAPI from "../../../services/Users/EditUserApi";

const styles = {
    campaignListTDataRow: {
        display: 'flex',
        background: '#EDEEF7',
        border: '1px solid #000000',
        boxSizing: 'border-box',
        borderRadius: 10,
        padding: '15px 30px',
        width: '100%',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 10
    },
    fieldError: FIELD_INCORRECT_DATA_VALIDATION,

    campaingListData: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 20,
        padding: '5px 10px',
        textAlign: 'center',
        height: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        wordBreak: 'break-word'
    },
    smallSpace: {
        flex: 1
    },
    mediumSpace: {
        flex: 3
    },
    mediumSpaceTwo: {
        flex: 4
    },
    largeSpace: {
        flex: 5
    },
    red: {
        color: 'rgba(200, 55, 104, 1)',
    },
}

const TableData = ({ classes, usersData, seriesIndex, userDetails, setIsEdit, isEdit, UpdatedUsers }) => {
    let dispatch = useDispatch();
    const [loadingState, setLoadingState] = useState({});

    const users = useSelector((state) => state.usersReducer.users);
    const [open, setOpen] = useState(false);
    const [instagramHandle, setInstagramHandle] = useState('');
    const [selectedUser, setSelectedUser] = useState(null);
    const [instagramHandleError, setInstagramHandleError] = useState(false);
    const [twitterHandle, setTwitterHandle] = useState("");
    const [twitterHandleError, setTwitterHandleError] = useState(false);

    const handleOpen = (user) => {
        setInstagramHandle(user?.instagram_name || '');
        setTwitterHandle(user.handle_map?.twitter || '');
        setSelectedUser(user);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSave = async () => {
        try {
            setInstagramHandleError(false);
            setTwitterHandleError(false);
            const instagramHandleRegex = /^[a-zA-Z0-9._]*$/;
            const twitterHandleRegex = /^[a-zA-Z0-9_]+$/;

            if (!instagramHandleRegex.test(instagramHandle)) {
                setInstagramHandleError(true);
                return;
            }

            // if (!twitterHandleRegex.test(twitterHandle)) {
            //     setTwitterHandleError(true);
            //     return;
            // }

            setLoadingState((prevState) => ({
                ...prevState,
                ["handle"]: true,
            }));

            const response = await UpdateInstagramHandleAPI(selectedUser.id, instagramHandle);
            if (response.status === 'SUCCESS') {
                await UpdatedUsers();
                toast.success(response.message);
            } else {
                toast.error('Failed to update handles');
            }
        } catch (error) {
            console.log('Error updating user:', error);
            toast.error(error?.response?.data?.error || 'Something went wrong');
        } finally {
            setLoadingState((prevState) => ({
                ...prevState,
                ["handle"]: false
            }));
            handleClose();
        }
    };

    const handleStatus = async (userId) => {
        setLoadingState((prevState) => ({
            ...prevState,
            [userId]: true,
        }));
        try {
            const response = await BlockUserAPI(userId);
            if (response === true) {
                const updatedUsers = users.map((user) => {
                    if (user.id === userId && user.account_status === 'ACTIVE') {
                        return { ...user, account_status: 'INACTIVE' };
                    }
                    return user;
                });
                dispatch(getUsers(updatedUsers));
                toast.success('User Status updated to Inactive');
            }
        } catch (error) {
            console.error('Error blocking user:', error);
        } finally {
            setLoadingState((prevState) => ({
                ...prevState,
                [userId]: false,
            }));
        }
    };

    return (
        <>
            {usersData.length > 0 &&
                usersData.map((user, index) => (
                    <tr className={classes.campaignListTDataRow} key={index}>
                        <td className={`${classes.campaingListData} ${classes.smallSpace}`} id="table-index">
                            {seriesIndex + index}
                        </td>
                        <td className={classes.largeSpace} style={{ display: 'block', paddingBottom: 0 }} id="table-campaign-name">
                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px',fontSize: '18px'  }}>
                                <span >Instagram</span>
                                <a href={'https://www.instagram.com/' + user.instagram_name} rel="noreferrer" target="_blank" style={{
                                    color: PRIMARY_COLOR, fontFamily: 'Poppins', fontWeight: 'normal'
                                }}>
                                    {user.instagram_name ? '@' + user.instagram_name : ''}
                                </a>

                            </div>

                            <div style={{ display: 'flex', alignItems: 'center', gap: '8px', marginTop: '2px',fontSize: '18px'  }}>
                                <span>Twitter</span>
                                <a href={'https://www.twitter.com/' + user.handle_map?.twitter} rel="noreferrer" target="_blank" style={{
                                    color: PRIMARY_COLOR, fontFamily: 'Poppins', fontWeight: 'normal'
                                }}>
                                    {user.handle_map?.twitter ? '@' + user.handle_map.twitter : ''}
                                </a>
                            </div>
                        </td>

                        <td className={`${classes.campaingListData} ${classes.mediumSpaceTwo}`} id="table-campaign-image">
                            {user.phone_number}
                        </td>
                        <td
                            className={`${classes.campaingListData} ${classes.mediumSpace}`}
                            id="table-posts-paid"
                            style={{ wordBreak: 'break-word' }}
                        >
                            {user.posts_paid}
                        </td>
                        <td
                            className={`${classes.campaingListData} ${classes.mediumSpace}`}
                            id="table-total-paid"
                            style={{ wordBreak: 'break-word' }}
                        >
                            {user.total_paid !== (null || undefined)
                                ? '$' + Helpers.numberWithCommas(user.total_paid)
                                : '$0'}
                        </td>
                        <td
                            className={`${classes.campaingListData} ${classes.mediumSpace}`}
                            id="table-posts-pending"
                            style={{ wordBreak: 'break-word' }}
                        >
                            {user.posts_pending !== (null || undefined)
                                ? Helpers.numberWithCommas(user.posts_pending)
                                : '0'}
                        </td>
                        <td
                            className={`${classes.campaingListData} ${classes.mediumSpace}`}
                            id="table-amount-pending"
                            style={{ wordBreak: 'break-word' }}
                        >
                            {user.amount_pending !== (null || undefined)
                                ? '$' + Helpers.numberWithCommas(user.amount_pending)
                                : '$0'}
                        </td>
                        <td
                            className={`${classes.campaingListData} ${classes.mediumSpace}`}
                            id="table-amount-approved"
                            style={{ wordBreak: 'break-word' }}
                        >
                            {user.balance_approved_amount !== (null || undefined)
                                ? '$' + Helpers.numberWithCommas(user.balance_approved_amount)
                                : '$0'}
                        </td>
                        <td
                            className={`${classes.campaingListData} ${classes.mediumSpace}`}
                            id="table-status"
                            style={{ display: 'list-item', listStyle: 'none' }}
                        >
                            <p style={{ margin: 0, textAlign: 'left' }}>
                                {user.date_joined !== null
                                    ? Helpers.formatDate(user.date_joined + 'Z')
                                    : ''}
                            </p>
                        </td>

                        <td
                            className={`${classes.campaingListData} ${classes.mediumSpace}`}
                            style={{ wordBreak: 'break-word' }}
                            id="table-campaign-switch"
                        >
                            {user.account_status === 'ACTIVE' ? (
                                <>
                                    {userDetails.type_id === 1802 ? (
                                        <div
                                            className={`${'pendi'} `}
                                            style={{ cursor: 'pointer' }}
                                            id="table-status"
                                            onClick={() => handleStatus(user.id)}
                                        >
                                            Block
                                        </div>
                                    ) : (
                                        'Active'
                                    )}
                                </>
                            ) : (
                                <div className={classes.red}>Blocked</div>
                            )}
                            {loadingState[user.id] && (
                                <CircularProgress size={18} style={{ marginRight: '5px' }} />
                            )}
                        </td>

                        {userDetails.type_id === 1802 && (
                            <td
                                className={`${classes.campaingListData} ${classes.smallSpace}`}
                                style={{ display: "block", paddingBottom: 0 }}
                                id="table-campaign-name"
                            >
                                {/* Edit Icon */}
                                <div className="pendi" style={{ cursor: "pointer" }} onClick={() => handleOpen(user)}>
                                    <FontAwesomeIcon icon={faPenToSquare} />
                                </div>

                                {open && selectedUser === user && (
                                    <Modal open={open} onClose={handleClose}>
                                        <div
                                            style={{
                                                display: "flex",
                                                alignItems: "center",
                                                justifyContent: "center",
                                                padding: "20px",
                                                background: "white",
                                                margin: "20px auto",
                                                maxWidth: "500px",
                                                borderRadius: "10px",
                                                position: "absolute",
                                                top: "50%",
                                                left: "50%",
                                                transform: "translate(-50%, -50%)",
                                                width: "100%",
                                            }}
                                        >
                                            <div style={{ width: "100%" }}>
                                                <h4 id="limit-posting-heading">Edit User</h4>

                                                {/* Instagram Handle */}
                                                <p className="handle">Instagram Handle</p>
                                                <div
                                                    className={`${classes.formInputContStyles} ${instagramHandleError ? classes.invalidFormInputs : ""}`}
                                                    style={{
                                                        display: "flex",
                                                        alignItems: "center",
                                                        border: "1px solid #ccc",
                                                        borderRadius: "5px",
                                                        padding: 2,
                                                        margin: 5,
                                                        marginTop: "10px",
                                                    }}
                                                >
                                                    <label htmlFor="instagram-handle" style={{ padding: "5px", fontSize: "20px" }}>
                                                        @
                                                    </label>
                                                    <input
                                                        className={classes.formInputStyles}
                                                        style={{ border: "none", outline: "none", flex: 1, padding: "5px", backgroundColor: "#f5f5f5" }}
                                                        id="instagram-handle"
                                                        type="text"
                                                        value={instagramHandle ?? ''}
                                                        onChange={(e) => {
                                                            const newValue = e.target.value;
                                                            const regex = /^[a-zA-Z0-9._]*$/;
                                                            if (newValue === "" || regex.test(newValue)) {
                                                                console.log("newValue", newValue);
                                                                setInstagramHandle(newValue);
                                                                setInstagramHandleError(false);
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                {instagramHandleError && (
                                                    <p style={{ color: "red", fontSize: "15px", marginTop: "4px", textAlign: "left" }}>
                                                        <span className={classes.fieldError}>Required Instagram Handle</span>
                                                    </p>
                                                )}

                                                {/* Update Button */}
                                                <div style={{ display: "flex", justifyContent: "flex-end", alignItems: 'center', marginTop: "20px" }}>
                                                    {loadingState["handle"] && (
                                                        <CircularProgress size={26} style={{ marginRight: '15px' }} />
                                                    )}
                                                    <button
                                                        onClick={() => {
                                                            if (!instagramHandle.trim()) {
                                                                setInstagramHandleError(true);
                                                            }
                                                            handleSave(selectedUser);
                                                        }}
                                                        className='btn'
                                                        disabled={
                                                            loadingState["handle"] ||
                                                            !instagramHandle.trim() ||
                                                            instagramHandle === user?.instagram_name
                                                        }
                                                        style={{
                                                            
                                                            padding: "12px 20px",
                                                            fontSize: "15px",
                                                            fontWeight: "bold",
                                                            backgroundColor: "#3f51b5",
                                                            color: "white",
                                                            border: "none",
                                                            borderRadius: "6px",
                                                            cursor: "pointer",
                                                            transition: "background 0.3s",
                                                        
                                                        }}
                                                    >
                                                        Update
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </Modal>
                                )}
                            </td>
                        )}



                    </tr>
                ))}
        </>
    );
};

export default withStyles(styles)(TableData);