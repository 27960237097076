import axios from 'axios';

/**
 * @description Get API to fetch a brand campaigns that needs recharging
 * @async
 * @function GetRechargeCampaignListAPI
 * @returns Promise
**/


const GetRechargeCampaignListAPI = async (page, size, brandId = null) => {

    let apiUrl = `${process.env.REACT_APP_API_URL}/campaign/recharge-campaign-list?pageNumber=${page}&pageSize=${size}`;
    if(brandId !== null) apiUrl = apiUrl + '&brandId=' + brandId;
    const config = {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('access-token')
        } 
    }

    const response = await axios.get(apiUrl, config);
    return response.data;
}

export default GetRechargeCampaignListAPI;
  