import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { useNavigate } from 'react-router-dom';
import { BTN_COLOR_THEME, WHITE_BG } from '../../utils/CssConstants';


const styles = {
    root: {
        background: 'rgba(247, 247, 247, 1)',
        width: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column'
    },
    invalidHeading: {
        fontSize: 40,
        color: 'black',
        fontFamily: 'Poppins',
        fontWeight: 600,
        marginBottom: 20,
    },
    button: {
        background: BTN_COLOR_THEME,
        border: 'none',
        borderRadius: 4,
        height: 60,
        fontFamily: 'Inter, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: 24,
        letterSpacing: '0.05em',
        color: WHITE_BG,
        width: '10%',
        marginBottom: '5%',
        marginTop: '4%',
        display: 'block',
    }
};


const InvalidRoute = ({ classes }) => { 

    const navigate = useNavigate();

    return (
        <div className={classes.root}>
            <h1 className={classes.invalidHeading}>Whoops! Looks like you're lost!</h1>
            <button className={classes.button} onClick={() => { navigate("/") }}>Home</button>
        </div>
    )
};

export default withStyles(styles)(InvalidRoute);