import axios from 'axios';

/**
 * @description Get API to fetch posts in dashboard
 * @async
 * @function GetPostsAPI
 * @returns Promise
**/


const GetPostsAPI = async (page, size, postStatus, brandId = null) => {
  
    try {

      let apiUrl = process.env.REACT_APP_API_URL + "/posts?page="+ page + "&size=" + size + "&statusId=" + postStatus;
      if(brandId !== null) apiUrl = apiUrl + '&brandId=' + brandId;

      const config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access-token')
        } 
      }

      const response = await axios.get(apiUrl, config)
      return response.data.data
  
    } catch (e) {
      return e;
    }
}

export default GetPostsAPI;
  