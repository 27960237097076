import { combineReducers } from 'redux';
import authReducer from './Auth/authReducer';
import dashboardReducer from './Dashboard/dashboardReducer';
import createCampaignReducer from './Campaign/createCampaignReducer';
import campaignListReducer from './Campaign/campaignListReducer';
import commonReducer from './Common/commonReducer';
import usersReducer from './Users/usersReducer';
import postHistoryReducer from './PostHistory/postHistoryReducer';
import newBusinessReducer from './NewBusiness/newBusinessReducer';
import brandsReducer from './Brands/brandsReducer';

const rootReducer = combineReducers({
    authReducer,
    dashboardReducer,
    createCampaignReducer,
    campaignListReducer,
    commonReducer,
    usersReducer,
    postHistoryReducer,
    newBusinessReducer,
    brandsReducer
});

export default rootReducer;