import * as actionTypes from '../../types/newbusiness';

const initialState = {
    newBusiness: [],
};

const newBusinessReducer = (state = initialState, action) => {
    switch(action.type) {
        case actionTypes.GET_NEW_BUSINESSES:
            return { ...state, newBusiness: action.payload};
        default:
            return state;
    }
}

export default newBusinessReducer;