import React from 'react';
import onClickOutside from 'react-onclickoutside';
import cancel from '../../assets/images/cancel-circle.png';
import { withStyles } from "@material-ui/core/styles";
import arrow from '../../assets/images/arrowDown.png';
import search from '../../assets/images/search.png'
import { PLACEHOLDER_COLOR, BTN_COLOR_THEME } from '../../utils/CssConstants';


const styles = theme => ({
  selectContainer: {
    // backgroundColor: 'red',
    width: '100%',
  },
  selectionContainer: {
    width: '100%',
    display: 'flex',
    border: '1px solid #A7A5A5',
    borderRadius: 5,
    padding: '0px 13px',
    backgroundColor: 'white',
    minHeight: 44

  },
  openIcon:{
    display:'flex',
    alignItems:'center'
  },
  selectedListItemsContainer: {
    flex: 1,
    display: 'flex',
    flexWrap: 'wrap',
  
  },
  selectedItem:{
    backgroundColor: BTN_COLOR_THEME,
    borderRadius:'12px',
    display:'flex',
    alignItems:'center',
    margin: '6px 0px',
    marginRight:10,
    padding:'7px 5px'
    
  },
  selectionName:{
    fontSize:'10px',
    fontWeight:400,
    fontFamily: 'Poppins',
    color: 'white'
  },
  removeBtn:{
    backgroundColor:'transparent',
    border:'none',
    outline:'none',
    padding:0,
    borderLeft: '0.5px solid white',
    height: '80%',
    // height:'27px',
    display: 'flex',
    alignItems: 'center',
    marginLeft:'3px',
    paddingLeft:'3px'
  },
  mainPlaceholder: {
    fontFamily: 'Poppins',
    fontSize: 9,
    letterSpacing: '5%',
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    color: PLACEHOLDER_COLOR,
    padding: '9px 13px',
  },
  listContainer: {
    padding: 0,
    margin: 0,
    listStyle: 'none',
    borderRadius: 5,
    boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
    backgroundColor: 'white',
    paddingBottom: 5,
    height: '167px',
    overflow: 'auto',
    position:'absolute',
    top:0, left:0,
    width:'100%',
    '&::-webkit-scrollbar': {
      height: 0,
      width: 0
    }
  },
  listItem: {
    padding: '3px 21px',
    fontWeight: 400,
    fontSize: '13px',
    fontFamily: 'Poppins',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: 'rgba(196, 196, 196, 0.4)'
    },

  },
  selectedListItem: {
    padding: '3px 21px',
    fontWeight: 400,
    fontSize: '13px',
    fontFamily: 'Poppins',
    cursor: 'pointer',
    backgroundColor: 'rgba(196, 196, 196, 0.4)',
    borderLeft: '2px solid #1549d6',
  },
  searchField: {
    width: '100%',
    flex:1,
    border: '1px solid #1549d6',
    borderTopLeftRadius: '5px',
    borderTopRightRadius: '5px',
    height: '33px',
    outline: 'none',
    padding: '3px 21px',
    fontWeight: 400,
    fontSize: '14px',
    fontFamily: 'Poppins',
    '&::placeholder': {
      color: PLACEHOLDER_COLOR,
      fontSize: '12px',
    }
  },
  searchedText: {
    color: PLACEHOLDER_COLOR,
  },
 
});

class SelectField extends React.Component{

  constructor(props) {
    super(props);
    this.state = {
      open: false,
      selection: [],
      inputText: '',
      filterList: [],
    };
  }

  componentDidUpdate(prevProps) {
    if (this.props !== prevProps) {
      this.updateState(this.props);
    }
  }
  componentDidMount() {
    this.updateState(this.props);
  }

  updateState = (data) => {
    this.setState({
      placeholder: data.placeholder,
      filterList: data.options,
      selection: data.selectedValues,
    });
  };


  toggle = () => this.setState({ open: !this.state.open });

  handleClickOutside = () => {
    //   debugger;
    this.setState({
      filterList: this.props.options,
      inputText: '',
      open: false,
    });
  };



 handleOnClick=(item)=> {
  //  debugger;

  if(this.props.selectionLimit){
    if (!this.state.selection.some((current) => current.id === item.id)) {
      if(this.state.selection.length<this.props.selectionLimit){
        this.setState({
          selection: [...this.state.selection, item],
        });
        this.props.onSelect([...this.state.selection, item], item);
        if(this.props.selectionLimit === 1) this.setState({ open: false });
      }else{
        return;
      }
    } else {
      let selectionAfterRemoval = this.state.selection;
      selectionAfterRemoval = selectionAfterRemoval.filter(
        current => current.id !== item.id
      );
      this.setState({
        selection: [...selectionAfterRemoval],
      });
     this.props.onRemove(selectionAfterRemoval, item);
    }
  }else{
    if (!this.state.selection.some((current) => current.id === item.id)) {
      this.setState({
        selection: [...this.state.selection, item],
      });
      this.props.onSelect([...this.state.selection, item], item);
    } else {
      let selectionAfterRemoval = this.state.selection;
      selectionAfterRemoval = selectionAfterRemoval.filter(
        current => current.id !== item.id
      );
      this.setState({
        selection: [...selectionAfterRemoval],
      });
     this.props.onRemove(selectionAfterRemoval, item);
    }
  }

  }

  removeItem=(item)=> {
    let selectionAfterRemoval = this.state.selection;
    selectionAfterRemoval = selectionAfterRemoval.filter(
      current => current.id !== item.id
    );
    this.setState({
      selection: [...selectionAfterRemoval],
    });
   this.props.onRemove(selectionAfterRemoval, item);

  }

  isItemInSelection=(item)=> {
    if (this.state.selection.some((current) => current.id === item.id)) {
      return true;
    }
    return false;
  }

  filterItems=(value)=> {
    let arr = [];
    if (value === '') {
      this.setState({ filterList: this.props.options });
    } else {
      arr =  this.props.options.filter((item) => {
        return item.value.toString().toLowerCase().includes(value.toLowerCase())
      })
      this.setState({ filterList: arr });
    }
    this.setState({ inputText: value });
  }

  formatingText=(item)=>{
    const {classes}=this.props;
    let idx = item.toLowerCase().indexOf(this.state.inputText.toLowerCase());
    if(idx>=0){
      return <span className={classes.itemName}>
              {item.substring(0, idx)}
              <span className={classes.searchedText}>{item.substring(idx, idx + this.state.inputText.length)}</span>
              {item.substring(idx + this.state.inputText.length)}
            </span>
    }
    return <span className={classes.itemName}>{item}</span>;
  }

render(){
  const { disable, selection, inputText, open, filterList } = this.state;
  const {placeholder, classes } = this.props;
  return (
    <div className={classes.selectContainer}>
      <div id='select-box' className={classes.selectionContainer} onClick={() => this.toggle()}>
        {selection.length > 0 ?
          <div className={classes.selectedListItemsContainer}>
            {selection.map(item => (
              <div className={classes.selectedItem} key={item.id} >
                <span className={classes.selectionName} id="selected-item">{item.value}</span>
                {/* <Divider orientation="vertical"   variant='middle'/> */}
                <button className={classes.removeBtn} onClick={(e) => {
                  if (!disable) {
                    e.stopPropagation();
                    this.removeItem(item);
                  }
                }} id="item-remove-button">
                  <img src={cancel} width={5} height={5} alt="cancel" />
                </button>
              </div>
            ))}
          </div>
          : <span className={classes.mainPlaceholder} id="select-box-placeholder">{placeholder}</span>
        }
        {!open &&
          <div className={classes.openIcon} id="select-box-dropdown-btn">
            <img src={arrow} width={12} alt="open" />
          </div>
        }
      </div>

      {open && (
        <div style={{position:'relative',top:0, left:0, width:'100%'}}>
        <ul className={classes.listContainer}>
          <li style={{position:'relative',top:0, left:0}}>
            <input
              className={classes.searchField}
              placeholder={'Search'}
              type='text'
              value={inputText}
              onChange={(e) => { this.filterItems(e.target.value); }}
              id="select-box-search"
            />
            <img src={search} width={18} heigth={21} style={{position:'absolute', top:8, right:11}} alt="search" id="select-box-search-img"/>
          </li>
          {
            filterList.map(item => (
              <li onClick={() => this.handleOnClick(item)} className={this.isItemInSelection(item) ? classes.selectedListItem : classes.listItem} key={item.id} id="select-box-listItem">
                  {this.formatingText(item.value)}
              </li>
            ))
          }
          {this.props.selectionLimit !== 1 &&
            <li className={classes.listItem} onClick={() => this.props.onAddingSubCat(inputText)}>Add New Service</li>
          }
        </ul>
          </div>
      )}
    </div>
  );
}
}
SelectField.defaultProps = {
  placeholder: '',
  options: [],
  selectedValues: [],
  onSelect: () => {},
  onRemove: () => {},
};

export default withStyles(styles)(onClickOutside(SelectField));
