import axios from 'axios';

/**
 * @description Get API to fetch post history
 * @async
 * @function GetPostHistoryAPI
 * @returns Promise
**/


const GetPostHistoryAPI = async (page, size, search = null, brandId = null) => {
  
    try {

      let apiUrl = process.env.REACT_APP_API_URL + "/posts/history-details?page="+ page + "&size=" + size;
      if(search !== null && search !== '') apiUrl = apiUrl + "&search=" + search.trim().replace('+', '%2B');
      if(brandId !== null) apiUrl = apiUrl + '&brandId=' + brandId;
      const config = {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('access-token')
        } 
      }

      const response = await axios.get(apiUrl, config)
      return response.data
  
    } catch (e) {
      return e
    }
}

export default GetPostHistoryAPI;
  