import axios from 'axios';

/**
 * @description POST API to refresh token of user
 * @async
 * @function PostRefreshTokenAPI
 * @returns Promise
**/


const PostRefreshTokenAPI = async () => {
  
    try {

        let apiUrl = process.env.REACT_APP_API_URL + "/refresh/token";

        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('access-token'),
            } 
        }

        const response = await axios.post(apiUrl, { access: "renew"}, config);
        return response;
        
    } catch (e) {
      return e;
    }
}

export default PostRefreshTokenAPI;
  