import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Button from '../../../../Custom/Button';
import { WHITE_BG, FIELD_INCORRECT_DATA_VALIDATION } from '../../../../../utils/CssConstants';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


const styles = {
    confirmationModalCont: {
        width: '100%',
        height: '100%',
        top: 0,
        left: 0,    
        position: 'fixed',
        zIndex: 999,
        alignItems: 'center',
        justifyContent: 'center',
        background: 'rgba(196, 196, 196, 0.6)',
        display: 'flex'
    },
    confirmationModal: {
        background: WHITE_BG,
        boxShadow: '0px 5px 15px rgba(0, 0, 0, 0.2)',
        borderRadius: 15,
        width: '50%',
        padding: 20,
        maxHeight: 435,
        overflowY: 'auto'
    },
    confirmationModalTitle: {
        marginTop: 10,
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 30,
        textAlign: 'center',
        color: "#FF0000"
    },
    confirmationModalContent: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 20,
        textAlign: 'center',
    },
    rowsMsgs: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 'normal',
        textDecoration: 'none',
        fontSize: 16,
    },
    confirmationModalBtns: {
        marginTop: 40,
        display: 'flex',
        justifyContent: 'space-around'
    },
    yesBtn: {
        background: "#37B1C8",
        borderRadius: 10,
        color: WHITE_BG,
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 20,
        border: 'none',
        outline: 'none',
        width: '45%',
        height: 75
    },
    noBtn: {
        background: "#A82828",
        borderRadius: 10,
        color: WHITE_BG,
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 20,
        border: 'none',
        outline: 'none',
        width: '45%',
        height: 75  
    },
    fieldError: FIELD_INCORRECT_DATA_VALIDATION,
    btnLoading: {
        color: "#FFF"
    }
};

const CSVStatusModal = ({ classes, csvResponse, closeModal }) => {

    return (
        <>
            <div className={classes.confirmationModalCont}>
                <div className={classes.confirmationModal}>
                    <div className={classes.confirmationModalTitle}>CSV File Status</div>
                    <br />  
                    <div style={{ display: 'flex', flexDirection: 'column', padding: '0 20px' }}>
                        <div className={classes.confirmationModalContent} style={{ textAlign: 'left' }}>No of rows successful: {csvResponse.data.success_rows} </div>   
                        <div className={classes.confirmationModalContent} style={{ textAlign: 'left' }}>No of rows failed: {csvResponse.data.un_success_rows}</div>
                        <div className={classes.confirmationModalContent} style={{ textAlign: 'left' }}>No of rows: {csvResponse.data.total_rows}</div>
                        <br />   

                        {csvResponse.data.un_seccess_msg.map((rowError, index) => {
                            return (
                                <>
                                    <div className={classes.confirmationModalContent} style={{ textAlign: 'left', marginBottom: 10}} key={index}>
                                        <span style={{ textDecoration: 'underline', fontWeight: 600 }}>Row: {rowError.row}</span>
                                        {rowError.messages.map((message, index) => {
                                            return (
                                                <div className={classes.rowsMsgs} key={index}>{message}</div>
                                            );
                                        })}
                                    </div>
                                </>   
                            );           
                        })}

                        <div className={classes.confirmationModalContent} style={{ textAlign: 'left', marginTop: 20, fontWeight: 600 }}>Please check our sample file and follow exact format for all un-successful rows.</div>   
                    </div>
                    <div className={classes.confirmationModalBtns}>
                        <Button className={classes.noBtn} name={"Okay"} onClick={() => closeModal()} />
                    </div>
                </div>
            </div>

            <ToastContainer theme='colored' />
        </>
    )
}

export default withStyles(styles)(CSVStatusModal);