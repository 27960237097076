import axios from 'axios';

/**
 * @description Get API to update campaign status
 * @async
 * @function GetToggleStatusAPI
 * @returns Promise
**/


const PutToggleStatusAPI = async (id) => {

    let apiUrl = process.env.REACT_APP_API_URL + "/campaign/toggle-status?campaignId=" + id;

    const body = {
        "campaignId": id,
    };

    const config = {
        headers: {
            Authorization: 'Bearer ' + localStorage.getItem('access-token')
        } 
    }

    const response = await axios.put(apiUrl, body, config)
    return response.data
}

export default PutToggleStatusAPI;
  