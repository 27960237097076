export const styles = (theme) => ({
    cropContainer: {
      position: 'relative',
      width: '100%',
      height: 200,
      background: '#333',
      [theme.breakpoints.up('sm')]: {
        height: 400,
      },
    },
    cropButton: {
      flexShrink: 0,
      marginLeft: 16,
      color: '#FFF',
      width: '23%',
      border: `1px solid #1549d6`,
      height: 40,
      outline: 'none',
      fontSize: 18,
      background: '#1549d6',
      boxSizing: 'border-box',
      fontStyle: 'normal',
      fontFamily: 'Poppins',
      fontWeight: 'normal',
      borderRadius: 20,
      textTransform: 'capitalize',
      '&:hover': {
        backgroundColor: '#0838bb'
      },
    },
    controls: {
      padding: 16,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'stretch',
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
        alignItems: 'center',
      },
    },
    sliderContainer: {
      display: 'flex',
      flex: '1',
      alignItems: 'center',
    },
    sliderLabel: {
      [theme.breakpoints.down('xs')]: {
        minWidth: 65,
      },
    },
    slider: {
      padding: '22px 0px',
      marginLeft: 32,
      color: '#1549d6',
      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
        alignItems: 'center',
        margin: '0 16px',
      },
    },
  })
  