import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from "@material-ui/core/styles";
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faScroll, faUser, faGift, faFileLines, faAddressCard } from '@fortawesome/free-solid-svg-icons';
import Logout from '../../assets/images/logout.png';
import Path12 from '../../assets/images/Path12.png';
import Path12Active from '../../assets/images/Path12_activeBlue.png';
import Path10 from '../../assets/images/Path10.png';
import Path10Active from '../../assets/images/Path10_activeBlue.png';
import { logout } from '../../store/actions/Auth/authActions';
import { resetCreateCampaign } from '../../store/actions/Campaign/createCampaignActions';
import Helpers from '../../utils/Helpers';
import "../../assets/css/style.css";

const styles = {
    sidebarItems: {
        cursor: 'pointer',
        width: '25px !important',
        height: '25px !important',
    },
};


const Sidebar = ({ classes }) => {

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const userType = useSelector((state) => state.authReducer.userDetails.type_id);

    const handleLogout = () => {
        let defaultState = Helpers.returnDefaultState();
        dispatch(logout({}));
        dispatch(resetCreateCampaign(defaultState.initialBillingState, defaultState.initialCampaignState, defaultState.initialProgressState, defaultState.previousCards));
        if (userType == 1805) {
            navigate('/agency-login');
        } else {
            navigate('/');
        }
    }
    return (
        <div className="sidebar">
            <div className="baritems flex-column d-flex">
                <div className={location.pathname === '/dashboard' ? "first-item" : "seconditem"}>
                    <Link to="/dashboard">
                        <img className={classes.sidebarItems} id="dashboard-icon" src={location.pathname === '/dashboard' ? Path12Active : Path12} width="25px" alt="" />
                    </Link>
                </div>
                {/* {userType !== 1801 &&  
                    <div className={location.pathname === '/new-business'  ? "first-item" : "seconditem"}>
                        <Link to="/new-business">
                            <FontAwesomeIcon icon={faBriefcase} className={classes.sidebarItems} color={location.pathname === '/new-business' ? '#1549d6' : '#FFFFFF'} id="new-business-icon" alt=""  />
                        </Link>
                    </div>
                } */}
                {userType !== 1805 &&
                    <div className={location.pathname === '/users' ? "first-item" : "seconditem"}>
                        <Link to="/users">
                            <FontAwesomeIcon icon={faUser} className={classes.sidebarItems} color={location.pathname === '/users' ? '#1549d6' : '#FFFFFF'} id="users-icon" alt="" />
                        </Link>
                    </div>
                }
                {userType !== 1805 &&
                    <div className={location.pathname === '/post-history' ? "first-item" : "seconditem"}>
                        <Link to="/post-history">
                            <FontAwesomeIcon icon={faScroll} className={classes.sidebarItems} color={location.pathname === '/post-history' ? '#1549d6' : '#FFFFFF'} id="scroll-icon" alt="" />
                        </Link>
                    </div>
                }
                {/* <div className="seconditem">
                    <img className={classes.sidebarItems} src={Archive} width="25px" alt="" />
                </div>
                <div className="seconditem">
                    <img className={classes.sidebarItems} src={BXSReports} width="25px" alt="" />
                </div> */}
                {userType !== 1805 &&
                    <div className={location.pathname === '/campaigns' ? "first-item" : "seconditem"}>
                        <Link to="/campaigns">
                            <img className={classes.sidebarItems} id="campaign-icon" src={location.pathname === '/campaigns' ? Path10Active : Path10} width="25px" alt="" />
                        </Link>
                    </div>
                }
                {(userType === 1802 || userType === 1806) &&
                    <div className={location.pathname === '/coupon' ? "first-item" : "seconditem"}>
                        <Link to="/coupon">
                            <FontAwesomeIcon icon={faGift} className={classes.sidebarItems} color={location.pathname === '/coupon' ? '#1549d6' : '#FFFFFF'} id="scroll-icon" alt="" />
                        </Link>
                    </div>
                }



                {(userType === 1802 || userType === 1801 || userType === 1806) && (
                    <div className={location.pathname === '/brand-summary' ? "first-item" : "seconditem"}>
                        <Link to="/brand-summary">
                            <FontAwesomeIcon icon={faFileLines} className={classes.sidebarItems} color={location.pathname === '/brand-summary' ? '#1549d6' : '#FFFFFF'} id="scroll-icon" alt="" />
                        </Link>
                    </div>
                )}

                {(userType === 1802 || userType === 1806) && (
                    <div className={location.pathname === '/brands' ? "first-item" : "seconditem"}>
                        <Link to="/brands">
                            <FontAwesomeIcon icon={faAddressCard} className={classes.sidebarItems} color={location.pathname === '/brands' ? '#1549d6' : '#FFFFFF'} id="scroll-icon" alt="" />
                        </Link>
                    </div>
                )}

                <div className="seconditem" onClick={handleLogout}>
                    <img className={classes.sidebarItems} id="logout-icon" src={Logout} width="25px" alt="" />
                </div>
            </div>
        </div>
    );
};

export default withStyles(styles)(Sidebar);