import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { FORM_INPUT_STYLES, PRIMARY_COLOR, WHITE_BG, CAMPAIGN_FORM_STEPS_HEADING, FORM_GROUP, FORM_INPUT_CONTAINER_STYLES, FIELD_INCORRECT_DATA_VALIDATION, CAMPAIGN_FORM_STEPS_LABEL, INVALID_FORM_VALIDATION } from '../../../../utils/CssConstants';
import Validate from "../../../../utils/Validation";
import Button from '../../../Custom/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle, faCheckCircle } from '@fortawesome/free-solid-svg-icons';
import CancelIcon from '@material-ui/icons/Cancel';
import CircularProgress from '@material-ui/core/CircularProgress';
import { insertBillingDetails, insertProgressDetails, insertCampaignDetails } from '../../../../store/actions/Campaign/createCampaignActions';
import PostCardValidateAPI from '../../../../services/Payment/PostCardValidateAPI';
import PostValidateCouponAPI from '../../../../services/Campaign/CreateCampaign/PostValidateCouponAPI';
import { ToastContainer, toast } from 'react-toastify';
import Helpers from '../../../../utils/Helpers';
import 'react-toastify/dist/ReactToastify.css';


const styles = theme => ({
    billingFormTop: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        height: 'auto'
    },
    billingFormRight: {
        width: '70%',
        display: 'flex',
        flexDirection: 'column',
        height: 'auto',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    formGroup: FORM_GROUP,
    formInputContStyles: FORM_INPUT_CONTAINER_STYLES,
    formInputStyles: FORM_INPUT_STYLES,
    formHeadingStyles: CAMPAIGN_FORM_STEPS_HEADING,
    formInputLabels: CAMPAIGN_FORM_STEPS_LABEL,
    invalidFormInputs: INVALID_FORM_VALIDATION,
    fieldError: FIELD_INCORRECT_DATA_VALIDATION,
    formLabelsResponsive: {
        width: '45%',
        [theme.breakpoints.down('sm')]: {
            width: '40%',
            fontSize: '0.90rem'
        }        
    },
    paymentMethods: {
        display: 'flex',
        flexWrap: 'wrap'
    },
    cardCheck: {
        width: '25%',
        borderLeft: '1px solid #B7B7B7',
        borderRight: 'none',
        justifyContent: 'center',
        alignItems: 'center',
        paddingRight: 0
    },
    paymentMethodImages: {
        marginRight: 15,
        maxWidth: 140,
        maxHeight: 80,
        objectFit: 'cover',
        boxSizing: 'border-box',
        borderRadius: 10
    },
    billingFormLeft: {
        width: '40%',
        display: 'flex',
        flexDirection: 'column',
        padding: '30px 0px 0px 40px',
    },
    cardDates: {
        display: 'flex',
        justifyContent: 'space-between',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    cardDateLabel: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 20,
        [theme.breakpoints.down('sm')]: {
            fontSize:'0.90rem'
        }
    },
    createCampaignBottom: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    validThruCont: {
        marginBottom: 10,
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    cardDateInputs: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'end',
        [theme.breakpoints.down('sm')]: {
            alignItems: 'start'
        }
    },
    formSubmit: {
        background: PRIMARY_COLOR,
        color: WHITE_BG,
        border: 'none',
        borderRadius: 10,
        height: 60,
        width: '20%',
        marginTop: 125,
        outline: 'none',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 18,
        [theme.breakpoints.down('sm')]: {
            width: '30%',
        }
    },
    applyBtn: {
        width: '20%',
        marginRight: -10,
        marginTop: 25,
        [theme.breakpoints.down('sm')]: {
            width: '40%',
        }

    },
    btnLoading: {
        color: "#FFF !important"
    },
    couponInputCont: {
        display: 'flex', 
        justifyContent: 'space-between', 
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column'
        }
    },
    couponDetails: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 18,
        marginBottom: 0,
        display: 'flex',
        justifyContent: 'space-between',
        width: '50%',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
    crossIcon: {
        cursor: 'pointer',
        color: '#CECECE',
        margin: 'auto auto'
    },
    couponResponsiveLabel: {
        width: '35%',
        [theme.breakpoints.down('sm')]: {
            width: '40%',
            fontSize: '0.90rem'
        }
    },
    cardNumberResponsive: {
        border: '1px solid #B7B7B7', 
        borderRadius: 20, 
        width: '25%', 
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            width: '50%'
        }
    },
    cvv: {
        width: '55%',
        [theme.breakpoints.down('sm')]: {
            width: '75%'
        }
    }
});

const Billing = ({ classes, step, setStep, prevStep, setBillingComplete, setReviewComplete }) => {

    const billingDetails = useSelector((state) => state.createCampaignReducer.billingDetails); 
    const campaignDetails = useSelector((state) => state.createCampaignReducer.campaignDetails); 
    const previousCards = useSelector((state) => state.createCampaignReducer.previousCards); 
    const dispatch = useDispatch();

    const [duplicateCardError, setDuplicateCardError] = useState(false); 
    const [paymentMode, setPaymentMode] = useState(billingDetails.paymentMode);
    const [selectedPreviousCard, setSelectedPreviousCard] = useState(billingDetails.selectedPreviousCard);
    const [nameOnCard, setNameOnCard] = useState(billingDetails.nameOnCard);
    const [nameOnCardError, setNameOnCardError] = useState(false);
    const [billingAddress, setBillingAddress] = useState(billingDetails.billingAddress);
    const [billingAddressError, setBillingAddressError] = useState(false);
    const [cardNumber, setCardNumber] = useState(billingDetails.cardNumber);
    const [cardNumberError, setCardNumberError] = useState(false);
    const [expiryMonth, setExpiryMonth] = useState(billingDetails.expiryMonth);
    const [expiryMonthError, setExpiryMonthError] = useState(false);
    const [expiryYear, setExpiryYear] = useState(billingDetails.expiryYear);
    const [expiryYearError, setExpiryYearError] = useState(false);
    const [cvv, setCvv] = useState(billingDetails.cvv); 
    const [cvvError, setCvvError] = useState(false);
    const [coupon, setCoupon] = useState('');
    const [couponError, setCouponError] = useState(false);
    const [couponValid, setCouponValid] = useState(false);
    const [couponDetails, setCouponDetails] = useState(null);
    const [btnLoading, setBtnLoading] = useState(false);

    const handlePaymentMode = (cardDetails) => {
        setPaymentMode("previousCards");
        setSelectedPreviousCard(cardDetails);
    }

    const emptyCoupon = () => {
        setCoupon('') 
        setCouponDetails(null)
        setCouponValid(false)
        setCouponError(false)
    }

    const maskCardNumber = (cardNumber) => {
        let val = cardNumber;        
        let newval = '';         
        val = val.replace(/\s/g, ''); 
        
        // iterate to letter-spacing after every 4 digits   
        for(var i = 0; i < val.length; i++) {             
            if(i % 4 === 0 && i > 0) newval = newval.concat(' ');             
            newval = newval.concat(val[i]);         
        }        

        setCardNumber(newval);
    }

    const handleNameOnCard = (text) => {
        const re = /^[a-zA-Z ]+$/;    
        if (text === '' || re.test(text)) {
            setNameOnCard(text);
        }
    }

    const handleCoupon = (text) => {
        if (text.length < 8) {
            setCoupon(text);
        }
    }

    const applyCoupon = () => {
        setCouponError(false);
        if(!coupon) {
            setCouponError('Coupon cannot be empty');
            return;
        }
        PostValidateCouponAPI(coupon, campaignDetails.payoutAmount).then(res => {
            if(res.status === 'SUCCESS') {
                setCouponDetails({
                    coupon_amount: res.data.coupon_amount,
                    campaign_amount: res.data.campaign_amount,
                    campaign_payable_amount: res.data.campaign_payable_amount,
                    coupon_remaining_amount: res.data.coupon_remaining_amount,
                    coupon_amount_used: res.data.coupon_amount_used,
                    effective_coupon_amount: res.data.effective_coupon_amount,
                    coupon_id: res.data.coupon_id   
                });
                setCouponValid(true);
            } else {
                toast.error(res.response.data.error)
            }
        }).catch(err => console.log(err.response.data.error))
    }

    const validateForm = () => {
        if(paymentMode === 'previousCards') {
            return true;
        }
        setCouponError(false);
        setNameOnCardError(false);
        setBillingAddressError(false);
        setCardNumberError(false);
        setExpiryMonthError(false);
        setExpiryYearError(false);
        setCvvError(false);
        setDuplicateCardError(false);

        let cardNumberStripped = cardNumber.replace(/ /g,'');
        let cardNumberlast4 = cardNumberStripped;
        cardNumberlast4 = cardNumberlast4.substring(cardNumberlast4.length - 4);
       
        let nameOnCardError = false, billingAddressError = false, cardNumberError = false, duplicateCardError = false, expiryMonthError = false, expiryYearError = false, cvvError = false;

        if(Validate.notNullValidation(nameOnCard) === false || Validate.textLengthValidation(nameOnCard, 20) === false) {
            nameOnCardError = true;
        } else {
            nameOnCardError = false;
        }

        if(Validate.notNullValidation(billingAddress) === false || Validate.fieldLengthValidation(billingAddress, 255) === false) {
            billingAddressError = true;
        } else {
            billingAddressError = false;
        }

        if (Validate.notNullValidation(cardNumberStripped) === false || Validate.cardNumberValidation(cardNumberStripped) === false) {
            cardNumberError = true;
        } else {
            cardNumberError = false;
            let matches = 0;
            if(previousCards.length > 0) {
                previousCards.map((card) => {
                    if(card.last_4_character === cardNumberlast4) {
                        matches += 1;
                    } else {
                        duplicateCardError = false;
                    }
                });
                if(matches > 0) duplicateCardError = true;
            }
        }
        if(Validate.notNullValidation(expiryMonth) === false || Validate.numberLengthValidation(expiryMonth, 2) === false || Validate.cardExpiryMonth(expiryMonth, expiryYear) === false) {
            expiryMonthError = true;
        } else {
            expiryMonthError = false;
        }

        if(Validate.notNullValidation(expiryYear) === false || Validate.numberLengthValidation(expiryYear, 2) === false || Validate.cardExpiryYear(expiryYear) === false) {
            expiryYearError = true;
        } else {
            expiryYearError = false;
        }

        if(Validate.notNullValidation(cvv) === false || Validate.numberLengthValidation(cvv, 3) === false) {
            cvvError = true;
        } else {
            cvvError = false;
        }

        setNameOnCardError(nameOnCardError);
        setBillingAddressError(billingAddressError);
        setCardNumberError(cardNumberError);
        setDuplicateCardError(duplicateCardError);
        setExpiryMonthError(expiryMonthError);
        setExpiryYearError(expiryYearError);
        setCvvError(cvvError);

        if(nameOnCardError || billingAddressError || cardNumberError || expiryMonthError || expiryYearError || cvvError || duplicateCardError) {
            return false;
        } else {
            return true;
        }

    }

    const handleSubmit = () => {
        let isValid = validateForm();
        if(isValid) {
            if(paymentMode === "newCard") {
                setBtnLoading(true);
                let billingDetails = {
                    paymentMode: paymentMode,
                    selectedPreviousCard: selectedPreviousCard,
                    nameOnCard: nameOnCard,
                    billingAddress: billingAddress,
                    cardNumber: cardNumber,
                    expiryMonth: expiryMonth,
                    expiryYear: expiryYear,
                    cvv: cvv,
                }
                PostCardValidateAPI({ billingDetails: billingDetails }).then(data => {
                    setBtnLoading(false);
                    toast.success(data.message);
                    dispatch(insertBillingDetails({
                            paymentMode: paymentMode,
                            selectedPreviousCard: selectedPreviousCard,
                            nameOnCard: nameOnCard,
                            billingAddress: billingAddress,
                            cardNumber: cardNumber,
                            expiryMonth: expiryMonth,
                            expiryYear: expiryYear,
                            cvv: cvv,
                            couponDetails: couponDetails
                    }));
                    setBillingComplete(true);
                    setReviewComplete(true);
                    dispatch(insertProgressDetails({
                        detailsComplete: true,
                        billingComplete: true,
                        reviewComplete: true,
                        step: step !== 3 ? step + 1 : step
                    }));
                    if(step !== 3) {
                        setStep(step + 1);
                    }
                    
                }).catch(err => {setBtnLoading(false); err.response && toast.error(err.response.data.error)});

            } else {
                dispatch(insertBillingDetails({
                        paymentMode: paymentMode,
                        selectedPreviousCard: selectedPreviousCard,
                        nameOnCard: nameOnCard,
                        billingAddress: billingAddress,
                        cardNumber: cardNumber,
                        expiryMonth: expiryMonth,
                        expiryYear: expiryYear,
                        cvv: cvv,
                        couponDetails: couponDetails
                }));
                setBillingComplete(true);
                setReviewComplete(true);
                dispatch(insertProgressDetails({
                    detailsComplete: true,
                    billingComplete: true,
                    reviewComplete: true,
                    step: step !== 3 ? step + 1 : step
                }));
                if(step !== 3) {
                    setStep(step + 1);
                }
            }
            // if(couponValid) {
            //     dispatch(insertCampaignDetails({
            //         ...campaignDetails,
            //         payoutAmount: couponDetails.campaign_net_amount <= 0 ? 0 : couponDetails.campaign_net_amount
            //     }))
            // }
        }
    }

    return (
        <>
            <div className={classes.billingFormTop}>
                <div className={classes.billingFormRight}>
                    {(previousCards.length > 0) && previousCards.map((card, index) => 
                        <div className={classes.formGroup} style={{ marginTop: 15 }} key={index}>
                            <div className={classes.formInputContStyles} style={{ marginTop: 0, marginBottom: 0 }}>
                                <label htmlFor="previous-card" className={`${classes.formInputLabels} ${classes.formLabelsResponsive}`} id="previous-card-label">Previous Card</label>
                                <input className={classes.formInputStyles} style={{ width: '65%', cursor: 'pointer'}} id="previous-card" type="text" value={"**** **** **** " + card.last_4_character} readOnly={true}  />
                                <label className={`${classes.formInputLabels} ${classes.cardCheck}`} onClick={(couponValid && couponDetails.campaign_amount === 0) ? '' : () => handlePaymentMode(card)}><FontAwesomeIcon id="circle-icon" icon={selectedPreviousCard === card ? faCheckCircle : faCircle} color={selectedPreviousCard === card ? PRIMARY_COLOR : "#797979"} /></label>
                            </div>
                        </div>
                    )}
                    
                                   
                    {/* <div className={classes.formGroup} style={{margin:'0 80px', marginTop: 30}}>
                        <div className={`${classes.formInputContStyles} ${previousCardError && classes.invalidFormInputs}`}>
                            <label htmlFor="previous_card" className={classes.formInputLabels} style={{width: '45%'}}>Previous Card</label>
                            <input className={classes.formInputStyles} style={{width: '65%'}} id="previous_card" type="text" value={previousCard} onChange={e => handlePreviousCard(e.target.value)} />
                            <label className={`${classes.formInputLabels} ${classes.cardCheck}`}><FontAwesomeIcon icon={faCircle} color={"#797979"} /></label>
                        </div>
                    </div> */}
                    <div className={classes.formGroup}>
                        <div className={classes.formInputContStyles}>
                            <label htmlFor="new-card" className={`${classes.formInputLabels} ${classes.formLabelsResponsive}`} id="new-card-label">New Card</label>
                            <input className={classes.formInputStyles} style={{ width: '65%', cursor: 'pointer' }} id="new-card" type="text" value="**** **** **** **** "  readOnly={true} />
                            <label className={`${classes.formInputLabels} ${classes.cardCheck}`} onClick={() => {setPaymentMode("newCard"); setSelectedPreviousCard("")}}><FontAwesomeIcon id="circle-icon" icon={paymentMode === "newCard" ? faCheckCircle : faCircle} color={paymentMode === "newCard" ? PRIMARY_COLOR : "#797979"} /></label>
                        </div>
                    </div>

                    {paymentMode === "newCard" &&
                        <div>
                            {/* <div className={classes.formGroup} style={{ margin:'0 80px', marginTop: 30, marginBottom: 40}}>
                                <h4 className={classes.formHeadingStyles} style={{ fontWeight: 'normal', paddingLeft: 0 }}>We Accept</h4>
                                <div className={classes.paymentMethods}>
                                    <img src={AmericanExpress} className={classes.paymentMethodImages} alt=""/>
                                    <img src={Paypal} className={classes.paymentMethodImages} alt=""/>
                                    <img src={Visa} className={classes.paymentMethodImages} alt=""/>
                                    <img src={MasterCard} className={classes.paymentMethodImages} alt=""/>
                                </div>
                            </div> */}
                        
                            <div className={classes.formGroup}>
                                <h3 className={classes.formHeadingStyles} style={{ paddingLeft: 0 }} id="payment-txt">Your payment information:</h3>
                                <div className={`${classes.formInputContStyles} ${nameOnCardError && classes.invalidFormInputs}`}>
                                    <label htmlFor="name-on-card" className={`${classes.formInputLabels} ${classes.formLabelsResponsive}`} id="name-on-card-label">Name</label>
                                    <input className={classes.formInputStyles} style={{width: '90%'}} id="name-on-card" type="text" value={nameOnCard} onChange={e => handleNameOnCard(e.target.value)} />
                                </div>
                                {nameOnCardError && <span className={classes.fieldError}>Card Holder Name is invalid, it should be less than 20 characters and should not contain numbers</span>}

                                <div className={`${classes.formInputContStyles} ${cardNumberError && classes.invalidFormInputs}`}>
                                    <label htmlFor="card-number" className={`${classes.formInputLabels} ${classes.formLabelsResponsive}`} id="card-number-label">Card Number</label>
                                    <input className={classes.formInputStyles} style={{ width: '65%' }} id="card-number" type="text" maxLength={19} value={cardNumber} onKeyUp={e => maskCardNumber(e.target.value)} onChange={e => setCardNumber(e.target.value)} />
                                    {/* <label className={`${classes.formInputLabels} ${classes.cardCheck}`} style={{border: 'none'}}><img src={(cardNumber.charAt(0) === '4') ? Visa : (cardNumber.charAt(0) === '5') ? MasterCard : (cardNumber.substring(0, 2) === '37' || cardNumber.substring(0, 2) === '34') ? AmericanExpress : ""} className={classes.paymentMethodImages} style={{border: '1px solid black', borderRadius: 0, maxWidth: "80px", maxHeight: "45"}} alt="" /></label> */}
                                </div>
                                {cardNumberError && <span className={classes.fieldError}>Card Number is invalid</span>}
                                {duplicateCardError && <span className={classes.fieldError}>This card already exists in our system</span>}

                                <div className={`${classes.formInputContStyles} ${billingAddressError && classes.invalidFormInputs}`}>
                                    <label htmlFor="billing-address" className={`${classes.formInputLabels} ${classes.formLabelsResponsive}`} id="billing-address-label">Billing Address</label>
                                    <input className={classes.formInputStyles} style={{ width: '65%' }} id="billing-address" type="text" value={billingAddress} onChange={e => setBillingAddress(e.target.value)} />
                                </div>
                                {billingAddressError && <span className={classes.fieldError}>Billing address is invalid</span>}

                            </div>

                            <div className={`${classes.formGroup} ${classes.cardDates}`}>
                                <div className={classes.validThruCont}>
                                    <label className={classes.cardDateLabel} style={{ textTransform: 'uppercase' }} id="valid-label">Valid Thru:</label>
                                    <div style={{ display: 'flex' }}>
                                        <input className={`${classes.formInputStyles} ${classes.cardNumberResponsive} ${expiryMonthError && classes.invalidFormInputs}`} id="expiry-month" type="text" value={expiryMonth} placeholder="MM" onChange={e => setExpiryMonth(e.target.value)} maxLength={2} />
                                        <span style={{ fontFamily: 'Poppins', fontStyle: 'normal', fontWeight: 'normal', fontSize: 48, margin: '0 15px' }} id="slash">/</span>
                                        <input className={`${classes.formInputStyles} ${classes.cardNumberResponsive} ${expiryYearError && classes.invalidFormInputs}`} id="expiry-year" type="text" value={expiryYear} placeholder="YY" onChange={e => setExpiryYear(e.target.value)} maxLength={2} />
                                    </div>
                                    {expiryMonthError && <span className={classes.fieldError} style={{marginTop: 10}} id="invalidMonth-error-message">Expiry Month is invalid</span>}
                                    {expiryYearError && <span className={classes.fieldError} style={{marginTop: 10}} id="invalidYear-error-message">Expiry Year is invalid</span>}    
                                </div>
                                
                                <div className={classes.cardDateInputs}>
                                    <label className={classes.cardDateLabel} id="security-label">Security Code:</label>
                                    {/* <div> */}
                                        <input className={`${classes.formInputStyles} ${classes.cardNumberResponsive} ${classes.cvv} ${cvvError && classes.invalidFormInputs}`} type="text" id="cvv" value={cvv} placeholder="CVV" onChange={e => setCvv(e.target.value)} maxLength={3} />
                                    {/* </div> */}
                                    {cvvError && <span className={classes.fieldError} style={{marginTop: 10}} id="field-error-message">CVV is invalid</span>}
                                </div>                                
                            </div>
                        </div>
                    }

                    <hr style={{ color: '#797979', height: 1.5 }} />
                    <div className={`${classes.formGroup} ${classes.couponInputCont}`}>
                        <div style={{ width: '100%' }}>
                            <h3 className={classes.formHeadingStyles} style={{ paddingLeft: 0 }} id="coupon-heading">Apply Launch Code:</h3>
                            <div className={`${classes.formInputContStyles} ${couponError && classes.invalidFormInputs}`} style={{ width: '90%' }}>
                                <label htmlFor="coupon" className={`${classes.formInputLabels} ${classes.couponResponsiveLabel}`} id="coupon-label">Launch Code</label>
                                <input className={classes.formInputStyles} style={{ width: '85%', textTransform: 'uppercase' }} id="coupon" type="text" value={coupon} onChange={e => handleCoupon(e.target.value)} />
                                <CancelIcon color="primary" id="cancel-icon" className={classes.crossIcon} onClick={emptyCoupon} />
                            </div>
                            {couponError && <span className={classes.fieldError}>{couponError}</span>}
                        </div>
                        <button className={`${classes.formSubmit} ${classes.applyBtn}`} onClick={() => applyCoupon()}>Apply</button>                        
                    </div>

                    {couponValid ?
                        <div className={classes.formGroup} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginTop: 20}}>
                            <p className={classes.couponDetails}><strong>Total Price:</strong> &nbsp;<span style={{ textDecoration: 'line-through', color: '#FF0000' }}>{'$' + parseFloat(campaignDetails.payoutAmount).toFixed(2)}</span></p>
                            {/* <p className={classes.couponDetails}><strong>Price:</strong> &nbsp;<span style={{ textDecoration: 'line-through', color: '#FF0000' }}>{'$' + campaignDetails.noOfPosts * campaignDetails.perPost}</span></p> */}
                            <p className={classes.couponDetails}><strong>Coupon Applied:</strong> &nbsp; {'-$' + (couponDetails.effective_coupon_amount).toFixed(2)}</p>
                            {/* <p className={classes.couponDetails}><strong>Thumzup Commission:</strong> &nbsp;{'$' + thumzupCommision}</p> */}
                            <p className={classes.couponDetails}><strong>Transaction Fees:</strong> &nbsp;{'$' + Helpers.numberWithCommas(Helpers.calculateStripeCharge(couponDetails.campaign_payable_amount))}</p>
                            <p className={classes.couponDetails}><strong>Subtotal: </strong> &nbsp;<strong>${(couponDetails.campaign_payable_amount <= 0 ? 0 : (Helpers.numberWithCommas(Helpers.calculateBudgetWithStripeCharge(couponDetails.campaign_payable_amount))))}</strong></p>
                        </div>
                    :
                        <div className={classes.formGroup} style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', marginTop: 20}}>
                            <p className={classes.couponDetails}><strong>Total Price:</strong> &nbsp;{'$' + Helpers.numberWithCommas(campaignDetails.payoutAmount)}</p>
                            <p className={classes.couponDetails}><strong>Transaction Fees:</strong> &nbsp;{'$' + Helpers.numberWithCommas(Helpers.calculateStripeCharge(campaignDetails.payoutAmount))}</p>
                            <p className={classes.couponDetails}><strong>Subtotal:</strong> &nbsp;{'$' + Helpers.numberWithCommas(Helpers.calculateBudgetWithStripeCharge(campaignDetails.payoutAmount))}</p>
                        </div>
                    }
                    
                </div>
            </div>
            <div className={classes.createCampaignBottom}>

                {step !== 1 && <Button className={classes.formSubmit} name={"Back"} id="back-btn" onClick={prevStep} />}
                <Button id="submit-btn" className={classes.formSubmit} name={btnLoading ? <CircularProgress size={20} className={classes.btnLoading} /> : "Next"} onClick={() => handleSubmit()} disabled={btnLoading}/>
            </div>
            <ToastContainer theme='colored' />
        </>
    );
};

export default withStyles(styles)(Billing);