import React, { useRef, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { FORM_INPUT_STYLES, CAMPAIGN_FORM_STEPS_HEADING, FORM_INPUT_CONTAINER_STYLES, CAMPAIGN_FORM_STEPS_LABEL, WHITE_BG, PRIMARY_COLOR, FORM_GROUP, FIELD_INCORRECT_DATA_VALIDATION, INVALID_FORM_VALIDATION } from '../../../../utils/CssConstants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faCircle } from '@fortawesome/free-solid-svg-icons';
import CancelIcon from '@material-ui/icons/Cancel';
import CircularProgress from '@material-ui/core/CircularProgress';
import PostRechargeCampaignAPI from '../../../../services/Campaign/CampaignList/PostRechargeCampaignAPI'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import GetCampaignListAPI from '../../../../services/Campaign/CampaignList/GetCampaignListAPI';
import { getCampaignLists } from '../../../../store/actions/Campaign/campaignListActions';
import { updateStatus } from '../../../../store/actions/Auth/authActions'
import { useStripe } from "@stripe/react-stripe-js";
import PostRechargeConfirmPaymentAPI from '../../../../services/Payment/PostRechargeConfirmPaymentAPI';
import PostValidateCouponAPI from '../../../../services/Campaign/CreateCampaign/PostValidateCouponAPI';
import GetRechargeCampaignListAPI from '../../../../services/Campaign/CampaignList/GetRechargeCampaignListAPI';
import PostAddCardAPI from '../../../../services/Payment/PostAddCardAPI';
import GetBrandDetailsAPI from '../../../../services/Common/GetBrandDetailsAPI';
import Validate from "../../../../utils/Validation";
import Helpers from '../../../../utils/Helpers';


const styles = theme => ({
    modalContainer: {
        width: '60%',
        position: 'fixed',
        top: '15%',
        left: '22.5%',
        alignItems: 'center',
        flexDirection: 'column',
        zIndex: 1,
        [theme.breakpoints.down('sm')]: {
            width: '80%',
            left: '7.5%'
        }
    },
    campaignInfoPopup: {
        background: 'rgba(0, 0, 0, 0.8)',
        border: '1px solid #1549d6',
        boxSizing: 'border-box',
        borderRadius: 20,
        width: '100%'
    },
    top: {
        margin: 15
    },
    header: {
        margin: 15,
        borderBottom: '2px solid #CECECE',
        height: '25%',
        boxSizing: 'border-box',
        justifyContent: 'space-between',
        flexDirection: 'row',
        display: 'flex'
    },
    headerTextLeft: {
        marginBottom: 10
    },
    crossIcon: {
        cursor: 'pointer',
        color: WHITE_BG,
    },
    content: {
        margin: 15,
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'left',
        overflowY: 'auto', 
        overflowX: 'hidden', 
        height: '250px',
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column',
            height: 400
        }
    },
    contentTile: {
        display: 'flex',
        flexDirection: 'row',
        width: '100%',
        justifyContent: 'flex-start',
        marginBottom: 10,
        alignItems: 'center',
        paddingTop: 5,
    },
    formGroup: FORM_GROUP,
    formInputContStyles: FORM_INPUT_CONTAINER_STYLES,
    formInputStyles: FORM_INPUT_STYLES,
    formHeadingStyles: CAMPAIGN_FORM_STEPS_HEADING,
    formInputLabels: CAMPAIGN_FORM_STEPS_LABEL,
    fieldError: FIELD_INCORRECT_DATA_VALIDATION,
    invalidFormInputs: INVALID_FORM_VALIDATION,
    cardCheck: {
        width: '10%',
        borderLeft: '1px solid #B7B7B7',
        borderRight: 'none',
        justifyContent: 'center',
        alignItems: 'center',
        paddingRight: 0
    },
    infoLabels: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 20,
        marginRight: 10,
        color: WHITE_BG,
        width: '35%'
    },
    infoData: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 20,
        color: WHITE_BG,
        margin: 0,
    },
    dates: {
        display: 'flex',
        marginRight: 30
    },
    bottom: {
        width: '100%',
        marginTop: 2.5
    },
    budgetPostDetails: {
        width: '50%',
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            '&:last-child': {
                marginTop: 20
            }
            
        }
    },
    newCardDetails: {
        width: '60%',
        '&:last-child': {
            width: '40%'
        },
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            '&:last-child': {
                width: '100%'
            },            
        } 
    },
    cardInput: {
        padding: 0, 
        width: '80%', 
        background: 'rgba(0, 0, 0, 0.8)', 
        color: WHITE_BG, 
        cursor: 'pointer',
        borderLeft: 'none'
    },
    btnSection: {
        margin: 15,
        height: '50%',
        boxSizing: 'border-box',
        display: 'flex',
        justifyContent: 'left',
        alignItems: 'baseline'
    },
    formSubmit: {
        background: PRIMARY_COLOR,
        color: WHITE_BG,
        border: 'none',
        outline: 'none',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 20,
        width: '20%',
        minWidth: 120,
        height: 50, 
        borderRadius: 5, 
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    btnLoading: {
        color: "#FFF !important"
    },
    crossIconCoupon: {
        cursor: 'pointer',
        color: '#CECECE',
        marginLeft: 10,
        position: 'relative',
        top: 1,
        right: 45,
        zIndex: 9
    },
    applyBtn: {
        background: PRIMARY_COLOR,
        color: WHITE_BG,
        border: 'none',
        borderRadius: 5,
        height: 40,
        width: 80,
        outline: 'none',
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 16
    },
    couponDetails: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 400,
        fontSize: 18,
        marginBottom: 0,
        display: 'flex',
        justifyContent: 'space-between',
        width: '100%',
        [theme.breakpoints.down('sm')]: {
            width: '100%'
        }
    },
})

const RechargeCampaignPopup = ({ classes, campaignInfo, cards, closeRechargeModal, currentPage, selectedFilter, setTotalCampaigns, setTotalActiveCampaigns, setTotalInactiveCampaigns, setTotalExpiredCampaigns, setTotalRechargeCampaigns }) => {

    const [noOfPost, setNoOfPost] = useState('');
    const [budgetTotal, setBudgetTotal] = useState(0);
    const [selectedCard, setSelectedCard] = useState('');
    const [btnLoading, setBtnLoading] = useState(false);
    const [apiResponse, setApiResponse] = useState('');
    const [cardForm, setCardForm] = useState(false);
    const [nameOnCard, setNameOnCard] = useState('');
    const [nameOnCardError, setNameOnCardError] = useState(false);
    const [billingAddress, setBillingAddress] = useState('');
    const [billingAddressError, setBillingAddressError] = useState(false);
    const [cardNumber, setCardNumber] = useState('');
    const [cardNumberError, setCardNumberError] = useState(false);
    const [expiryMonth, setExpiryMonth] = useState('');
    const [expiryMonthError, setExpiryMonthError] = useState(false);
    const [expiryYear, setExpiryYear] = useState('');
    const [expiryYearError, setExpiryYearError] = useState(false);
    const [cvv, setCvv] = useState(''); 
    const [cvvError, setCvvError] = useState(false);
    const [duplicateCardError, setDuplicateCardError] = useState(false); 
    const [coupon, setCoupon] = useState("");
    const [couponError, setCouponError] = useState(false);
    const [couponValid, setCouponValid] = useState(false);
    const [couponDetails, setCouponDetails] = useState(null);
    const [couponForm, setCouponForm] = useState(false);
    const [addCardResponse, setAddCardResponse] = useState(false)

    const userDetails = useSelector((state) => (state.authReducer.userDetails));

    const node = useRef();
    const dispatch = useDispatch();
    const stripe = useStripe();
    const { commission } = JSON.parse(sessionStorage.getItem('campaignCharges'))

    const handleNoOfPost = noOfPost => {
        const re = /^[0-9]*$/;
        if((noOfPost === '' || re.test(noOfPost))) {
            setNoOfPost(noOfPost);
            if(!noOfPost) {
                setBudgetTotal(0)
                return
            }
            setBudgetTotal(Helpers.calculateBudgetWithStripeCharge((noOfPost * campaignInfo.price_per_post * commission)));
        }
    }

    const maskCardNumber = (cardNumber) => {
        let val = cardNumber;        
        let newval = '';         
        val = val.replace(/\s/g, ''); 
        
        // iterate to letter-spacing after every 4 digits   
        for(var i = 0; i < val.length; i++) {             
            if(i % 4 === 0 && i > 0) newval = newval.concat(' ');             
            newval = newval.concat(val[i]);         
        }        

        setCardNumber(newval);
    }

    const handleNameOnCard = (text) => {
        const re = /^[a-zA-Z ]+$/;    
        if (text === '' || re.test(text)) {
            setNameOnCard(text);
        }
    }

    const applyCoupon = async () => {
        setCouponError(false);
        if(!coupon) {
            setCouponError('Coupon cannot be empty');
            return;
        }

        const budgetWithoutStripeCharges = noOfPost * campaignInfo.price_per_post * commission;

        await PostValidateCouponAPI(coupon, budgetWithoutStripeCharges).then(res => {
            if(res.status === 'SUCCESS') {
                setCouponDetails({
                    coupon_amount: res.data.coupon_amount,
                    campaign_amount: res.data.campaign_amount,
                    campaign_payable_amount: res.data.campaign_payable_amount,
                    coupon_remaining_amount: res.data.coupon_remaining_amount,
                    coupon_amount_used: res.data.coupon_amount_used,
                    effective_coupon_amount: res.data.effective_coupon_amount,
                    coupon_id: res.data.coupon_id   
                });
                setBudgetTotal(res.data.campaign_payable_amount <= 0 ? 0 : (Helpers.numberWithCommas(Helpers.calculateBudgetWithStripeCharge(res.data.campaign_payable_amount))))
                setCouponValid(true);
                toast.success("Coupon applied")
            } else {
                toast.error(res.response.data.error)
                emptyCoupon()
            }
        }).catch(err => console.log(err.response.data.error))
    }

    const handleOnlyNumbers = (text, dataSet) => {
        const re = /^[0-9]+$/;    
        if(text === '' || re.test(text)) {
            dataSet(text);
        }
    }

    const handleCoupon = (text) => {
        if (text.length < 8) {
            setCoupon(text);
        }
    }

    const emptyCoupon = () => {
        setCoupon('')
        setCouponError(false)
        handleNoOfPost(noOfPost)
        setCouponDetails(null)
        setCouponValid(false)
    }


    const handleServerResponse = async (paymentIntentSecret, paymentIntentId) => {
        const result = await stripe.confirmCardPayment(paymentIntentSecret);
        handleStripeJsResult(result, paymentIntentId);
    }

    const handleStripeJsResult = async (result, paymentIntentId) => {
        if (result.error) {
            toast.error("The transaction was declined from the payment provider");
        } else {
            const total = parseFloat(noOfPost * campaignInfo.price_per_post * commission)
            const response = await PostRechargeConfirmPaymentAPI(total, campaignInfo.campaign_id, noOfPost, paymentIntentId);
            if(response.data.stripe_payment_status.toLowerCase() !== "succeeded") {
                toast.error("Something went wrong, please try again");
            } else {
                toast.success('Campaign recharged successfully');
                setTimeout(() => {
                    closeRechargeModal();
                    GetCampaignListAPI(currentPage, 10, selectedFilter).then(data => {
                        if(data !== undefined) {
                            dispatch(getCampaignLists(data.data.campaigns));
                            setTotalCampaigns(data.data.total)
                            setTotalActiveCampaigns(data.data.total_active)
                            setTotalInactiveCampaigns(data.data.total_inactive)
                            setTotalExpiredCampaigns(data.data.total_expired)                   
                        }
                    }).catch(err => (err.response) && toast.error(err.response.data.error));
                    GetBrandDetailsAPI(userDetails.brand_id).then(data => {
                        if(data.status === 'SUCCESS') {
                            dispatch(updateStatus({
                                ...userDetails, 
                                phone: data.data.phone_number,
                                instaHandle: data.data.instagram_handle,
                                instaHashtag: data.data.instagram_hashtag,
                                outOfFunds: data.data.campaign_out_of_budget
                            }))
                        }
                    }).catch(err => console.log(err))
                }, 2000)
            }
        }
    }

    const validateForm = () => {
        setNameOnCardError(false);
        setBillingAddressError(false);
        setCardNumberError(false);
        setExpiryMonthError(false);
        setExpiryYearError(false);
        setCvvError(false);
        setDuplicateCardError(false);

        let cardNumberStripped = cardNumber.replace(/ /g,'');
        let cardNumberlast4 = cardNumberStripped;
        cardNumberlast4 = cardNumberlast4.substring(cardNumberlast4.length - 4);
       
        let nameOnCardError = false, billingAddressError = false, cardNumberError = false, duplicateCardError = false, expiryMonthError = false, expiryYearError = false, cvvError = false;

        if(Validate.notNullValidation(nameOnCard) === false || Validate.textLengthValidation(nameOnCard, 20) === false) {
            nameOnCardError = true;
        } else {
            nameOnCardError = false;
        }

        if(Validate.notNullValidation(billingAddress) === false || Validate.fieldLengthValidation(billingAddress, 255) === false) {
            billingAddressError = true;
        } else {
            billingAddressError = false;
        }

        if (Validate.notNullValidation(cardNumberStripped) === false || Validate.cardNumberValidation(cardNumberStripped) === false) {
            cardNumberError = true;
        } 
        else {
            cardNumberError = false;
            let matches = 0;
            if(cards.length > 0) {
                cards.map((card) => {
                    if(card.last_4_character === cardNumberlast4) {
                        matches += 1;
                    } else {
                        duplicateCardError = false;
                    }
                });
                if(matches > 0) duplicateCardError = true;
            }
        }
        if(Validate.notNullValidation(expiryMonth) === false || Validate.numberLengthValidation(expiryMonth, 2) === false || Validate.cardExpiryMonth(expiryMonth, expiryYear) === false) {
            expiryMonthError = true;
        } else {
            expiryMonthError = false;
        }

        if(Validate.notNullValidation(expiryYear) === false || Validate.numberLengthValidation(expiryYear, 2) === false || Validate.cardExpiryYear(expiryYear) === false) {
            expiryYearError = true;
        } else {
            expiryYearError = false;
        }

        if(Validate.notNullValidation(cvv) === false || Validate.numberLengthValidation(cvv, 3) === false) {
            cvvError = true;
        } else {
            cvvError = false;
        }

        setNameOnCardError(nameOnCardError);
        setBillingAddressError(billingAddressError);
        setCardNumberError(cardNumberError);
        setDuplicateCardError(duplicateCardError);
        setExpiryMonthError(expiryMonthError);
        setExpiryYearError(expiryYearError);
        setCvvError(cvvError);
        if(nameOnCardError || billingAddressError || cardNumberError || expiryMonthError || expiryYearError || cvvError || duplicateCardError) {
            return false;
        } else {
            return true;
        }

    }

    const callRechargeAPI = async (newCardDetails) => {
        setBtnLoading(true);
        const total = parseFloat(noOfPost * campaignInfo.price_per_post * commission)
        campaignInfo.couponDetails = couponDetails ? couponDetails : null
        await PostRechargeCampaignAPI({ total, noOfPost, selectedCard: newCardDetails, campaignInfo, noOfPost }).then(res => {
            if(res.status === "SUCCESS") {
                if(res.data.stripe_payment_status.toLowerCase() !== "succeeded") {
                    handleServerResponse(res.data.payment_intent_client_secret, res.data.payment_intent_id);
                } else {
                    toast.success(res.message);
                    setTimeout(() => {
                        closeRechargeModal();
                        if(selectedFilter === 405) {
                            GetRechargeCampaignListAPI(currentPage, 10).then(data => {
                                if(data !== undefined) {
                                    dispatch(getCampaignLists(data.data.campaigns));
                                    setTotalRechargeCampaigns(data.data.total_active);
                                }
                            }).catch(err => (err.response) && toast.error(err.response.data.error));
                            return
                        }
                        GetCampaignListAPI(currentPage, 10, selectedFilter).then(data => {
                            if(data !== undefined) {
                                dispatch(getCampaignLists(data.data.campaigns));
                                setTotalCampaigns(data.data.total)
                                setTotalActiveCampaigns(data.data.total_active)
                                setTotalInactiveCampaigns(data.data.total_inactive)
                                setTotalExpiredCampaigns(data.data.total_expired)                   
                            }
                        }).catch(err => (err.response) && toast.error(err.response.data.error));
                        GetBrandDetailsAPI(userDetails.brand_id).then(data => {
                            if(data.status === 'SUCCESS') {
                                dispatch(updateStatus({
                                    ...userDetails, 
                                    phone: data.data.phone_number,
                                    instaHandle: data.data.instagram_handle,
                                    instaHashtag: data.data.instagram_hashtag,
                                    outOfFunds: data.data.campaign_out_of_budget
                                }))
                            }
                        }).catch(err => console.log(err))
                    }, 2000)
                }
            } else {
                setBtnLoading(false);
                toast.error(res.response.data.error)
            }
        });
    }

    const handleRecharge = async () => {
        setBtnLoading(true);
        if(addCardResponse) {
            await callRechargeAPI(addCardResponse);
        } else {
            await callRechargeAPI(selectedCard);
            setBtnLoading(false);
        }
    }

    const addCard = async () => {
        let isValid = validateForm();
        if(isValid) {
            setBtnLoading(true);
            let body = {
                billingAddress: billingAddress,
                cardNumber: cardNumber,
                expiryMonth: expiryMonth,
                expiryYear: expiryYear,
                cvv: cvv,
            }
            await PostAddCardAPI(body).then(res => {
                if(res.status === 'SUCCESS') {
                    toast.success(res.message);
                    setCouponForm(true)
                    setCardForm(false)
                    setBtnLoading(false)
                    setAddCardResponse(res.data)
                } else {
                    setBtnLoading(false)
                    toast.error(res.response.data.error)
                }
            }).catch(err => err.response && toast.error(err.response.data.error))
        }
    }

    useEffect(() => {
        function handleClickOutside(event) {
            if (node.current && !node.current.contains(event.target)) {
                closeRechargeModal()
            }
        }
        
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };

        // eslint-disable-next-line
    }, [node]);
    
    return (
        <>
            <div ref={node} className={classes.modalContainer}>
                <div className={classes.campaignInfoPopup}> 
                        <div className={classes.top}>
                            <div className={classes.header}>
                                <div className={classes.headerTextLeft}>
                                    <label className={classes.infoLabels} style={{ fontSize: 24, width: '100%' }} id="campaign-name-label">Recharge Campaign:</label>
                                    <p className={classes.infoData} id="campaign-name">{campaignInfo.brand_name}</p>
                                </div>
                                <CancelIcon color="primary" id="cancel-icon" className={classes.crossIcon} onClick={closeRechargeModal} />
                            </div>

                            {cardForm ? 
                                <div className={classes.content}>
                                    <div className={classes.newCardDetails}>
                                        <div className={classes.contentTile}>
                                            <label className={classes.infoLabels} id="name-on-card-label">Name On Card: </label>
                                            <input className={`${classes.formInputContStyles} ${nameOnCardError && classes.invalidFormInputs}`} type="text" style={{ height: 'auto', padding: 10, borderRadius: 5, margin: 0, width: '60%', background: 'rgba(0, 0, 0, 0.8)', color: WHITE_BG }} id="name-on-card" value={nameOnCard} onChange={e => handleNameOnCard(e.target.value)} />
                                        </div>
                                        <div className={classes.contentTile}>
                                            <label className={classes.infoLabels} id="card-number-label ">Card Number: </label>
                                            <input className={`${classes.formInputContStyles} ${cardNumberError && classes.invalidFormInputs}`} style={{ height: 'auto', padding: 10, borderRadius: 5, margin: 0, width: '60%', background: 'rgba(0, 0, 0, 0.8)', color: WHITE_BG }} id="card-number" type="text" maxLength={19} value={cardNumber} onKeyUp={e => maskCardNumber(e.target.value)} onChange={e => setCardNumber(e.target.value)} />
                                        </div>
                                        <div className={classes.contentTile}>
                                            <label className={classes.infoLabels} id="billing-address-label">Billing Address: </label>
                                            <input className={`${classes.formInputContStyles} ${billingAddressError && classes.invalidFormInputs}`} style={{ height: 'auto', padding: 10, borderRadius: 5, margin: 0, width: '60%', background: 'rgba(0, 0, 0, 0.8)', color: WHITE_BG }} id="billing-address" type="text" value={billingAddress} onChange={e => setBillingAddress(e.target.value)} />
                                        </div>
                                        {nameOnCardError && <span className={classes.fieldError} style={{ padding: 0, marginTop: 20 }}>Card Holder Name is invalid</span>}
                                        {cardNumberError && <span className={classes.fieldError} style={{ padding: 0 }}>Card Number is invalid</span>}
                                        {duplicateCardError && <span className={classes.fieldError} style={{ padding: 0 }}>This card already exists in our system</span>}
                                        {billingAddressError && <span className={classes.fieldError} style={{ padding: 0 }}>Billing address is invalid</span>}
                                        {expiryMonthError && <span className={classes.fieldError} style={{ padding: 0 }} id="invalidMonth-error-message">Expiry Month is invalid</span>}
                                        {expiryYearError && <span className={classes.fieldError} style={{ padding: 0 }} id="invalidYear-error-message">Expiry Year is invalid</span>}  
                                        {cvvError && <span className={classes.fieldError} style={{ padding: 0 }} id="field-error-message">CVV is invalid</span>}        
                                        {apiResponse !== "" && <span className={classes.fieldError} style={{ paddingLeft: 0, color: "#FFF", marginLeft: 30, width: '50%' }}>{apiResponse}</span>}
                                    </div>
                                    <div className={classes.newCardDetails}>
                                        <div className={classes.contentTile}>
                                            <label className={classes.infoLabels} style={{ width: '50%' }} id="valid-label">Valid Thru: </label>
                                            <div style={{ display: 'flex', width: '40%', justifyContent: 'space-between' }}>
                                                <input className={`${classes.formInputContStyles} ${expiryMonthError && classes.invalidFormInputs}`} style={{ height: 'auto', padding: 10, borderRadius: 5, margin: 0, width: '45%', background: 'rgba(0, 0, 0, 0.8)', color: WHITE_BG }} id="expiry-month" type="text" value={expiryMonth} placeholder="MM" onChange={e => handleOnlyNumbers(e.target.value, setExpiryMonth)} maxLength={2} />
                                                <input className={`${classes.formInputContStyles} ${expiryYearError && classes.invalidFormInputs}`} style={{ height: 'auto', padding: 10, borderRadius: 5, margin: 0, width: '45%', background: 'rgba(0, 0, 0, 0.8)', color: WHITE_BG }} id="expiry-year" type="text" value={expiryYear} placeholder="YY" onChange={e => handleOnlyNumbers(e.target.value, setExpiryYear)} maxLength={2}/>
                                            </div>
                                        </div>
                                        <div className={classes.contentTile}>
                                            <label className={classes.infoLabels} style={{ width: '50%' }} id="security-label">Security Code: </label>
                                            <input className={`${classes.formInputContStyles} ${cvvError && classes.invalidFormInputs}`} style={{ height: 'auto', padding: 10, borderRadius: 5, margin: 0, width: '40%', background: 'rgba(0, 0, 0, 0.8)', color: WHITE_BG }} type="text" id="cvv" placeholder="CVV" value={cvv} onChange={e => handleOnlyNumbers(e.target.value, setCvv)} maxLength={3} />
                                        </div>                               
                                    </div>
                                </div> 
                            : couponForm ? 
                                <>
                                     <div className={classes.content}>
                                        <div className={classes.budgetPostDetails} style={{ width: '100%' }}>
                                            <div className={classes.contentTile}>
                                                <label className={classes.infoLabels} id="coupon" style={{ position: 'relative', maxWidth: 'fit-content' }}>Coupon Code: </label>
                                                <input className={classes.formInputContStyles} type="text" value={coupon} onChange={e => handleCoupon(e.target.value)} style={{ height: 'auto', padding: 10, borderRadius: 5, margin: 0, width: '40%', background: 'rgba(0, 0, 0, 0.8)', color: WHITE_BG }} />
                                                <CancelIcon color="primary" id="cancel-icon" className={classes.crossIconCoupon} onClick={emptyCoupon} />
                                                <button className={classes.applyBtn} onClick={applyCoupon}>Apply</button>                        
                                            </div>
                                            <div className={classes.contentTile} style={{ color: "#FFF", marginTop: 40, flexDirection: 'column', alignItems: 'flex-start' }}>
                                                {couponValid ?
                                                    <div style={{ width: '40%' }}>
                                                        <p className={classes.couponDetails}><strong>Total Price:</strong> &nbsp;<span style={{ textDecoration: 'line-through', color: '#FF0000' }}>{'$' + parseFloat(noOfPost * campaignInfo.price_per_post * commission).toFixed(2)}</span></p>
                                                        <p className={classes.couponDetails}><strong>Coupon Applied:</strong> &nbsp; {'-$' + (couponDetails.effective_coupon_amount).toFixed(2)}</p>
                                                        <p className={classes.couponDetails}><strong>Transaction Fees:</strong> &nbsp;{'$' + Helpers.numberWithCommas(Helpers.calculateStripeCharge(couponDetails.campaign_payable_amount))}</p>
                                                        <p className={classes.couponDetails}><strong>Subtotal: </strong> &nbsp;<strong>${(couponDetails.campaign_payable_amount <= 0 ? 0 : (Helpers.numberWithCommas(Helpers.calculateBudgetWithStripeCharge(couponDetails.campaign_payable_amount))))}</strong></p>
                                                    </div>
                                                :
                                                    <div style={{ width: '40%' }}>
                                                        <p className={classes.couponDetails}><strong>Total Price:</strong> &nbsp;{'$' + Helpers.numberWithCommas(parseFloat(noOfPost * campaignInfo.price_per_post * commission).toFixed(2))}</p>
                                                        <p className={classes.couponDetails}><strong>Transaction Fees:</strong> &nbsp;{'$' + Helpers.numberWithCommas(Helpers.calculateStripeCharge(parseFloat(noOfPost * campaignInfo.price_per_post * commission).toFixed(2)))}</p>
                                                        <p className={classes.couponDetails}><strong>Subtotal:</strong> &nbsp;{'$' + Helpers.numberWithCommas(Helpers.calculateBudgetWithStripeCharge(parseFloat(noOfPost * campaignInfo.price_per_post * commission).toFixed(2)))}</p>
                                                    </div>
                                                }
                                            </div>
                                            {couponError !== "" && <span className={classes.fieldError} style={{ paddingLeft: 0, marginLeft: 30, width: '50%' }}>{couponError}</span>} 
                                        </div>
                                    </div>
                                </>
                            :
                                <div className={classes.content}>
                                    <div className={classes.budgetPostDetails}>
                                        <div className={classes.contentTile}>
                                            <label className={classes.infoLabels} id="budget-label">No Of Posts: </label>
                                            <input className={classes.formInputContStyles} style={{ height: 'auto', padding: 10, borderRadius: 5, margin: 0, width: '50%', background: 'rgba(0, 0, 0, 0.8)', color: WHITE_BG }} value={noOfPost} onChange={e => handleNoOfPost(e.target.value)} />
                                        </div>
                                        {apiResponse !== "" && <span className={classes.fieldError} style={{ paddingLeft: 0, color: "#FFF", marginLeft: 30, width: '50%' }}>{apiResponse}</span>}
                                    </div>
                                    <div className={classes.budgetPostDetails}>
                                        <label className={classes.infoLabels} style={{ width: '100%' }} id="budget-total">Previous Cards: </label>
                                        {(cards.length > 0) && cards.map((card, index) => 
                                            <div className={classes.formInputContStyles} style={{ width: '95%', height: 50, padding: 10, borderRadius: 5, cursor: 'pointer', background: 'rgba(0, 0, 0, 0.8)', color: WHITE_BG }} key={index}>
                                                <input className={`${classes.formInputStyles} ${classes.cardInput}`} id="previous-card" type="text" value={"**** **** **** " + card.last_4_character} readOnly={true}  />
                                                <label className={`${classes.formInputLabels} ${classes.cardCheck}`} style={{ height: 'auto', width: '20%', cursor: 'pointer' }} onClick={() => setSelectedCard(card)}><FontAwesomeIcon id="circle-icon" icon={selectedCard === card ? faCheckCircle : faCircle} color={selectedCard === card ? PRIMARY_COLOR : "#797979"} /></label>
                                            </div>
                                        )}
                                        <button className={classes.formSubmit} style={{ width: '95%', backgroundColor: (noOfPost !== '' && noOfPost > 0) ? PRIMARY_COLOR : '#c1c1c1' }} onClick={() => { setCardForm(true); setSelectedCard(''); }} disabled={(noOfPost !== '' && noOfPost > 0) ? false : true}>Add a new Card</button>     
                                    </div>
                                </div>
                            }
                            <div className={classes.btnSection} style={{ alignItems: 'center', justifyContent: 'space-between' }}>
                                {couponForm && !cardForm &&
                                    <button className={classes.formSubmit} onClick={handleRecharge} disabled={btnLoading}>{btnLoading ? <CircularProgress className={classes.btnLoading} size={30} /> : 'Recharge'}</button>
                                }
                                {!couponForm && !cardForm &&
                                    <button className={classes.formSubmit} style={{ backgroundColor: ((noOfPost !== '' && noOfPost > 0) && (selectedCard !== '' || cardForm)) ? PRIMARY_COLOR : '#c1c1c1' }} onClick={() => setCouponForm(true)} disabled={((noOfPost !== '' && noOfPost > 0) && (selectedCard !== '' || cardForm)) ? false : true}>Next</button>
                                }
                                {cardForm && !couponForm &&
                                    <>
                                        <button className={classes.formSubmit} onClick={addCard} disabled={btnLoading}>{btnLoading ? <CircularProgress className={classes.btnLoading} size={30} /> : 'Add Card'}</button>
                                        <button className={classes.formSubmit} onClick={() => setCardForm(!cardForm)} disabled={btnLoading}>Back</button>
                                    </>
                                }
                                {couponForm && !cardForm && 
                                    <button className={classes.formSubmit} onClick={() => {setCouponForm(!couponForm); emptyCoupon();}} disabled={btnLoading}>Back</button>
                                }
                            </div>
                        </div> 
                </div> 
            </div>
            <ToastContainer theme='colored' />
        </>
    )
}

export default withStyles(styles)(RechargeCampaignPopup);