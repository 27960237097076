import React from 'react'
import { withStyles } from "@material-ui/core/styles"
import { PRIMARY_COLOR } from '../../../utils/CssConstants'
import Helpers from '../../../utils/Helpers'


const styles = {
    campaignListTDataRow: {
        display: 'flex',
        background: '#EDEEF7',
        border: '1px solid #000000',
        boxSizing: 'border-box',
        borderRadius: 10,
        padding: '15px 30px',
        width: '100%',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        marginBottom: 10
    },
    campaingListData: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: 20,
        padding: '5px 10px',
        textAlign: 'center',
        height: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        wordBreak: 'break-word'
    },
    smallSpace: {
        flex: 1
    },
    mediumSpace: {
        flex: 3
    },
    mediumSpaceTwo: {
        flex: 4
    },
    largeSpace: {
        flex: 7
    }
}

const TableData = ({ classes, postsData, seriesIndex, isLikesColumn }) => {

    return (
        <>
            {(postsData.length > 0) && postsData.map((post, index) =>
                <tr className={classes.campaignListTDataRow} key={index}>
                    <td className={`${classes.campaingListData} ${classes.smallSpace}`} id="table-index">{seriesIndex + index}</td>
                    <td className={`${classes.campaingListData} ${classes.mediumSpaceTwo}`} id="table-instagram-handle">
                        <a href={'https://www.instagram.com/' + post.instagram_username} rel="noreferrer" target="_blank" style={{ color: PRIMARY_COLOR }}>
                            {post.instagram_username ? '@' + post.instagram_username : ''}
                        </a>
                    </td>
                    <td className={`${classes.campaingListData} ${classes.mediumSpaceTwo}`} id="table-post-date">{post.post_date && Helpers.formatDate(post.post_date + 'Z')}</td>
                    <td className={`${classes.campaingListData} ${classes.mediumSpace}`} id="table-followers-count">{post.follower_count ? Helpers.numberWithCommas(post.follower_count) : 0}</td>
                    {isLikesColumn && <td className={`${classes.campaingListData} ${classes.mediumSpace}`} id="table-likes-count">{post.like_count ? Helpers.numberWithCommas(post.like_count) : 0}</td>}
                </tr>
            )}
        </>
    )
}

export default withStyles(styles)(TableData)