import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { withStyles } from "@material-ui/core/styles";
import { WHITE_BG, BTN_COLOR_THEME, LOGIN_FONT_COLOR, FIELD_INCORRECT_DATA_VALIDATION, INVALID_FORM_VALIDATION } from '../../utils/CssConstants';
import Layout from './Layout';
import Validate from '../../utils/Validation';
import CircularProgress from '@material-ui/core/CircularProgress';
import PostForgotPasswordAPI from '../../services/Auth/PostForgotPasswordAPI';


const styles = theme => ({
    loginFormLabels: {
        fontWeight: 600,
        fontSize: '1em',
        fontFamily: 'Poppins',
        color: LOGIN_FONT_COLOR,
        display: 'block'
    },
    invalidFormInputs: INVALID_FORM_VALIDATION,
    fieldError: FIELD_INCORRECT_DATA_VALIDATION,
    loginFormInput: {
        height: 40,
        padding: 10,
        marginBottom: 10,
        width: '80%',
        border: '1px solid #A7A5A5',
        outline: 'none',
        boxSizing: 'border-box',
        borderRadius: 5,
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
    },
    loginSubmitBtn: {
        background: BTN_COLOR_THEME,
        border: 'none',
        borderRadius: 4,
        height: 40,
        fontFamily: 'Inter, sans-serif',
        fontStyle: 'normal',
        fontWeight: 'bold',
        fontSize: 12,
        letterSpacing: '0.05em',
        color: WHITE_BG,
        width: '237px',
        marginBottom: '5%',
        marginTop: '2%',
        display: 'block',
        [theme.breakpoints.down('sm')]: {
            height: 40,
        },
    },
    smallHeading: {
        fontWeight: 600,
        fontSize: '1.375em',
        fontFamily: 'Poppins',
        color: 'black',
        textAlign: 'center',
        marginBottom: '20px',
        marginRight: '25%'
    },
    btnLoading: {
        marginRight: 10,
        color: "#FFF !important"
    },
    NavigationBtns: {
        display: 'flex'
    }
});

const ForgotPassword = ({ classes }) => {

    const navigate = useNavigate();

    const [email, setEmail] = useState("");
    const [emailError, setEmailError] = useState(false);
    const [btnLoading, setBtnLoading] = useState(false);
    const [apiResponse, setApiResponse] = useState("");


    const validateForm = () => {
        setEmailError(false);

        let emailError = false;

        if (Validate.emailValidation(email) === false) {
            emailError = true;
        } else {
            emailError = false;
        }

        setEmailError(emailError);

        if (emailError) {
            return false;
        } else {
            return true;
        }
    }

    const handleSubmit = async () => {
        let isValid = validateForm();
        if (isValid) {
            setBtnLoading(true);
            PostForgotPasswordAPI(email).then(res => {
                setBtnLoading(false);
                if(res.status === "SUCCESS") {
                    setApiResponse("We have sent you a password reset email, please check your email for further instructions.");
                } else {
                    setApiResponse(res.response.data.error)
                }
            });
        }
    }

    useEffect(() => {
        document.title = "Forgot Password - Thumzup"

    }, []);

    return (
        <> {apiResponse === "" ?
                <Layout pageHeadline="Welcome to The Thumzup Admin Area" pageSubHeading="Get paid to post about things you love">
                    <h3 className={classes.smallHeading} id="sign-in-head">Forgot Password</h3>
                    <label className={classes.loginFormLabels} htmlFor="email" id="email-label">Email</label>
                    <input className={`${classes.loginFormInput} ${emailError && classes.invalidFormInputs}`} type="email" id="email" value={email} onChange={(e) => setEmail(e.target.value)} />
                    {emailError && <span className={classes.fieldError} style={{ paddingLeft: 0 }}>Invalid email address</span>}

                    <div className={classes.NavigationBtns}>
                        {/* <button className={classes.loginSubmitBtn} style={{ marginRight: 30 }} id="login-btn" onClick={() => handleSubmit()} disabled={btnLoading}>{btnLoading ? <CircularProgress size={20} className={classes.btnLoading} /> : "LOG IN"}</button> */}
                        <button className={classes.loginSubmitBtn} id="submit-btn" onClick={() => handleSubmit()} disabled={btnLoading}>{btnLoading ? <CircularProgress size={20} className={classes.btnLoading} /> : "Submit"}</button>   
                    </div>
                    
                </Layout>
            : 
                <Layout pageHeadline="Welcome to The Thumzup Admin Area" pageSubHeading="Get paid to post about things you love">
                    <h3 className={classes.smallHeading} id="sign-in-head">Forgot Password</h3>
                    <h4 className={classes.loginFormLabels} style={{ width: '80%', marginTop: '5%', color: apiResponse[0] !== 'W' && '#FF0000' }} htmlFor="email" id="email-label">{apiResponse}</h4>

                    {/* <div className={classes.NavigationBtns}> */}
                        {/* <button className={classes.loginSubmitBtn} style={{ marginRight: 30 }} id="login-btn" onClick={() => handleSubmit()} disabled={btnLoading}>{btnLoading ? <CircularProgress size={20} className={classes.btnLoading} /> : "LOG IN"}</button> */}
                        <button onClick={() => navigate('/')} className={classes.loginSubmitBtn} style={{ marginTop: '5%' }} id="go-back">Go Back</button>
                    {/* </div> */}
                </Layout>
            }
        </>  
    )
};

export default withStyles(styles)(ForgotPassword);