import axios from 'axios';

/**
 * @description Get API to fetch cards by brand
 * @async
 * @function GetCardByBrandAPI
 * @returns Promise
**/


const GetCardsByBrandAPI = async () => {

    try {

        let apiUrl = process.env.REACT_APP_API_URL + "/card/cards";
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('access-token')
            } 
        }

        const response = await axios.get(apiUrl, config)
        return response.data;

    } catch (e) {
        return e;
    }
}

export default GetCardsByBrandAPI;
  