import axios from 'axios';

/**
 * @description Post API to confirm recharge campaign payment through 3D
 * @async
 * @function PostRechargeConfirmPaymentAPI
 * @returns Promise
**/


const PostRechargeConfirmPaymentAPI = async (budgetTotal, campaignId, postTotal, paymentIntentId) => {

    try {

        let apiUrl = `${process.env.REACT_APP_API_URL}/campaign/recharge/confirmPayment?paymentIntentId=${paymentIntentId}`;
        let request = {
            budget_total: budgetTotal,
            campaign_id: campaignId,
            posts_total: postTotal
        }
        const config = {
            headers: {
                Authorization: 'Bearer ' + localStorage.getItem('access-token')
            } 
        }

        const response = await axios.post(apiUrl, request, config)
        return response.data;

    } catch (e) {
        return e;
    }
}

export default PostRechargeConfirmPaymentAPI;
  