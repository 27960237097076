import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { withStyles } from '@material-ui/styles';
import { WHITE_BG, GRAY_BG_F7, CAMPAIGNLIST_FILTER_LI_STYLES, PRIMARY_COLOR } from '../../utils/CssConstants';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import CircularProgress from '@material-ui/core/CircularProgress';
import Pagination from '@mui/material/Pagination';
import { AppContext } from '../../utils/AppContext';
import GetBrandAPI from '../../services/Brand/GetBrandAPI';
import TableData from './TableData/TableData';
import { getBrands } from '../../store/actions/Brands/brandsActions';


const styles = theme => ({
    rootUsers: {
        display: 'flex',
        background: WHITE_BG,
        margin: '30px 10px',
        padding: 15,
        borderRadius: 20
    },
    componentCont: {
        background: GRAY_BG_F7,
        width: '100%',
        borderRadius: 30,
        minHeight: '100vh',
        height: 'auto',
    },
    loaderContainer: {
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        minHeight: '100vh'
    },
    campaignListTop: {
        margin: 30,
        marginLeft: 50,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'baseline',
        [theme.breakpoints.down('sm')]: {
            margin: '20px 20px'
        }
    },
    campaignListHeading: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 22,
        marginBottom: 0,
        width: '15%',
        [theme.breakpoints.down('sm')]: {
            width: '20%'
        }
    },

    campaignListContent: {
        width: '100%',
        marginBottom: 20,
        [theme.breakpoints.down('sm')]: {
            overflowX: 'auto'
        }
    },
    campaignListTable: {
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        [theme.breakpoints.down('sm')]: {
            width: 1400
        }
    },
    campaignListTheadRow: {
        display: 'flex',
        background: WHITE_BG,
        border: '1px solid #000000',
        boxSizing: 'border-box',
        boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
        borderRadius: 10,
        padding: '15px 30px',
        width: '100%',
        minHeight: '12%',
        flexWrap: 'wrap',
        marginBottom: 10,
        justifyContent: 'center',
        alignItems: 'center',
    },
    campaingListHeadings: {
        fontFamily: 'Poppins',
        fontStyle: 'normal',
        fontWeight: 600,
        fontSize: 18,
        padding: '5px 10px',
        borderRight: '1px solid #CECECE',
        textAlign: 'center',
        height: '100%',
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
    },
    smallSpace: {
        flex: 1
    },
    mediumSpace: {
        flex: 3
    },
    mediumSpaceTwo: {
        flex: 4
    },
    largeSpace: {
        flex: 7
    }


 
})


const Brands = ({ classes }) => {


    const [loader, setLoader] = useState(false);
    const [totalBrands, setTotalBrands] = useState(null);
    const [error, setError] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const brandsPerPage = 10


    const userDetails = useSelector((state) => state.authReducer.userDetails);
    const brands = useSelector((state) => state.brandsReducer.brands);

    const navigate = useNavigate();
    let dispatch = useDispatch();



    const handlePagination = async (event, value) => {
        setCurrentPage(value);
        setLoader(true)
        await GetBrandAPI(value, 10).then(data => {
            if (data !== undefined) {
                dispatch(getBrands(data.data.fetch_brand_list_model));
                setTotalBrands(data?.data?.total_count)
                if (data?.data?.total_count <= 0) setError('No records');
            }
            setLoader(false);
        }).catch(err => console.log(err));
    }

    useEffect(() => {
        document.title = "Brands - Thumzup";

        if (userDetails.is_verified) {
            if (userDetails.status_id === 1901) {
                navigate('/get-started')
            }
        } else {
            navigate('/signup-verification')
        }

        setLoader(true)
        GetBrandAPI(currentPage, 10).then(data => {
            if (data !== undefined) {
                dispatch(getBrands(data.data.fetch_brand_list_model));
                setTotalBrands(data?.data?.total_count)
                if (data?.data?.total_count <= 0) setError('No records');
            }
            setLoader(false);

        }).catch(err => console.log(err));

    }, [userDetails, dispatch, navigate]);

    // Get Current users
    const indexOfLastUser = currentPage * brandsPerPage;
    const indexOfFirstUser = indexOfLastUser - brandsPerPage;

    return (
        <div className={classes.rootUsers}>
            <div className={classes.componentCont}>
                <div className={classes.campaignListTop}>
                    <h3 className={classes.campaignListHeading} id="page-heading">Brands</h3>
                </div>

                {loader === true ?
                    <div className={classes.loaderContainer}>
                        <CircularProgress size={80} />
                    </div>
                    :
                    (totalBrands > 0) ?
                        <>
                            <div className={classes.campaignListContent}>
                                <table className={classes.campaignListTable}>
                                    <thead>
                                        <tr className={classes.campaignListTheadRow}>
                                            <th className={`${classes.campaingListHeadings} ${classes.mediumSpaceTwo}`}>Brand Name</th>
                                            <th className={`${classes.campaingListHeadings} ${classes.mediumSpace}`}>First Name</th>
                                            <th className={`${classes.campaingListHeadings} ${classes.mediumSpace}`}>Last Name</th>
                                            <th className={`${classes.campaingListHeadings} ${classes.mediumSpaceTwo}`}>Phone Number</th>
                                            <th className={`${classes.campaingListHeadings} ${classes.mediumSpaceTwo}`}>Email Address</th>
                                            <th className={`${classes.campaingListHeadings} ${classes.mediumSpaceTwo}`} style={{ borderRight: 'none' }}>SignUp Date</th>

                                        </tr>
                                    </thead>
                                    <tbody>
                                        <TableData brandData={brands} seriesIndex={currentPage < 2 ? currentPage : indexOfFirstUser + 1} />
                                    </tbody>
                                </table>
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'center', width: '100%' }}>
                                <Pagination color='primary' onChange={handlePagination} count={Math.ceil(totalBrands / 10)} page={currentPage} showFirstButton showLastButton />
                            </div>
                        </>
                        :
                        <div className={classes.loaderContainer}>
                            <h1 id="no-records">{error}</h1>
                        </div>
                }
            </div>
        </div>
    )
}

export default withStyles(styles)(Brands);