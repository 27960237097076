import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import ProgressBar from './ProgressBar/ProgressBar';
import AddDetails from './AddDetails/AddDetails';
import Preview from './Preview/Preview';
import Billing from './Billing/Billing';


const CreateCampaign = ({ stateChanged, setStateChanged, setActiveTab }) => {

    const progressDetails = useSelector((state) => state.createCampaignReducer.progressDetails); 

    const [step, setStep] = useState(progressDetails.step);
    const [detailsComplete, setDetailsComplete] = useState(progressDetails.detailsComplete);
    const [billingComplete, setBillingComplete] = useState(progressDetails.billingComplete);
    const [reviewComplete, setReviewComplete] = useState(progressDetails.reviewComplete);

    const prevStep = () => {
        setStep(step - 1);
    }

    const progressbarProps = {
        detailsComplete,
        billingComplete,
        reviewComplete,
        step
    };
    
    const renderComponentSwitch = () => {
        switch (step) {
            case 1:
                return <AddDetails step={step} setStep={setStep} prevStep={prevStep} setDetailsComplete={setDetailsComplete} stateChanged={stateChanged} setStateChanged={setStateChanged} />
            case 2:
                return <Billing step={step} setStep={setStep} prevStep={prevStep} setBillingComplete={setBillingComplete} setReviewComplete={setReviewComplete} />
            case 3:
                return  <Preview step={step} setStep={setStep} prevStep={prevStep} setReviewComplete={setReviewComplete} setActiveTab={setActiveTab} setStateChanged={setStateChanged} />
            default:
                return <AddDetails step={step} setStep={setStep} prevStep={prevStep} setDetailsComplete={setDetailsComplete} />
        }
    }

    useEffect(() => {
        document.title = "Campaign - Thumzup"
     }, []);

    return (
        <>                    
            <ProgressBar {...progressbarProps} />

            {renderComponentSwitch()}
            
        </>
    )
}

export default CreateCampaign;