import axios from 'axios';

/**
 * @description API to update posts status
 * @async
 * @function UpdatePostStatusAPI
 * @returns Promise
**/


const UpdatePostStatusAPI = async (postId, status, notes = null, reason = null) => {
  
    try {

        const apiUrl = process.env.REACT_APP_API_URL + "/posts/" + postId;
        const body = {
            "flagged_notes": notes,
            "reason": reason,
            "status": status
        };
        
        const config = {
            headers: {
              Authorization: 'Bearer ' + localStorage.getItem('access-token')
            } 
        }

        const response = await axios.put(apiUrl, body, config);
        return response.data;
  
    } catch (e) {
        return e;
    }
}

export default UpdatePostStatusAPI;
  