import React, { useEffect } from 'react';

const PaymentMethods = () => {

    useEffect(() => {
        document.title = "Payment Methods"
     }, []);

    return (
        <div>
            
        </div>
    );
};

export default PaymentMethods;