import * as actionTypes from '../../types/campaignlist';

export const getCampaignLists = (data) => async (dispatch) => {
    await dispatch({
        type: actionTypes.GET_CAMPAIGN_LIST,
        payload: data
    });
};

export const updateCampaignStatus = (campaignId, status) => async (dispatch) => {
    await dispatch({
        type: actionTypes.UPDATE_CAMPAIGN_STATUS,
        payload: [campaignId, status]
    });
};